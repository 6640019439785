<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="auto" class="pa-0">
        <v-card class="login-box px-7 py-10 pa-sm-12">
          <v-row justify="center">
            <v-col cols="auto">
              <div class="text-center pa-0 pb-10 pa-sm-12 pb-sm-16">
                <Logo />
              </div>
              <div class="px-0 pb-sm-10 px-sm-12 pb-sm-12 mb-6">
                <login-form v-model="authData" :is-loading="isLoading" :has-error="hasError" @submit="doLogin" />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Logo from '@/pwa/modules/Login/components/Logo.vue';
import LoginForm from '@/pwa/modules/Login/components/LoginForm.vue';
import { LoginData, LoginMethods } from '@/pwa/modules/Login/types';

export default Vue.extend<LoginData, LoginMethods, unknown, unknown>({
  components: {
    Logo,
    LoginForm
  },
  data() {
    return {
      authData: {
        email: '',
        password: ''
      },
      hasError: false,
      isLoading: false
    };
  },
  methods: {
    doLogin() {
      this.hasError = false;
      this.isLoading = true;

      this.$auth
        .login({
          data: {
            username: this.authData.email,
            password: this.authData.password
          },
          redirect: {
            path: '/assessment?greet=1'
          }
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;

  .row {
    min-height: 100%;

    .login-box {
      border: 1px solid var(--v-white-darken1);
    }
  }
}
</style>
