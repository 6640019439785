<template>
  <v-app>
    <div class="app-content">
      <div class="background" :class="{ 'd-none': isAuthenticated && isPhone }">
        <div class="triangle ml-8"></div>
      </div>
      <app-bar
        :title="$route.meta.title || ''"
        :sub-menu="subMenu"
        :current-route-name="$route.name || ''"
        :station-assessment-count-unapproved="stationAssessmentCountUnapproved"
        :station-assessment-images-not-reviewed="stationAssessmentImagesNotReviewed"
        :ride-assessment-count-exported="rideAssessmentCountExported"
        :show-bottom-navigation="showBottomNavigation"
      >
        <template v-if="isAuthenticated">
          <account-menu
            v-if="!isPhone"
            :full-name="this.$auth.user().fullName || ''"
            :email="this.$auth.user().email || ''"
            @logout="doLogout"
          />
          <v-btn v-else icon class="ml-auto" @click="phoneDrawerOpen = !phoneDrawerOpen" data-test="burger-menu">
            <burger-menu-icon :opened="phoneDrawerOpen" color="primary" />
          </v-btn>
        </template>
      </app-bar>
      <drawer
        v-if="!isPhone"
        :disabled="!isAuthenticated || menuLocked"
        :station-assessment-count-unapproved="stationAssessmentCountUnapproved"
        :station-assessment-images-not-reviewed="stationAssessmentImagesNotReviewed"
        :ride-assessment-count-exported="rideAssessmentCountExported"
        :current-route-path="$route.path"
        :expand-on-hover="canHover"
      />
      <phone-drawer
        v-else-if="isAuthenticated"
        :drawer-open="phoneDrawerOpen"
        :full-name="this.$auth.user().fullName || ''"
        @update:drawerOpen="phoneDrawerOpen = $event"
        @logout="doLogout"
      />
      <main-content />
      <bottom-navigation v-if="showBottomNavigation" :sub-menu="subMenu" />
    </div>
    <div class="rotate-device-hint">{{ $t('layout.rotateDeviceHint') }}</div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { AuthorizedMethods, LayoutComputed, LayoutData } from '@/shared/modules/Layout/types';
import { getSubMenu, showBottomNavigation } from '@/shared/modules/Layout/services/sub-menu-service';
import AppBar from '@/shared/modules/Layout/components/AppBar.vue';
import AccountMenu from '@/shared/modules/Layout/components/AccountMenu.vue';
import BurgerMenuIcon from '@/shared/modules/Layout/components/BurgerMenuIcon.vue';
import Drawer from '@/shared/modules/Layout/components/Drawer.vue';
import MainContent from '@/shared/modules/Layout/components/Main.vue';
import { mapActions, mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';
import PhoneDrawer from '@/shared/modules/Layout/components/PhoneDrawer.vue';
import BottomNavigation from '@/shared/modules/Layout/components/BottomNavigation.vue';
import { useMediaQuery } from '@vueuse/core';

export default Vue.extend<LayoutData, AuthorizedMethods, LayoutComputed, unknown>({
  components: {
    AppBar,
    AccountMenu,
    BurgerMenuIcon,
    Drawer,
    MainContent,
    PhoneDrawer,
    BottomNavigation
  },
  data() {
    return {
      phoneDrawerOpen: false,
      timeout: undefined
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, [
      'menuLocked',
      'stationAssessmentCountUnapproved',
      'stationAssessmentImagesNotReviewed',
      'rideAssessmentCountExported'
    ]),
    subMenu() {
      return getSubMenu(this.$route, this.$router.options.routes || []);
    },
    showBottomNavigation() {
      return showBottomNavigation(this.$route) && this.isPhone;
    },
    isAuthenticated() {
      return this.$auth.check();
    },
    isPhone() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    ...mapActions(LAYOUT_NAMESPACE, [
      'fetchStationAssessmentCountUnapproved',
      'fetchStationAssessmentImagesNotReviewed',
      'fetchRideAssessmentCountExported'
    ]),
    doLogout() {
      this.phoneDrawerOpen = false;
      this.$auth.logout({
        redirect: {
          path: '/login'
        }
      });
    },
    stopIntervalApiCalls() {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    },
    callNotificationApis() {
      if (this.$auth.check()) {
        this.fetchStationAssessmentCountUnapproved();
        this.fetchStationAssessmentImagesNotReviewed();
        this.fetchRideAssessmentCountExported();
        this.timeout = window.setTimeout(this.callNotificationApis, 60000);
      } else {
        this.stopIntervalApiCalls();
      }
    }
  },
  watch: {
    isAuthenticated: {
      handler: function (value) {
        if (false === value) {
          this.doLogout();
        } else if (this.timeout === undefined) {
          this.callNotificationApis();
        }
      }
    }
  },
  async created() {
    this.callNotificationApis();
  },
  setup() {
    const canHover = useMediaQuery('(hover: hover)');
    return { canHover };
  },
  beforeDestroy() {
    this.stopIntervalApiCalls();
  }
});
</script>

<style lang="scss" scoped>
@import '~@/shared/styles/media.scss';

.v-application {
  background: rgb(248, 249, 250) !important;
}

.rotate-device-hint {
  display: none;
}

@include mobile-landscape {
  .app-content {
    display: none;
  }

  .rotate-device-hint {
    display: grid;
    place-content: center;
    height: 100%;
  }
}

.background {
  height: 20vh;
  min-height: 350px;
  background: url('~@/shared/assets/background.png') no-repeat top left var(--v-primary-base);
  background-size: auto 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  @include sm-and-up {
    height: 75vh;
    min-height: 750px;
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 42.5px 0 42.5px;
    border-color: var(--v-primary-base) transparent transparent transparent;
    position: absolute;
    left: -10px;
    bottom: -22px;
    z-index: 100;

    &.top {
      border-color: white transparent transparent transparent;
      top: 85px;
      bottom: auto;
    }
  }
}
</style>
