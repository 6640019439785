<template>
  <v-dialog v-model="datePicker" :disabled="disabled" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="displayDate"
        :label="hideLabel ? undefined : $t('layout.common.date')"
        :disabled="disabled"
        :style="inputStyles"
        data-test="date-picker"
        hide-details
        readonly
      >
        <template #append>
          <v-icon class="mt-1" small>fal fa-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :first-day-of-week="1"
      :max="maximum"
      :min="minimum"
      :disabled="disabled"
      @input="datePicker = false"
      color="primary"
      header-color="picker-header"
      locale="de-DE"
    ></v-date-picker>
  </v-dialog>
</template>

<script lang="ts">
import { formatPickerDate, isAfter } from '@/shared/helper/date';
import { format, isValid, parseISO } from 'date-fns';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  data() {
    return {
      datePicker: false,
      date: '',
      time: ''
    };
  },
  props: {
    value: {
      type: String,
      default: format(Date.now(), 'yyyy-MM-dd HH:mm:ss')
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    max: {
      type: Date as PropType<Date | undefined>,
      default: undefined
    },
    min: {
      type: Date as PropType<Date | undefined>,
      default: undefined
    }
  },
  computed: {
    displayDate(): string {
      const parsedDate = parseISO(`${this.date} ${this.time}`);
      const date = isValid(parsedDate) ? parsedDate : new Date();
      return this.$d(date, 'date');
    },
    inputStyles(): Partial<CSSStyleDeclaration> {
      return {
        maxWidth: this.maxWidth
      };
    },
    maxDate(): Date | number {
      return this.max ? new Date(this.max) : Date.now();
    },
    maximum(): string {
      return formatPickerDate(this.maxDate);
    },
    minimum(): string | undefined {
      return this.min ? formatPickerDate(this.min) : undefined;
    }
  },
  methods: {
    setToMax() {
      this.date = this.maximum;
      this.time = format(this.maxDate, 'HH:mm');
    }
  },
  watch: {
    date(date) {
      if (isAfter(parseISO(`${date} ${this.time}:00`), this.maxDate)) {
        this.setToMax();
      }

      this.$emit('input', `${date} ${this.time}:00`);
    },
    time(time) {
      if (isAfter(parseISO(`${this.date} ${time}:00`), this.maxDate)) {
        this.setToMax();
      }

      this.$emit('input', `${this.date} ${time}:00`);
    },
    value: {
      immediate: true,
      handler(value: string) {
        if (!value) return;

        try {
          const [date, time] = value.split(' ');

          if (!date || !time) return;

          this.date = date.trim();
          this.time = time.trim().slice(0, 5);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

::v-deep .picker-header {
  background: $gradient-primary;
}

::v-deep .v-dialog {
  margin: 15px;
}
</style>
