export default {
  stationAssessmentEdit: {
    statuses: {
      complete: 'vollständig',
      incomplete: 'unvollständig'
    },
    deleteDialog: {
      title: 'Erfassung wirklich löschen?',
      description: 'Die Stationserfassung für "{0}" wird unwiderruflich gelöscht.'
    },
    saveDialog: {
      title: 'Erfassung wirklich überschreiben?',
      description: 'Die Stationserfassung für "{0}" wird überschrieben.'
    },
    discardDialog: {
      discard: 'verwerfen?',
      title: 'Erfassung wirklich verwerfen?',
      description: 'Die Änderungen der Stationserfassung für "{0}" werden verworfen.'
    },
    baseData: 'Grunddaten',
    sections: {
      qualityOfStay: 'Aufenthaltsqualität',
      qualityOfStayShort: 'AQ',
      continueToQualityOfStay2: 'Weiter zu AQ2',
      returnToQualityOfStay1: 'Zurück zu AQ1',
      passengerInformation: 'Fahrgastinformationen',
      accessibility: 'Barrierefreiheit',
      platformsAndAccessAreas: 'Bahnsteige & Zugangsbereiche',
      cleanlinessAndStructuralCondition: 'Sauberkeit & baulicher Zustand',
      featuresDamageFree: 'Ausstattung/Schadenfrei',
      static: 'Statische',
      dynamic: 'Dynamische',
      missingFeatures: 'Fehlende Ausstattung',
      forDisabled: 'für Behinderte'
    },
    sectionNotes: {
      placeholder: 'Keine Bemerkung',
      qualityOfStay: 'Bemerkung Aufenthaltsqualität 1 und 2',
      passengerInformation: 'Bemerkung Fahrgastinformationen',
      accessibility: 'Bemerkung Barrierefreiheit'
    },
    devaluationUnits: {
      seats: 'Sitzgelegenheiten',
      damages: 'Schäden',
      graffiti: 'Graffiti',
      lighting: 'Beleuchtung',
      elevators: 'Aufzüge',
      pollution: 'Verschmutzung',
      showcases: 'Vitrinen',
      vegetation: 'Vegetation / Herbstlaub',
      wasteBins: 'Abfallbehälter',
      odourNuisance: 'Geruchsbelästigung',
      weatherProtection: 'Wetterschutz',
      lyingAroundGarbage: 'Herumliegender Müll',
      moisturePuddles: 'Feuchtigkeit / Pfützenbildung',
      ticketMachines: 'Fahrkartenautomaten / Entwerter',
      stationNameplates: 'Stationsnamensschilder',
      timetableDisplay: 'Fahrplanaushang',
      displaysDsa: 'Anzeigetafeln Fahrgastinformationen',
      timeDisplay: 'Zeitanzeige',
      platformAccess: 'Zugang zu Bahnsteig ist stufenlos, sowie Höhe der Bahnsteigkante > 76cm',
      tactileGuidanceSystem: 'Taktiles Leitsystem auf den Bahnsteigen vorhanden'
    },
    featureCondition: {
      exists: 'vorhanden',
      doesNotExist: 'nicht vorhanden',
      conditionOk: 'Zustand OK',
      conditionDamaged: 'beschädigt/defekt'
    }
  }
};
