import { LayoutState, SnackbarConfig } from '@/shared/modules/Layout/types';
import { AxiosResponse } from 'axios';
import { VueConstructor } from 'vue';
import { ActionTree, Module, MutationTree } from 'vuex';

export const LAYOUT_NAMESPACE = 'LAYOUT_NAMESPACE';

const state: LayoutState = {
  menuLocked: false,
  snackbar: {
    visible: false,
    config: {
      title: '',
      description: '',
      color: '',
      icon: undefined,
      timeout: 0
    }
  },
  stationAssessmentCountUnapproved: 0,
  stationAssessmentImagesNotReviewed: 0,
  rideAssessmentCountExported: 0
};

const mutations: MutationTree<LayoutState> = {
  setStationAssessmentCountUnapproved(state, payload) {
    state.stationAssessmentCountUnapproved = payload;
  },
  setStationAssessmentImagesNotReviewed(state, payload) {
    state.stationAssessmentImagesNotReviewed = payload;
  },
  setRideAssessmentCountExported(state, payload) {
    state.rideAssessmentCountExported = payload;
  },
  lockMenu(state) {
    state.menuLocked = true;
  },
  unlockMenu(state) {
    state.menuLocked = false;
  },
  showSnackbar(state, payload: SnackbarConfig) {
    state.snackbar = {
      visible: true,
      config: {
        title: payload.title,
        description: payload.description,
        color: payload.color,
        icon: payload.icon,
        timeout: payload.timeout
      }
    };
  },
  hideSnackbar(state) {
    state.snackbar.visible = false;
  }
};

const configureActions: (Vue: VueConstructor) => ActionTree<LayoutState, unknown> = Vue => {
  const actions: ActionTree<LayoutState, unknown> = {
    async fetchStationAssessmentCountUnapproved({ commit }): Promise<void> {
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('/station/assessment/count/unaudited');
        const unapprovedCount = response?.data.unaudited ?? 0;
        commit('setStationAssessmentCountUnapproved', unapprovedCount);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchStationAssessmentImagesNotReviewed({ commit }): Promise<void> {
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('/station/assessment/count/images-not-reviewed');
        const imagesNotReviewedCount = response?.data.imagesNotReviewed ?? 0;
        commit('setStationAssessmentImagesNotReviewed', imagesNotReviewedCount);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRideAssessmentCountExported({ commit }): Promise<void> {
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('/ride/assessment/count/exported');
        const exportedCount = response?.data.exported ?? 0;
        commit('setRideAssessmentCountExported', exportedCount);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return actions;
};

export const configureLayoutStore: (Vue: VueConstructor) => Module<LayoutState, unknown> = Vue => {
  const module: Module<LayoutState, unknown> = {
    namespaced: true,
    state,
    mutations,
    actions: configureActions(Vue)
  };
  return module;
};
