import { v4 as uuid } from 'uuid';
import { getDiff } from 'recursive-diff';
import { formatImageDownloadDateTime } from '@/shared/helper/date';

import type {
  DevaluationConditionUnit,
  DevaluationRangeUnit,
  StationAssessment
} from '@/shared/modules/AssessmentEdit/types/station';
import type { AssessmentValidationUnitStatus, NavigationSection } from '@/shared/modules/AssessmentEdit/types';

function createEmptyStationAssessment(
  stationCode: string,
  stationName: string,
  assessmentDateTime: string,
  author: string
): StationAssessment {
  return {
    assessmentId: uuid(),
    stationCode: stationCode,
    stationName: stationName,
    assessmentDateTime,
    author,
    assessmentStatus: 'incomplete',
    isTransmitting: false,
    sections: {
      qualityOfStayOne: {
        visited: false
      },
      qualityOfStayTwo: {
        visited: false
      },
      accessibility: {
        visited: false
      },
      passengerInformation: {
        visited: false
      }
    },
    exportDateTime: null,
    qualityOfStay: {
      lyingAroundGarbage: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      pollution: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      odourNuisance: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      moisturePuddles: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      graffiti: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      damages: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      vegetation: {
        type: 'range',
        value: undefined,
        note: '',
        images: []
      },
      ticketMachines: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      elevators: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      showcases: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      lighting: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      seats: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      weatherProtection: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      wasteBins: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      note: '',
      images: []
    },
    passengerInformation: {
      stationNameplates: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      timetableDisplay: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      displaysDsa: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      timeDisplay: {
        type: 'condition',
        value: undefined,
        note: '',
        images: []
      },
      note: '',
      images: []
    },
    accessibility: {
      platformAccess: {
        type: 'boolean',
        value: undefined
      },
      tactileGuidanceSystem: {
        type: 'boolean',
        value: undefined
      },
      note: '',
      images: []
    }
  };
}

function getNewAssessmentId(): string {
  return uuid();
}

function getImageDownloadTitle(stationName: string, assessmentDateTime: string, unitTitle: string): string {
  const date = formatImageDownloadDateTime(assessmentDateTime);
  const station = stationName.replaceAll('/', '_').replaceAll(' ', '_');
  const unit = unitTitle.replaceAll(' /', '').replaceAll(' ', '_');

  return `${date}_${station}_${unit}`;
}

function stationAssessmentHasChanged(initial: StationAssessment, updated: StationAssessment): boolean {
  return getDiff(initial, updated).length > 0;
}

function getStationAssessmentImageIds(stationAssessment: StationAssessment): string[] {
  const imageIds: string[] = [];

  imageIds.push(...stationAssessment.qualityOfStay.images);
  imageIds.push(...stationAssessment.passengerInformation.images);
  imageIds.push(...stationAssessment.accessibility.images);

  const qualityOfStayDevaluationUnits = Object.entries(stationAssessment.qualityOfStay)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as (DevaluationConditionUnit | DevaluationRangeUnit)[];
  const passengerInformationDevaluationUnits = Object.entries(stationAssessment.passengerInformation)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as DevaluationConditionUnit[];
  [...qualityOfStayDevaluationUnits, ...passengerInformationDevaluationUnits].forEach(devaluationUnit => {
    imageIds.push(...devaluationUnit.images);
  });

  return imageIds;
}

function getStationAssessmentNavigationSections(
  qualityOfStayStatus: AssessmentValidationUnitStatus,
  passengerInformationStatus: AssessmentValidationUnitStatus,
  accessibilityStatus: AssessmentValidationUnitStatus
): NavigationSection[] {
  return [
    {
      label: 'stationAssessmentEdit.sections.qualityOfStay',
      routeName: 'station-assessment-quality-of-stay',
      status: qualityOfStayStatus,
      dataTest: 'nav-section-quality-of-stay'
    },
    {
      label: 'stationAssessmentEdit.sections.passengerInformation',
      routeName: 'station-assessment-passenger-information',
      status: passengerInformationStatus,
      dataTest: 'nav-section-passenger-information'
    },
    {
      label: 'stationAssessmentEdit.sections.accessibility',
      routeName: 'station-assessment-accessibility',
      status: accessibilityStatus,
      dataTest: 'nav-section-accessibility'
    }
  ];
}

export {
  createEmptyStationAssessment,
  getNewAssessmentId,
  getImageDownloadTitle,
  stationAssessmentHasChanged,
  getStationAssessmentImageIds,
  getStationAssessmentNavigationSections
};
