import { DevaluationUnitName, DevaluationUnitSectionName } from '@/shared/modules/AssessmentEdit/types/ride';

const NoImagesAllowedDevaluationUnits: DevaluationUnitName[] = [
  'stationTrainAnnouncement',
  'stationDisruptionAnnouncement',
  'conductorCount',
  'conductorInactivity',
  'conductorAppearance',
  'conductorBehavior',
  'securityStaffCount',
  'securityStaffInactivity',
  'securityStaffAppearance',
  'securityStaffBehavior',
  'onboardServiceCount',
  'onboardServiceBehavior',
  'seats',
  'standingRoom',
  'speakerAnnouncement',
  'passengerInformationSystem',
  'functionHeating',
  'disruptionAnnouncement'
];

const DeactivatableFields = [
  'trainNumber',
  'departureStationId',
  'departureTimeNominal',
  'departureTimeActual',
  'arrivalStationId',
  'arrivalTimeNominal',
  'arrivalTimeActual',
  'stationInformation[0]', // stationTrainAnnouncement
  'stationInformation[1]', // stationDisruptionAnnouncement
  'stationInformation[2]', // stationDestinationDisplay
  'stationInformation[3]', // stationDisruptionDisplay
  'stationInformation[4]', // trainLabel
  'wagonTypes', // wagonTypes
  'wagonState[0]', // vehicleNumber
  'wagonState[1]', // safetyEndangeringDefects
  'wagonState[2]', // functionExteriorShell
  'wagonState[3]', // cleanlinessExteriorShell
  'wagonState[4]', // exteriorGraffiti
  'wagonState[5]', // functionWindows
  'wagonState[6]', // cleanlinessWindows
  'wagonState[7]', // functionPassageDoors
  'wagonState[8]', // cleanlinessPassageDoors
  'wagonState[9]', // functionFloor
  'wagonState[10]', // cleanlinessFloor
  'wagonState[11]', // functionSeats
  'wagonState[12]', // cleanlinessSeats
  'wagonState[13]', // functionWasteBins
  'wagonState[14]', // cleanlinessWasteBins
  'wagonState[15]', // functionInteriorScaffold
  'wagonState[16]', // cleanlinessInteriorScaffold
  'wagonState[17]', // interiorGraffiti
  'wagonState[18]', // functionLights
  'wagonState[19]', // functionHeating
  'wagonState[20]', // functionToilets
  'wagonState[21]', // cleanlinessToilets
  'wagonState[22]', // functionExteriorDoors
  'wagonState[23]', // cleanlinessExteriorDoors
  'conductors[0]', // conductorCount
  'conductors[1]', // conductorInactivity
  'conductors[2]', // conductorAppearance
  'conductors[3]', // conductorBehavior
  'securityStaff[0]', // securityStaffCount
  'securityStaff[1]', // securityStaffInactivity
  'securityStaff[2]', // securityStaffAppearance
  'securityStaff[3]', // securityStaffBehavior
  'onboardService[0]', // onboardServiceCount
  'onboardService[1]', // onboardServiceBehavior
  'utilization[0]', // seats
  'utilization[1]', // standingRoom
  'inTrainInformation[0]', // routeNetworkPlan
  'inTrainInformation[1]', // speakerAnnouncement
  'inTrainInformation[2]', // passengerInformationSystem
  'disruption[0]', // disrupted
  'disruption[1]', // disruptionAnnouncement
  'disruption[2]', // disruptionStartStationId
  'disruption[3]', // disruptionEndStationId
  'assessmentComment'
];

const DevaluationUnitDependencies: Record<string, string[]> = {
  cancelled: [
    'arrivalTimeActual',
    'stationInformation[4]', // trainLabel
    'wagonTypes', // wagonTypes
    'wagonState[0]', // vehicleNumber
    'wagonState[1]', // safetyEndangeringDefects
    'wagonState[2]', // functionExteriorShell
    'wagonState[3]', // cleanlinessExteriorShell
    'wagonState[4]', // exteriorGraffiti
    'wagonState[5]', // functionWindows
    'wagonState[6]', // cleanlinessWindows
    'wagonState[7]', // functionPassageDoors
    'wagonState[8]', // cleanlinessPassageDoors
    'wagonState[9]', // functionFloor
    'wagonState[10]', // cleanlinessFloor
    'wagonState[11]', // functionSeats
    'wagonState[12]', // cleanlinessSeats
    'wagonState[13]', // functionWasteBins
    'wagonState[14]', // cleanlinessWasteBins
    'wagonState[15]', // functionInteriorScaffold
    'wagonState[16]', // cleanlinessInteriorScaffold
    'wagonState[17]', // interiorGraffiti
    'wagonState[18]', // functionLights
    'wagonState[19]', // functionHeating
    'wagonState[20]', // functionToilets
    'wagonState[21]', // cleanlinessToilets
    'wagonState[22]', // functionExteriorDoors
    'wagonState[23]', // cleanlinessExteriorDoors
    'conductors[0]', // conductorCount
    'conductors[1]', // conductorInactivity
    'conductors[2]', // conductorAppearance
    'conductors[3]', // conductorBehavior
    'securityStaff[0]', // securityStaffCount
    'securityStaff[1]', // securityStaffInactivity
    'securityStaff[2]', // securityStaffAppearance
    'securityStaff[3]', // securityStaffBehavior
    'onboardService[0]', // onboardServiceCount
    'onboardService[1]', // onboardServiceBehavior
    'utilization[0]', // seats
    'utilization[1]', // standingRoom
    'inTrainInformation[0]', // routeNetworkPlan
    'inTrainInformation[1]', // speakerAnnouncement
    'inTrainInformation[2]', // passengerInformationSystem
    'disruption[0]', // disrupted
    'disruption[1]', // disruptionAnnouncement
    'disruption[2]', // disruptionStartStationId
    'disruption[3]' // disruptionEndStationId
  ],
  safetyEndangeringDefects: [
    'wagonState[2]', // functionExteriorShell
    'wagonState[3]', // cleanlinessExteriorShell
    'wagonState[4]', // exteriorGraffiti
    'wagonState[5]', // functionWindows
    'wagonState[6]', // cleanlinessWindows
    'wagonState[7]', // functionPassageDoors
    'wagonState[8]', // cleanlinessPassageDoors
    'wagonState[9]', // functionFloor
    'wagonState[10]', // cleanlinessFloor
    'wagonState[11]', // functionSeats
    'wagonState[12]', // cleanlinessSeats
    'wagonState[13]', // functionWasteBins
    'wagonState[14]', // cleanlinessWasteBins
    'wagonState[15]', // functionInteriorScaffold
    'wagonState[16]', // cleanlinessInteriorScaffold
    'wagonState[17]', // interiorGraffiti
    'wagonState[18]', // functionLights
    'wagonState[19]', // functionHeating
    'wagonState[20]', // functionToilets
    'wagonState[21]', // cleanlinessToilets
    'wagonState[22]', // functionExteriorDoors
    'wagonState[23]' // cleanlinessExteriorDoors
  ],
  conductorCount: [
    'conductors[1]', // conductorInactivity
    'conductors[2]', // conductorAppearance
    'conductors[3]' // conductorBehavior
  ],
  conductorInactivity: [
    'conductors[2]', // conductorAppearance
    'conductors[3]' // conductorBehavior
  ],
  securityStaffCount: [
    'securityStaff[1]', // securityStaffInactivity
    'securityStaff[2]', // securityStaffAppearance
    'securityStaff[3]' // securityStaffBehavior
  ],
  securityStaffInactivity: [
    'securityStaff[2]', // securityStaffAppearance
    'securityStaff[3]' // securityStaffBehavior
  ],
  onboardServiceCount: [
    'onboardService[1]' // onboardServiceBehavior
  ],
  disrupted: [
    'disruption[1]', // disruptionAnnouncement
    'disruption[2]', // disruptionStartStationId
    'disruption[3]' // disruptionEndStationId
  ],
  noComment: ['assessmentComment'],
  noToilet: [
    'wagonState[20]', // functionToilets
    'wagonState[21]' // cleanlinessToilets
  ]
};

const RideAssessmentDevaluationUnitSections: DevaluationUnitSectionName[] = [
  'stationInformation',
  'wagonState',
  'conductors',
  'securityStaff',
  'onboardService',
  'utilization',
  'inTrainInformation',
  'disruption'
];

const wagonTypeWithoutToilet = 'ET 422';

export {
  DeactivatableFields,
  DevaluationUnitDependencies,
  NoImagesAllowedDevaluationUnits,
  RideAssessmentDevaluationUnitSections,
  wagonTypeWithoutToilet
};
