<template>
  <div>
    <v-data-table
      :value="value"
      :headers="visibleHeaders"
      :items="assessments"
      :options="options"
      :mobile-breakpoint="0"
      :hide-default-header="isPhone"
      :show-select="selectable"
      :loading="loading"
      class="table"
      hide-default-footer
      item-key="assessmentId"
      @update:options="$emit('update:options', $event)"
      @click:row="emitAssessmentClicked"
      @input="$emit('input', $event)"
    >
      <template #header.lineName="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-text-field
            v-debounce:500="value => updateSearch('lineName', value)"
            @click:clear="() => updateSearch('lineName', undefined)"
            :value="search.lineName"
            :label="header.text"
            hide-details
            clearable
          >
            <template #append>
              <v-icon class="mt-1" small>fal fa-search</v-icon>
            </template>
          </v-text-field>
        </div>
      </template>
      <template #header.trainNumber="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-text-field
            v-debounce:500="value => updateSearch('trainNumber', value)"
            @click:clear="() => updateSearch('trainNumber', undefined)"
            :value="search.trainNumber"
            :label="header.text"
            hide-details
            clearable
          />
        </div>
      </template>
      <template #header.assessmentDateTime="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-menu
            v-model="showAssessmentDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                @click:clear="() => updateSearch('assessmentDateTime', undefined)"
                :value="search.assessmentDateTime"
                :label="header.text"
                hide-details
                clearable
                readonly
              >
                <template #append>
                  <v-icon class="mt-1" small>fal fa-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @input="value => updateSearch('assessmentDateTime', value)"
              :value="search.assessmentDateTime"
              no-title
              scrollable
            />
          </v-menu>
        </div>
      </template>
      <template #header.departureStationCode="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-text-field
            v-debounce:500="value => updateSearch('departureStationCode', value)"
            @click:clear="() => updateSearch('departureStationCode', undefined)"
            :value="search.departureStationCode"
            :label="header.text"
            hide-details
            clearable
          />
        </div>
      </template>
      <template #header.arrivalStationCode="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-text-field
            v-debounce:500="value => updateSearch('arrivalStationCode', value)"
            @click:clear="() => updateSearch('arrivalStationCode', undefined)"
            :value="search.arrivalStationCode"
            :label="header.text"
            hide-details
            clearable
          />
        </div>
      </template>
      <template #header.assessmentValidationStatus="{ header }">
        <div class="filter d-inline-block pr-2" @click.stop="() => {}">
          <v-select
            @change="value => updateSearch('assessmentValidationStatus', value)"
            :value="search.assessmentValidationStatus"
            :append-icon="null"
            :label="header.text"
            :items="statusSelectEntries"
            hide-details
            clearable
          />
        </div>
      </template>

      <template #item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          data-test="row-select-checkbox"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template #item.statusIndicator="{ item }">
        <td
          class="px-0"
          :class="[
            'status-cell',
            {
              'status-cell--complete': item.assessmentValidationStatus === 'complete',
              'status-cell--with-checkbox': selectable
            }
          ]"
        ></td>
      </template>
      <template #item.statusIndicatorPlaceholder>
        <td class="px-0 status-cell status-cell--hidden" :class="{ 'status-cell--with-checkbox': selectable }"></td>
      </template>
      <template #item.lineName="{ item }">
        <td class="line-code-cell">
          <p class="text-truncate">
            <span>{{ item.lineName }}</span>
            <span v-if="isPhone" class="ml-2 lightgrey--text text--darken-2">
              - {{ $t('rideAssessment.trainNumber') }} {{ item.trainNumber }}</span
            >
          </p>
          <p v-if="isPhone" class="line-code-cell__date lightgrey--text text--darken-2">
            {{ formatDate(item.assessmentDateTime) }} - {{ $t('rideAssessment.departureStation') }}
            {{ item.departureStationCode }}
          </p>
        </td>
      </template>
      <template #item.assessmentDateTime="{ value }">
        {{ $d(new Date(value), 'datetime') }}
      </template>
      <template #item.assessmentValidationStatus="{ value }">
        <span :class="['status-indicator mr-1', { 'status-indicator--complete': value === 'complete' }]"></span
        >{{ $t(`rideAssessment.statuses.${value}`) }}
      </template>
      <template #item.deleteAction="{ item }">
        <td class="action-cell">
          <v-tooltip :disabled="isPhone" bottom>
            <template #activator="{ on }">
              <v-btn
                :disabled="onlineDelete && offline"
                v-on="on"
                data-test="button-delete"
                x-small
                icon
                @click.stop="showAssessmentDeleteDialog(item)"
              >
                <v-icon>far fa-trash</v-icon>
              </v-btn>
            </template>
            {{ $t('rideAssessment.actions.deleteAssessment') }}
          </v-tooltip>
        </td>
      </template>
      <template #item.synchronizeAction="{ item }">
        <td class="action-cell">
          <v-tooltip :disabled="isPhone" bottom>
            <template #activator="{ on }">
              <v-btn
                :disabled="item.assessmentValidationStatus !== 'complete' || offline"
                :loading="item.isSynchronizing"
                color="primary"
                v-on="on"
                data-test="button-synchronize"
                x-small
                icon
                @click.stop="showAssessmentSynchronizeDialog(item)"
              >
                <v-icon>far fa-arrow-up-from-bracket</v-icon>
              </v-btn>
            </template>
            {{ $t('rideAssessment.actions.synchronizeAssessment') }}
          </v-tooltip>
        </td>
      </template>
      <template #item.reviseAction="{ item }">
        <td class="action-cell">
          <v-tooltip :disabled="isPhone" bottom>
            <template #activator="{ on }">
              <v-btn
                :disabled="offline"
                :loading="item.isSynchronizing"
                color="primary"
                v-on="on"
                data-test="button-revise"
                x-small
                icon
                @click.stop="showAssessmentReviseDialog(item)"
              >
                <v-icon>far fa-pen</v-icon>
              </v-btn>
            </template>
            {{ $t('rideAssessment.actions.reviseAssessment') }}
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
    <app-confirm-dialog
      :value="showDeleteDialog"
      :color="'error'"
      :confirm-text-key="'layout.common.delete'"
      @cancel="resetCurrentAssessment"
      @confirm="emitDelete"
    >
      <template #title>{{ $t('rideAssessment.deleteDialog.title') }}</template>
      <template #text>{{ $t('rideAssessment.deleteDialog.description', [currentAssessmentLineName]) }}</template>
    </app-confirm-dialog>
    <app-confirm-dialog
      :value="showSynchronizeDialog"
      :confirm-text-key="'rideAssessment.actions.synchronizeAssessmentShort'"
      @cancel="resetCurrentAssessment"
      @confirm="emitSynchronize"
    >
      <template #title>{{ $t('rideAssessment.synchronizeDialog.title') }}</template>
      <template #text>{{ $t('rideAssessment.synchronizeDialog.description', [currentAssessmentLineName]) }}</template>
    </app-confirm-dialog>
    <app-confirm-dialog
      :value="showReviseDialog"
      :confirm-text-key="'rideAssessment.actions.reviseAssessmentShort'"
      @cancel="resetCurrentAssessment"
      @confirm="emitRevise"
    >
      <template #title>{{ $t('rideAssessment.reviseDialog.title') }}</template>
      <template #text>{{ $t('rideAssessment.reviseDialog.description', [currentAssessmentLineName]) }}</template>
    </app-confirm-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { formatDate } from '@/shared/helper/date';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';

import type {
  ListRideAssessment,
  RideAssessmentOptions,
  RideAssessmentSearch,
  RideAssessmentValidationStatus,
  RideAssessmentTableHeader
} from '@/pwa/modules/Assessment/types/ride';

export default Vue.extend({
  components: { AppConfirmDialog },
  props: {
    assessments: {
      type: Array as PropType<ListRideAssessment[]>,
      required: true
    },
    search: {
      type: Object as PropType<RideAssessmentSearch>,
      required: true
    },
    options: {
      type: Object as PropType<RideAssessmentOptions>,
      required: true
    },
    columns: {
      type: Array as PropType<RideAssessmentTableHeader['value'][]>,
      default: () => ['lineName', 'trainNumber', 'assessmentDateTime']
    },
    selectable: {
      type: Boolean,
      default: false
    },
    offline: {
      type: Boolean,
      default: false
    },
    onlineDelete: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array as PropType<ListRideAssessment[]>,
      default: () => []
    }
  },
  data() {
    return {
      showAssessmentDatePicker: false,
      showDeleteDialog: false,
      showSynchronizeDialog: false,
      showReviseDialog: false,
      currentAssessment: undefined as ListRideAssessment | undefined,
      statusSelectEntries: [
        {
          text: this.$t('rideAssessment.statuses.complete'),
          value: 'complete'
        },
        {
          text: this.$t('rideAssessment.statuses.incomplete'),
          value: 'incomplete'
        }
      ] as { text: string; value: RideAssessmentValidationStatus }[],
      headers: [
        {
          text: '',
          value: 'statusIndicator',
          sortable: false,
          width: this.selectable ? 35 : 88,
          class: 'status-cell status-cell--hidden'
        },
        {
          text: '',
          value: 'statusIndicatorPlaceholder',
          sortable: false,
          width: this.selectable ? 35 : 88,
          class: 'status-cell status-cell--hidden'
        },
        {
          text: '',
          // value used by vuetify
          value: 'data-table-select',
          sortable: false,
          width: 1,
          class: 'checkbox-cell',
          cellClass: 'checkbox-cell'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.lineName'),
          value: 'lineName',
          class: 'text-no-wrap'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.trainNumber'),
          value: 'trainNumber',
          class: 'text-no-wrap',
          hide: 'xs'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.assessmentDateTime'),
          value: 'assessmentDateTime',
          class: 'text-no-wrap',
          hide: 'xs'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.departureStationCode'),
          value: 'departureStationCode',
          class: 'text-no-wrap',
          hide: 'xs'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.arrivalStationCode'),
          value: 'arrivalStationCode',
          class: 'text-no-wrap',
          hide: 'xs'
        },
        {
          text: this.$t('rideAssessment.tableHeaders.status'),
          value: 'assessmentValidationStatus',
          class: 'text-no-wrap',
          hide: 'smAndDown'
        },
        {
          text: '',
          value: 'deleteAction',
          align: 'end',
          sortable: false,
          hide: 'xs'
        },
        {
          text: '',
          value: 'synchronizeAction',
          align: 'end',
          sortable: false
        },
        {
          text: '',
          value: 'reviseAction',
          align: 'end',
          sortable: false
        }
      ] as RideAssessmentTableHeader[]
    };
  },
  computed: {
    currentAssessmentLineName(): string | undefined {
      return this.currentAssessment?.lineName;
    },
    visibleHeaders(): RideAssessmentTableHeader[] {
      // adapted from https://github.com/vuetifyjs/vuetify/issues/3993#issuecomment-389818032
      return this.headers.filter(
        header => (!header.hide || !this.$vuetify.breakpoint[header.hide]) && this.columns.includes(header.value)
      );
    },
    isPhone(): boolean {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    formatDate,
    updateSearch(field: string, value: string | null | undefined) {
      this.showAssessmentDatePicker = false;
      this.$emit('update:search', {
        ...this.search,
        [field]: value === '' || value === null ? undefined : value
      });
    },
    showAssessmentDeleteDialog(item: ListRideAssessment) {
      this.currentAssessment = item;
      this.showDeleteDialog = true;
    },
    showAssessmentSynchronizeDialog(item: ListRideAssessment) {
      this.currentAssessment = item;
      this.showSynchronizeDialog = true;
    },
    showAssessmentReviseDialog(item: ListRideAssessment) {
      this.currentAssessment = item;
      this.showReviseDialog = true;
    },
    resetCurrentAssessment() {
      this.showDeleteDialog = false;
      this.showSynchronizeDialog = false;
      this.showReviseDialog = false;
      this.currentAssessment = undefined;
    },
    emitDelete() {
      this.showDeleteDialog = false;
      this.$emit('deleteAssessment', this.currentAssessment?.assessmentId);
      this.currentAssessment = undefined;
    },
    emitSynchronize() {
      this.showSynchronizeDialog = false;
      this.$emit('synchronizeAssessment', this.currentAssessment);
      this.currentAssessment = undefined;
    },
    emitRevise() {
      this.showReviseDialog = false;
      this.$emit('reviseAssessment', this.currentAssessment?.assessmentId);
      this.currentAssessment = undefined;
    },
    emitAssessmentClicked(assessment: ListRideAssessment) {
      this.$emit('assessmentClicked', assessment.assessmentId);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';
@import '@/shared/styles/media.scss';

.table {
  --table-inline-margin: var(--inline-margin, 24px);

  margin-inline: calc(-1 * var(--table-inline-margin));
  max-width: calc(100% + var(--table-inline-margin) * 2);

  @include sm-and-up {
    --table-inline-margin: var(--inline-margin, 88px);
  }

  & ::v-deep tbody > tr {
    cursor: pointer;
  }

  :is(td, th) {
    @include sm-and-up {
      border-bottom-width: 0 !important;
    }

    &:last-child {
      padding-right: 20px;

      @include sm-and-up {
        padding-right: 48px;
      }
    }
  }

  ::v-deep .checkbox-cell {
    @include xs-only {
      // table hack part 2
      width: 1px;

      padding-left: 0;
    }
  }

  // vertically align first column with tabs
  ::v-deep :is(.status-cell, .checkbox-cell) + :is(td, th):not(.checkbox-cell) {
    padding-left: 0;
  }

  p {
    margin-bottom: 0;
  }

  ::v-deep .v-data-table__wrapper {
    overflow-x: hidden;
  }

  ::v-deep .action-cell {
    padding-right: 0;

    @include xs-only {
      // table hack part 2
      width: 1px;
    }

    & ~ .action-cell {
      padding-left: 8px;
    }
  }
}

.status-indicator {
  background-color: var(--v-error-base);
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  vertical-align: middle;
  width: 8px;

  &--complete {
    background-color: var(--v-primary-base);
  }
}

::v-deep .status-cell {
  position: relative;
  min-width: var(--table-inline-margin);
  width: var(--table-inline-margin);

  &::before {
    background-color: var(--v-error-base);
    content: '';
    display: block;
    // overlap row border
    height: calc(100% + 1px);
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;

    @include sm-and-up {
      height: 100%;
      width: 7px;
    }
  }

  &--complete::before {
    background-color: var(--v-primary-base);
  }

  &--hidden::before {
    content: none;
  }

  &--with-checkbox {
    @include sm-and-up {
      --checkbox-width: 53px;
      min-width: calc(var(--table-inline-margin) - var(--checkbox-width));
      width: calc(var(--table-inline-margin) - var(--checkbox-width));
    }
  }
}

::v-deep .line-code-cell {
  @include xs-only {
    padding-right: 0 !important;

    // table hack to enable ellipsis overflow (https://stackoverflow.com/questions/39148127/how-to-work-with-ellipsis-in-bootstrap-responsive-table)
    // part 1
    max-width: 1px;
  }

  &__date,
  &__departure-station {
    font-size: 0.6875rem;
  }
}
</style>
