<template>
  <v-dialog
    :value="null !== devaluationUnitName"
    :fullscreen="fullscreenImageVisible && !$vuetify.breakpoint.mdAndUp"
    :content-class="fullscreenImageVisible ? 'camera-and-notes-dialog--auto-width' : undefined"
    max-width="1280"
    persistent
  >
    <v-card class="camera-and-notes-modal" data-test="camera-and-notes-modal">
      <v-card-title v-if="!fullscreenImageVisible">
        <v-col class="pa-0 d-flex justify-md-center">
          <div class="d-flex align-center mb-2 justify-center">
            <v-img :src="icon" width="17" height="17" contain class="flex-grow-0 mr-2" />
            <h3 class="camera-and-notes-modal__title flex-grow-1 primary--text font-weight-regular">{{ $t(title) }}</h3>
          </div>
        </v-col>
      </v-card-title>
      <v-btn
        v-if="fullscreenImageVisible"
        @click="closeModal"
        class="button-close fullscreen"
        dark
        data-test="button-close"
        icon
      >
        <v-icon>far fa-times</v-icon>
      </v-btn>
      <v-btn v-else @click="closeModal" class="button-close" data-test="button-close" small icon>
        <v-icon color="grey" small>far fa-times</v-icon>
      </v-btn>
      <div v-if="fullscreenImageVisible" class="fullscreen-container d-flex">
        <img
          :src="getFullscreenImage(fullscreenImageIndex)"
          :alt="fullscreenImageIndex"
          class="fullscreen-container__image"
        />
        <div class="fullscreen-container__actions d-flex">
          <v-btn
            v-if="!readOnly"
            @click="openDeleteDialog"
            class="fullscreen-container__button"
            data-test="button-delete"
            fab
            dark
            small
          >
            <v-icon>far fa-trash-can</v-icon>
          </v-btn>
          <v-btn class="fullscreen-container__button" fab dark small data-test="button-download" @click="downloadImage">
            <v-icon>far fa-arrow-down-to-line</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12" offset-md="1" md="10">
            <v-divider />
            <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex mt-6 mb-3">
              <v-icon color="primary">fal fa-images</v-icon>
              <span class="ml-2 text-uppercase font-weight-bold">
                {{ $t('assessmentEdit.cameraAndNotesModal.pictures') }}
              </span>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <div v-if="noImagesAllowed" class="image-placeholder mt-7 mt-md-2 mb-4">
                  <div class="image-placeholder__camera">
                    <animated-icon class="icon" icon="no-camera" autoplay />
                  </div>
                  <p>{{ $t('assessmentEdit.cameraAndNotesModal.noImagesAllowed') }}</p>
                </div>
                <div
                  v-else-if="images.length === 0 && uploadingImageCount === 0"
                  class="image-placeholder mt-7 mt-md-2 mb-4"
                  data-test="no-image"
                >
                  <div class="image-placeholder__camera">
                    <animated-icon class="icon" icon="camera" autoplay />
                  </div>
                  <p class="text-uppercase font-weight-bold">
                    {{ $t('assessmentEdit.cameraAndNotesModal.images') }}
                  </p>
                  <p>{{ $t('assessmentEdit.cameraAndNotesModal.max3Images') }}</p>
                  <div v-if="$vuetify.breakpoint.mdAndUp && !readOnly">
                    <v-btn
                      v-if="images.length === 0"
                      color="primary"
                      class="button-camera"
                      data-test="button-add-images-empty"
                      @click="$refs.images.click()"
                    >
                      {{ $t('assessmentEdit.cameraAndNotesModal.uploadImages') }}
                    </v-btn>
                  </div>
                </div>
                <div v-else>
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-img
                      v-if="images.length > 0"
                      :src="selectedImageSrc"
                      alt=""
                      class="selected-image mt-3"
                      data-test="image-large"
                      @click="showFullscreenImage(selectedImageIndex)"
                    />
                    <div
                      v-else
                      class="selected-image selected-image--loading d-flex flex-column align-center justify-center"
                    >
                      <p>{{ $t('assessmentEdit.cameraAndNotesModal.savingImage') }}</p>
                      <animated-icon class="selected-image__camera" icon="camera" />
                    </div>
                  </template>
                  <!-- image row -->
                  <v-row class="my-4" :dense="$vuetify.breakpoint.smAndDown">
                    <!-- existing images -->
                    <v-col
                      cols="4"
                      v-for="(image, index) in images"
                      :key="index"
                      class="d-flex align-center"
                      data-test="image-thumbnail"
                    >
                      <v-img :src="image" class="thumbnail" @click="selectImage(index)">
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndUp && selectedImageIndex === index"
                          class="thumbnail--active"
                          color="white"
                        >
                          fal fa-check-circle
                        </v-icon>
                      </v-img>
                    </v-col>
                    <!-- uploading placeholders -->
                    <!-- prevent key collision because children of the same parent need a unique key -->
                    <v-col
                      cols="4"
                      v-for="i in uploadingImageCount"
                      :key="i + maxImages"
                      data-test="image-loading-thumbnail"
                    >
                      <div class="thumbnail-placeholder lightgrey lighten-2">
                        <v-progress-circular color="primary" width="3" indeterminate></v-progress-circular>
                      </div>
                    </v-col>
                    <!-- new image placeholder -->
                    <v-col v-if="$vuetify.breakpoint.mdAndUp && !maxImagesReached && !readOnly" cols="4">
                      <div class="thumbnail-placeholder lightgrey lighten-2">
                        <v-btn
                          color="primary"
                          class="button-add-images"
                          data-test="button-add-images"
                          @click="$refs.images.click()"
                          fab
                        >
                          <v-icon>far fa-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <div
                  class="d-flex justify-center align-center"
                  v-if="$vuetify.breakpoint.smAndDown && !readOnly && !noImagesAllowed"
                >
                  <v-btn
                    :disabled="maxImagesReached"
                    color="primary flat"
                    class="button-camera"
                    data-test="button-camera"
                    @click="$refs.camera.click()"
                  >
                    {{ $t('assessmentEdit.cameraAndNotesModal.takePhotos') }}
                  </v-btn>
                  <v-btn
                    :disabled="maxImagesReached"
                    color="primary"
                    class="button-add-images ml-3"
                    data-test="button-add-images"
                    @click="$refs.images.click()"
                    fab
                  >
                    <v-icon>far fa-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="5" offset-md="1" class="pt-12 pt-md-0 pb-8">
                <slot />

                <v-textarea
                  :value="note"
                  :label="$t('assessmentEdit.cameraAndNotesModal.noteLabel')"
                  :disabled="readOnly"
                  :counter="noteCharacterLimit"
                  :maxlength="noteCharacterLimit"
                  @change="addNote"
                  color="primary"
                  variant="underlined"
                  rows="3"
                  data-test="input-note"
                  class="mb-7"
                  auto-grow
                />

                <v-btn
                  v-if="$vuetify.breakpoint.xsOnly"
                  @click="() => $emit('closed')"
                  color="primary"
                  class="button-back"
                  data-test="button-back"
                  fab
                >
                  <v-icon>far fa-arrow-left</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <input
      @change="addImages"
      id="images"
      ref="images"
      type="file"
      accept="image/*"
      class="d-none"
      data-test="input-image"
      multiple
    />
    <input
      @change="addImages"
      id="camera"
      ref="camera"
      type="file"
      accept="image/*"
      class="d-none"
      capture="environment"
    />
    <app-confirm-dialog
      :value="uploadError"
      :confirm-text-key="'assessmentEdit.cameraAndNotesModal.errorDialog.button'"
      :show-cancel-button="false"
      @confirm="uploadError = false"
    >
      <template #title>{{ $t('assessmentEdit.cameraAndNotesModal.errorDialog.title') }}</template>
      <template #text><span v-html="$t('assessmentEdit.cameraAndNotesModal.errorDialog.text')" /></template>
    </app-confirm-dialog>
    <app-confirm-dialog
      :value="showDeleteDialog"
      :confirm-text-key="'assessmentEdit.cameraAndNotesModal.deleteDialog.button'"
      color="error"
      @confirm="deleteImage"
      @cancel="closeDeleteDialog"
    >
      <template #title>
        <div data-test="delete-image-dialog-title">
          {{ $t('assessmentEdit.cameraAndNotesModal.deleteDialog.title') }}
        </div>
      </template>
      <template #text>{{ $t('assessmentEdit.cameraAndNotesModal.deleteDialog.text') }}</template>
    </app-confirm-dialog>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { NavigationGuardNext } from 'vue-router';
import AnimatedIcon from '@/shared/modules/Global/components/AnimatedIcon.vue';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import { ASSESSMENT_MAX_IMAGES } from '@/shared/modules/AssessmentEdit/services/assessment-statics';
import { getImageCount } from '@/shared/modules/AssessmentEdit/services/image-service';
import { DialogNavigationMixin } from '@/shared/modules/Global/mixins/DialogNavigationMixin';

export default Vue.extend({
  components: { AnimatedIcon, AppConfirmDialog },
  mixins: [DialogNavigationMixin('handleNavigation')],
  props: {
    devaluationUnitName: {
      type: String as PropType<string | null>,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    images: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    note: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    downloadTitle: {
      type: String,
      default: ''
    },
    noImagesAllowed: {
      type: Boolean,
      default: false
    },
    noNoteCharacterLimit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreenImageIndex: null as number | null,
      showDeleteDialog: false,
      selectedImageIndex: 0,
      maxImages: ASSESSMENT_MAX_IMAGES,
      uploadingImageCount: 0,
      uploadError: false
    };
  },
  computed: {
    selectedImageSrc(): string {
      return this.images.find((_, index: number) => index === this.selectedImageIndex) ?? '';
    },
    maxImagesReached(): boolean {
      return this.images.length + this.uploadingImageCount >= this.maxImages;
    },
    fullscreenImageVisible(): boolean {
      return this.fullscreenImageIndex !== null;
    },
    noteCharacterLimit(): number | undefined {
      return this.noNoteCharacterLimit ? undefined : 255;
    }
  },
  methods: {
    closeModal() {
      if (null !== this.fullscreenImageIndex) {
        this.fullscreenImageIndex = null;
        return;
      }

      this.$emit('closed');
    },
    handleNavigation(next: NavigationGuardNext) {
      this.closeModal();
      next(false);
    },
    addImages(event: Event) {
      const newImageCount = getImageCount(event);
      if (this.images.length + this.uploadingImageCount + newImageCount > this.maxImages) {
        this.uploadError = true;
        return;
      }
      this.addLoadingIndicators(newImageCount);
      this.$emit('add-images', event);
    },
    addLoadingIndicators(count: number) {
      this.uploadingImageCount += count;
    },
    addNote(note: string) {
      this.$emit('add-note', note);
    },
    selectImage(index: number) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.selectedImageIndex = index;
        return;
      }

      this.fullscreenImageIndex = index;
    },
    showFullscreenImage(index: number) {
      this.fullscreenImageIndex = index;
    },
    getFullscreenImage(index: number) {
      return this.images.find((_, _index) => index === _index);
    },
    downloadImage() {
      const image = this.getFullscreenImage(this.fullscreenImageIndex ?? 0);
      if (!image) {
        return;
      }

      const link = document.createElement('a');
      link.href = image;
      link.download = this.downloadTitle;
      link.click();
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    deleteImage() {
      this.$emit('delete-image', this.fullscreenImageIndex);
      this.closeDeleteDialog();
      this.fullscreenImageIndex = null;
      this.selectedImageIndex = 0;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    }
  },
  watch: {
    images: function (newImages: string[], oldImages: string[]) {
      const lengthDifference = newImages.length - oldImages.length;

      if (lengthDifference > 0) {
        this.uploadingImageCount -= lengthDifference;
      }

      // consistency checks
      if (newImages.length + this.uploadingImageCount > this.maxImages) {
        this.uploadingImageCount = this.maxImages - newImages.length;
      }
      if (this.uploadingImageCount < 0) {
        this.uploadingImageCount = 0;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';

::v-deep .camera-and-notes-dialog--auto-width {
  @include md-and-up {
    width: auto;
  }
}

.camera-and-notes-modal {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(auto, 100%);
  height: 100%;
  min-height: 200px;
  min-width: 200px;

  &__title {
    font-size: 0.75rem;
    line-height: 1.1875;

    @include md-and-up {
      font-size: 1rem;
    }
  }

  .button-close {
    position: absolute;
    top: 5px;
    right: 5px;

    @include md-and-up {
      top: 10px;
      right: 16px;
    }

    &.fullscreen {
      background-color: var(--v-black-base);
      opacity: 0.7;
      z-index: 1;
    }
  }

  .image-placeholder {
    width: 125px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include md-and-up {
      width: 100%;
      max-width: 400px;
      aspect-ratio: 1/1;
      background-color: var(--v-lightgrey-lighten2);
      border-radius: 50%;
      justify-content: center;
      margin-left: 0;
    }

    &__camera {
      max-width: 80px;
    }
  }

  .thumbnail-placeholder {
    aspect-ratio: 4/3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-camera {
    text-transform: none;
    font-weight: bold;
  }

  .button-add-images {
    // remove default button indentation
    text-indent: 0;
    width: 44px;
    height: 44px;

    .v-icon {
      font-size: 20px;
    }
  }

  .button-back {
    width: 44px;
    height: 44px;
    position: absolute;
    bottom: 18px;
    right: 25px;
  }

  .thumbnail {
    width: 100%;
    background-color: var(--v-black-base);
    border-radius: 5px;
    object-fit: cover;
    aspect-ratio: 4/3;

    &:hover {
      cursor: pointer;
    }

    &--active {
      position: absolute;
      bottom: 5px;
      right: 5px;
      z-index: 1;
      background-color: var(--v-darkgrey-base);
      border-radius: 50%;
    }
  }

  .selected-image {
    height: 350px;
    background-color: var(--v-black-base);
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }

    &--loading {
      background-color: var(--v-lightgrey-lighten2);

      &:hover {
        cursor: auto;
      }
    }

    &__camera {
      max-width: 150px;
      flex-basis: 0;
    }
  }

  .fullscreen-container {
    height: 100%;

    &__image {
      max-height: 100%;
      max-width: 100%;
      border-radius: 5px;
      height: auto;
      margin: auto;

      @include md-and-up {
        max-height: 90vh;
      }
    }

    &__actions {
      position: absolute;
      bottom: 16px;
      left: 16px;
      gap: 8px;
    }

    &__button {
      background-color: var(--v-black-base);
      opacity: 0.7;
    }
  }
}
</style>
