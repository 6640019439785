<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { hasPermission } from '@/shared/helper/permissions';
import Vue from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';

function redirectToProjectSubPage(to: Route, next: NavigationGuardNext) {
  // don't redirect if user is already on one of the assessment pages
  if (to.name !== 'assessment') return next();

  const canViewStationAssessment = hasPermission('canViewStationAssessment');
  const canViewRideAssessment = hasPermission('canViewRideAssessment');
  const query = to.query;

  return canViewStationAssessment
    ? next({ name: 'station-assessment-list', query })
    : canViewRideAssessment
    ? next({ name: 'ride-assessment-list', query })
    : next({ name: 'login' });
}

export default Vue.extend({
  beforeRouteEnter: (to, from, next) => {
    redirectToProjectSubPage(to, next);
  },
  beforeRouteUpdate: (to, from, next) => {
    redirectToProjectSubPage(to, next);
  }
});
</script>
