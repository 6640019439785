import { formatApiDateTime, parseApiDateTime } from '@/shared/helper/date';
import { getImages, saveImages } from '@/shared/modules/AssessmentEdit/services/image-service';

import type {
  DevaluationBooleanUnit,
  DevaluationConditionUnit,
  DevaluationRangeUnit,
  ExportDevaluationUnit,
  ExportStationAssessment,
  StationAssessment
} from '@/shared/modules/AssessmentEdit/types/station';

function convertStationAssessmentForExport(
  stationAssessment: StationAssessment,
  exportDateTime: string | null
): ExportStationAssessment {
  return {
    assessmentId: stationAssessment.assessmentId,
    author: stationAssessment.author,
    assessmentDateTime: formatApiDateTime(stationAssessment.assessmentDateTime),
    exportDateTime: formatApiDateTime(exportDateTime),
    stationCode: stationAssessment.stationCode,
    qualityOfStay: {
      ...convertSectionDevaluationUnitsForExport(stationAssessment, 'qualityOfStay'),
      note: stationAssessment.qualityOfStay.note,
      images: stationAssessment.qualityOfStay.images
    },
    passengerInformation: {
      ...convertSectionDevaluationUnitsForExport(stationAssessment, 'passengerInformation'),
      note: stationAssessment.passengerInformation.note,
      images: stationAssessment.passengerInformation.images
    },
    accessibility: {
      ...copyAccessibilityDevaluationUnits(stationAssessment),
      note: stationAssessment.accessibility.note,
      images: stationAssessment.accessibility.images
    }
  };
}

function convertStationAssessmentFromExport(stationAssessment: ExportStationAssessment): StationAssessment {
  return {
    assessmentId: stationAssessment.assessmentId,
    stationCode: stationAssessment.stationCode,
    stationName: stationAssessment.stationName ?? '',
    assessmentDateTime: parseApiDateTime(stationAssessment.assessmentDateTime) ?? new Date().toISOString(),
    author: stationAssessment.author,
    assessmentStatus: 'complete',
    isTransmitting: false,
    sections: {
      qualityOfStayOne: {
        visited: true
      },
      qualityOfStayTwo: {
        visited: true
      },
      accessibility: {
        visited: true
      },
      passengerInformation: {
        visited: true
      }
    },
    exportDateTime: parseApiDateTime(stationAssessment.exportDateTime) ?? new Date().toISOString(),
    qualityOfStay: {
      ...convertSectionDevaluationUnitsFromExport(stationAssessment, 'qualityOfStay'),
      note: stationAssessment.qualityOfStay.note,
      images: stationAssessment.qualityOfStay.images
    },
    passengerInformation: {
      ...convertSectionDevaluationUnitsFromExport(stationAssessment, 'passengerInformation'),
      note: stationAssessment.passengerInformation.note,
      images: stationAssessment.passengerInformation.images
    },
    accessibility: {
      ...copyAccessibilityDevaluationUnits(stationAssessment),
      note: stationAssessment.accessibility.note,
      images: stationAssessment.accessibility.images
    }
  };
}

function convertSectionDevaluationUnitsForExport(
  stationAssessment: StationAssessment | ExportStationAssessment,
  section: 'qualityOfStay' | 'passengerInformation'
) {
  const entries = Object.entries(stationAssessment[section]);
  return Object.fromEntries(
    entries
      .filter(([key]) => !['note', 'images'].includes(key))
      .map(devaluationUnit => {
        const devaluationUnitValue = devaluationUnit[1] as DevaluationRangeUnit | DevaluationConditionUnit;
        return [
          devaluationUnit[0],
          {
            devaluation: {
              type: devaluationUnitValue.type,
              value: devaluationUnitValue.value
            },
            note: devaluationUnitValue.note,
            images: devaluationUnitValue.images
          }
        ];
      })
  );
}

function copyAccessibilityDevaluationUnits(stationAssessment: StationAssessment | ExportStationAssessment) {
  const entries = Object.entries(stationAssessment['accessibility']);
  return Object.fromEntries(
    entries
      .filter(([key]) => !['note', 'images'].includes(key))
      .map(devaluationUnit => {
        const devaluationUnitValue = devaluationUnit[1] as DevaluationBooleanUnit;
        return [
          devaluationUnit[0],
          {
            type: devaluationUnitValue.type,
            value: devaluationUnitValue.value
          }
        ];
      })
  );
}

function convertSectionDevaluationUnitsFromExport(
  stationAssessment: StationAssessment | ExportStationAssessment,
  section: 'qualityOfStay' | 'passengerInformation'
) {
  const entries = Object.entries(stationAssessment[section]);
  return Object.fromEntries(
    entries
      .filter(([key]) => !['note', 'images'].includes(key))
      .map(devaluationUnit => {
        const devaluationUnitValue = devaluationUnit[1] as ExportDevaluationUnit;
        return [
          devaluationUnit[0],
          {
            type: devaluationUnitValue.devaluation.type,
            value: devaluationUnitValue.devaluation.value,
            note: devaluationUnitValue.note,
            images: devaluationUnitValue.images
          }
        ];
      })
  );
}

async function replaceIdsWithImages(stationAssessment: ExportStationAssessment): Promise<void> {
  stationAssessment.qualityOfStay.images = await getImages(stationAssessment.qualityOfStay.images);
  stationAssessment.passengerInformation.images = await getImages(stationAssessment.passengerInformation.images);
  stationAssessment.accessibility.images = await getImages(stationAssessment.accessibility.images);

  const qualityOfStayDevaluationUnits = Object.entries(stationAssessment.qualityOfStay)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as ExportDevaluationUnit[];
  const passengerInformationDevaluationUnits = Object.entries(stationAssessment.passengerInformation)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as ExportDevaluationUnit[];
  for (const unit of [...qualityOfStayDevaluationUnits, ...passengerInformationDevaluationUnits]) {
    unit.images = await getImages(unit.images);
  }
}

async function replaceImagesWithIds(stationAssessment: StationAssessment): Promise<void> {
  stationAssessment.qualityOfStay.images = await saveImagesOrThrow(stationAssessment.qualityOfStay.images);
  stationAssessment.passengerInformation.images = await saveImagesOrThrow(
    stationAssessment.passengerInformation.images
  );
  stationAssessment.accessibility.images = await saveImagesOrThrow(stationAssessment.accessibility.images);

  const qualityOfStayDevaluationUnits = Object.entries(stationAssessment.qualityOfStay)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as (DevaluationConditionUnit | DevaluationRangeUnit)[];
  const passengerInformationDevaluationUnits = Object.entries(stationAssessment.passengerInformation)
    .filter(([key]) => !['note', 'images'].includes(key))
    .map(([, value]) => value) as DevaluationConditionUnit[];
  for (const unit of [...qualityOfStayDevaluationUnits, ...passengerInformationDevaluationUnits]) {
    unit.images = await saveImagesOrThrow(unit.images);
  }
}

async function saveImagesOrThrow(images: string[]) {
  const result = await saveImages(images, 'edit-');
  if (!result.success) {
    throw result.error;
  }
  return result.ids;
}

export {
  convertStationAssessmentForExport,
  convertStationAssessmentFromExport,
  replaceIdsWithImages,
  replaceImagesWithIds
};
