<template>
  <v-tabs
    v-bind="$attrs"
    v-on="$listeners"
    :class="{ 'tabs--vertical': verticalIndicator }"
    slider-size="1"
    @change="update"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-tabs>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    verticalIndicator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    update(value: number) {
      this.$emit('input', value);
    }
  }
});
</script>

<style scoped lang="scss">
::v-deep .v-tabs-bar {
  background-color: transparent !important;
  height: auto;
}

::v-deep .v-tab {
  justify-content: start;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1.4;
  min-width: 70px;
  padding-inline: 0;
  text-transform: none;

  &--active.v-tab {
    color: var(--v-darkgrey-darken1);
  }

  &::before {
    content: none;
  }

  & > .v-ripple__container {
    display: none;
  }
}

::v-deep .v-tabs-slider-wrapper {
  max-width: 25px;
}

::v-deep .v-slide-group__prev {
  // remove reserved space on mobile
  display: none !important;
}

.tabs--vertical {
  ::v-deep .v-tabs-slider-wrapper {
    display: none;
  }

  ::v-deep .v-tab {
    padding-inline-start: 12px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 2px;
      opacity: 1 !important;
      transform: translateY(100%);
      transition: 0.25s ease-in-out;

      // allow overwriting for individual tabs
      background-color: var(--tab-indicator-color, var(--v-primary-base));
    }

    &--active::before {
      transform: translateY(0);
    }
  }
}
</style>
