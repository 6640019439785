import VuexPersistence, { AsyncStorage } from 'vuex-persist';
import { RootState } from '@/pwa/types';
import { STATION_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/stationAssessment';
import { RIDE_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/rideAssessment';
import localForage from 'localforage';

// VuexPersist automatically persists the state on every mutation but not on actions!
const vuexPersist = new VuexPersistence<RootState>({
  storage: localForage.createInstance({ name: 'localForage' }) as AsyncStorage,
  asyncStorage: true,
  reducer: state => {
    return {
      [STATION_ASSESSMENT_NAMESPACE]: {
        stationReportsUpdated: state[STATION_ASSESSMENT_NAMESPACE].stationReportsUpdated,
        stationReportsCountTotal: state[STATION_ASSESSMENT_NAMESPACE].stationReportsCountTotal,
        stationReportsCountUnassessed: state[STATION_ASSESSMENT_NAMESPACE].stationReportsCountUnassessed,
        stationAssessments: state[STATION_ASSESSMENT_NAMESPACE].stationAssessments,
        globalStationAssessments: state[STATION_ASSESSMENT_NAMESPACE].globalStationAssessments
      },
      [RIDE_ASSESSMENT_NAMESPACE]: {
        draftRideAssessments: state[RIDE_ASSESSMENT_NAMESPACE].draftRideAssessments
      }
    };
  }
});

export { vuexPersist };
