import { VueConstructor } from 'vue';
import VueAuth from '@websanova/vue-auth';
import { VueRouter } from 'vue-router/types/router';
import { CurrentUser } from '@/shared/types';
import { getUserRoleAndProjects } from '@/shared/helper/vue-auth';
import { getPermissionsForUser } from '@/shared/helper/permissions';

export default (Vue: VueConstructor, router: VueRouter) => {
  // @ts-ignore
  Vue.router = router;

  const bearerAuth = {
    request: function (req: object, token: string) {
      // @ts-ignore
      this.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      });
    },
    response: function (res: object) {
      // @ts-ignore
      return (res.data || {}).token;
    }
  };

  // @ts-ignore
  Vue.use(VueAuth, {
    auth: bearerAuth,
    http: require('@websanova/vue-auth/dist/drivers/http/axios.1.x.common.js'),
    router: require('@websanova/vue-auth/dist/drivers/router/vue-router.2.x.common.js'),
    rolesKey: 'permissions',
    loginData: {
      url: 'login',
      method: 'POST',
      redirect: '/',
      fetchUser: true
    },
    fetchData: {
      url: 'security/user/current',
      method: 'GET',
      enabled: true
    },
    refreshData: { enabled: false },
    parseUserData: (data: CurrentUser) => {
      const result = getUserRoleAndProjects(data.roles);
      if (result instanceof Error) {
        throw result;
      }
      const permissions = getPermissionsForUser(result.userRole, result.projects);

      return {
        ...data,
        userRole: result.userRole,
        projects: result.projects,
        permissions
      };
    }
  });
};
