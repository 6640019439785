import {
  DevaluationUnit,
  DevaluationUnitSection,
  RideAssessment,
  Validatable
} from '@/shared/modules/AssessmentEdit/types/ride';
import { AssessmentValidationUnitStatus } from '@/shared/modules/AssessmentEdit/types';
import { NoImagesAllowedDevaluationUnits } from '@/shared/modules/AssessmentEdit/services/ride-assessment-statics';
import { includes } from '@/shared/helper/typescript';

function validateRideAssessment(rideAssessment: RideAssessment): void {
  rideAssessment.forms.stationInformation.status = validateDevaluationUnitSection(
    rideAssessment.stationInformation,
    rideAssessment.forms.stationInformation
  );
  rideAssessment.forms.wagonState.status = validateDevaluationUnitSection(
    rideAssessment.wagonState,
    rideAssessment.forms.wagonState
  );
  rideAssessment.forms.conductors.status = validateDevaluationUnitSection(
    rideAssessment.conductors,
    rideAssessment.forms.conductors
  );
  rideAssessment.forms.securityStaff.status = validateDevaluationUnitSection(
    rideAssessment.securityStaff,
    rideAssessment.forms.securityStaff
  );
  rideAssessment.forms.onboardService.status = validateDevaluationUnitSection(
    rideAssessment.onboardService,
    rideAssessment.forms.onboardService
  );
  rideAssessment.forms.utilization.status = validateDevaluationUnitSection(
    rideAssessment.utilization,
    rideAssessment.forms.utilization
  );
  rideAssessment.forms.inTrainInformation.status = validateDevaluationUnitSection(
    rideAssessment.inTrainInformation,
    rideAssessment.forms.inTrainInformation
  );
  rideAssessment.forms.disruption.status = validateDevaluationUnitSection(
    rideAssessment.disruption,
    rideAssessment.forms.disruption
  );
  rideAssessment.forms.baseData.status = validateBaseData(rideAssessment, rideAssessment.forms.baseData);
  rideAssessment.forms.wagonTypes.status = validateWagonType(rideAssessment, rideAssessment.forms.wagonTypes);
  rideAssessment.wagonTypes.status = rideAssessment.forms.wagonTypes.status;
  rideAssessment.forms.assessmentComment.status = validateAssessmentComment(
    rideAssessment,
    rideAssessment.forms.assessmentComment
  );
  rideAssessment.assessmentComment.status = rideAssessment.forms.assessmentComment.status;

  rideAssessment.assessmentValidationStatus = Object.values(rideAssessment.forms).every(
    form => form.status === 'complete'
  )
    ? 'complete'
    : 'incomplete';
}

function validateDevaluationUnitSection(
  section: DevaluationUnitSection,
  form: { visited: boolean; deactivated: boolean }
): AssessmentValidationUnitStatus {
  if (form.deactivated === true) {
    return 'complete';
  }

  section.forEach(unit => {
    const { status, errorMessage } = validateDevaluationUnit(unit);
    unit.status = status;
    unit.errorMessage = errorMessage;
  });
  const containsInitialDevaluationUnit = section.some(unit => unit.status === 'initial');
  const containsIncompleteDevaluationUnit = section.some(unit => unit.status === 'incomplete');

  if (form.visited === false) {
    removeInitialErrorMessages(section);
  }

  return getSectionAggregateStatus(containsIncompleteDevaluationUnit, containsInitialDevaluationUnit, form.visited);
}

function validateBaseData(rideAssessment: RideAssessment, form: { visited: boolean }): AssessmentValidationUnitStatus {
  const stringBaseDataFields = [
    rideAssessment.trainNumber,
    rideAssessment.departureStationId,
    rideAssessment.arrivalStationId,
    rideAssessment.arrivalTimeNominal,
    rideAssessment.arrivalTimeActual
  ];

  stringBaseDataFields.forEach(field => {
    const fieldValidation = validateBaseDataField(field.value, field.deactivated);
    field.status = fieldValidation.status;
    field.errorMessage = fieldValidation.errorMessage;
  });

  const { status, errorMessage } = validateCancelled(
    rideAssessment.cancelled.value,
    rideAssessment.cancellationComment.note
  );
  rideAssessment.cancelled.status = status;
  rideAssessment.cancelled.errorMessage = errorMessage;

  const baseDataFields = [...stringBaseDataFields, rideAssessment.cancelled];
  const containsInitialBaseDataField = baseDataFields.some(field => field.status === 'initial');
  const containsIncompleteBaseDataField = baseDataFields.some(field => field.status === 'incomplete');

  if (form.visited === false) {
    removeInitialBaseDataErrorMessages(baseDataFields);
  }

  return getSectionAggregateStatus(containsIncompleteBaseDataField, containsInitialBaseDataField, form.visited);
}

function validateWagonType(
  rideAssessment: RideAssessment,
  form: { visited: boolean; deactivated: boolean }
): AssessmentValidationUnitStatus {
  if (form.deactivated === true) {
    return 'complete';
  }

  const completeVehicleNumbers = rideAssessment.wagonTypes.values.some(wagonType => {
    return wagonType.vehicleNumbers.some(vehicleNumber => vehicleNumber !== '');
  });

  if (completeVehicleNumbers === true) {
    return 'complete';
  }

  if (form.visited === false) {
    return 'initial';
  }

  return 'incomplete';
}

function validateAssessmentComment(
  rideAssessment: RideAssessment,
  form: { visited: boolean; deactivated: boolean }
): AssessmentValidationUnitStatus {
  if (form.deactivated === true) {
    return 'complete';
  }

  if (rideAssessment.assessmentComment.noComment === true) {
    return 'complete';
  }

  if (rideAssessment.assessmentComment.note !== '') {
    return 'complete';
  }

  if (rideAssessment.assessmentComment.imageIds.length > 0) {
    return 'complete';
  }

  if (form.visited === false) {
    return 'initial';
  }

  return 'incomplete';
}

function validateDevaluationUnit(unit: DevaluationUnit): Validatable {
  if (unit.deactivated === true) {
    return { status: 'complete' };
  }

  if (unit.value === undefined && unit.note === '' && unit.imageIds.length === 0) {
    return { status: 'initial', errorMessage: 'missingValue' };
  }

  if (unit.value === undefined) {
    return { status: 'incomplete', errorMessage: 'missingValue' };
  }

  if (
    unit.value === '0' &&
    includes(NoImagesAllowedDevaluationUnits, unit.name) === false &&
    unit.imageIds.length === 0 &&
    unit.type !== 'number_na_6'
  ) {
    return { status: 'incomplete', errorMessage: 'missingImage' };
  }

  return { status: 'complete' };
}

function validateBaseDataField(value: string, deactivated: boolean): Validatable {
  if (deactivated === true) {
    return { status: 'complete' };
  }

  if (value === '') {
    return { status: 'initial', errorMessage: 'missingValue' };
  }

  return { status: 'complete' };
}

function validateCancelled(value: boolean, note: string): Validatable {
  if (value === true && note === '') {
    return { status: 'incomplete', errorMessage: 'missingNote' };
  } else {
    return { status: 'complete' };
  }
}

function getSectionAggregateStatus(
  containsIncompleteDevaluationUnit: boolean,
  containsInitialDevaluationUnit: boolean,
  sectionVisited: boolean
): AssessmentValidationUnitStatus {
  if (containsIncompleteDevaluationUnit || (containsInitialDevaluationUnit && sectionVisited)) {
    return 'incomplete';
  } else if (containsInitialDevaluationUnit) {
    return 'initial';
  } else {
    return 'complete';
  }
}

function removeInitialErrorMessages(section: DevaluationUnitSection) {
  section.forEach(unit => {
    if (unit.status === 'initial') {
      unit.errorMessage = undefined;
    }
  });
}

function removeInitialBaseDataErrorMessages(baseDataFields: Validatable[]) {
  baseDataFields.forEach(field => {
    if (field.status === 'initial') {
      field.errorMessage = undefined;
    }
  });
}

export {
  validateRideAssessment,
  validateDevaluationUnitSection,
  validateBaseData,
  validateWagonType,
  validateAssessmentComment,
  validateDevaluationUnit
};
