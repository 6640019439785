<template>
  <v-container class="pt-0 px-6">
    <section-heading subheading="rideAssessmentEdit.sections.disruption" />
    <div class="form-grid">
      <devaluation-input
        title="rideAssessmentEdit.devaluationUnits.disrupted"
        :show-comment="false"
        :error-message="devaluationInputs[0].errorMessage"
      >
        <boolean-option
          :value="devaluationInputs[0].value"
          :disabled="devaluationInputs[0].disabled"
          @input="updateValue('disrupted', $event)"
        />
      </devaluation-input>
      <devaluation-input
        title="rideAssessmentEdit.devaluationUnits.disruptionAnnouncement"
        :show-info="true"
        :has-comment="devaluationInputs[1].hasComment"
        :error-message="devaluationInputs[1].errorMessage"
        @info-icon-clicked="openInfoModal('disruptionAnnouncement')"
        @open-modal="openCameraAndNotesModal('disruptionAnnouncement')"
      >
        <number-range-option
          :type="devaluationInputs[1].type"
          :value="devaluationInputs[1].value"
          :disabled="devaluationInputs[1].disabled"
          @input="updateValue('disruptionAnnouncement', $event)"
        />
      </devaluation-input>
      <devaluation-input
        title="rideAssessmentEdit.devaluationUnits.disruptionStartStationId"
        :show-comment="false"
        :error-message="devaluationInputs[2].errorMessage"
      >
        <dialog-select
          :value="devaluationInputs[2].value"
          :items="stationsOnLine"
          :disabled="devaluationInputs[2].disabled"
          class="pt-0"
          item-text="name"
          item-value="id"
          data-test="disruption-start-station-select"
          height="34"
          hide-details
          @input="updateValue('disruptionStartStationId', !$event ? undefined : $event)"
        />
      </devaluation-input>
      <devaluation-input
        title="rideAssessmentEdit.devaluationUnits.disruptionEndStationId"
        :show-comment="false"
        :error-message="devaluationInputs[3].errorMessage"
      >
        <dialog-select
          :value="devaluationInputs[3].value"
          :items="stationsOnLine"
          :disabled="devaluationInputs[3].disabled"
          class="pt-0"
          item-text="name"
          item-value="id"
          height="34"
          data-test="disruption-end-station-select"
          hide-details
          @input="updateValue('disruptionEndStationId', !$event ? undefined : $event)"
        />
      </devaluation-input>
    </div>
    <assessment-overview-button :complete="sectionIsComplete" to-route-name="ride-assessment" />
    <devaluation-information-modal
      v-model="devaluationInformationModalVisible"
      :title="devaluationInformationModalTitle"
      :text="devaluationInformationModalText"
    />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalDevaluationUnitKey"
      :devaluation-unit-name="cameraAndNotesModalDevaluationUnitKey"
      :title="cameraAndNotesModalTitle"
      :note="cameraAndNotesModalDevaluationUnit?.note ?? ''"
      :images="cameraAndNotesModalImages"
      :download-title="cameraAndNotesModalDownloadTitle"
      :read-only="cameraAndNotesModalReadOnly"
      :no-images-allowed="cameraAndNotesModalNoImagesAllowed"
      @add-note="updateValue(cameraAndNotesModalDevaluationUnitKey, $event, 'note')"
      @add-images="addImages"
      @delete-image="deleteImage"
      @closed="cameraAndNotesModalDevaluationUnitKey = ''"
    >
      <predefined-notes-list
        :devaluation-unit-key="cameraAndNotesModalDevaluationUnitKey"
        :selected-notes="cameraAndNotesModalDevaluationUnit?.predefinedNotes ?? []"
        :disabled="cameraAndNotesModalReadOnly"
        @update:selected-notes="updateValue(cameraAndNotesModalDevaluationUnitKey, $event, 'predefinedNotes')"
      />
    </camera-and-notes-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import BooleanOption from '@/shared/modules/AssessmentEdit/components/BooleanOption.vue';
import NumberRangeOption from '@/shared/modules/AssessmentEdit/components/NumberRangeOption.vue';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import PredefinedNotesList from '@/shared/modules/AssessmentEdit/components/PredefinedNotesList.vue';
import DevaluationInformationModal from '@/shared/modules/AssessmentEdit/components/DevaluationInformationModal.vue';
import { RideFormMixin, RideFormMixinType } from '@/shared/modules/AssessmentEdit/mixins/ride-form-mixin';
import { Station } from '@/shared/plugins/database/profitester';
import { dbHelper } from '@/shared/helper/database';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

export default (Vue as VueConstructor<Vue & RideFormMixinType>).extend({
  mixins: [RideFormMixin('disruption')],
  components: {
    DialogSelect,
    SectionHeading,
    DevaluationInput,
    AssessmentOverviewButton,
    CameraAndNotesModal,
    PredefinedNotesList,
    DevaluationInformationModal,
    BooleanOption,
    NumberRangeOption
  },
  data() {
    return {
      stationsOnLine: [] as Station[]
    };
  },
  async mounted() {
    this.stationsOnLine = await dbHelper.getSortedStationsOnLine(this.rideAssessment.lineId);
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-grid {
  @include dynamic-grid(40px, 300px, 3);

  @include md-and-up {
    row-gap: 40px;
    justify-content: start;
  }
}
</style>
