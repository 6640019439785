import {
  calculateFieldsToDeactivate,
  calculateFormsToDeactivate,
  createRideAssessment,
  getImageKeysOfDeactivatedFields,
  resetDeactivatedFields,
  rideAssessmentHasChanged
} from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { getProperty, setProperty } from 'dot-prop';
import { deleteImages, saveImages } from '@/shared/modules/AssessmentEdit/services/image-service';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';
import { DeactivatableFields } from '@/shared/modules/AssessmentEdit/services/ride-assessment-statics';
import { validateRideAssessment } from '@/shared/modules/AssessmentEdit/services/ride-assessment-validation';

import type {
  RideAssessment,
  ImagePrefix,
  RideAssessmentEditState,
  AssessmentWagonType
} from '@/shared/modules/AssessmentEdit/types/ride';
import type { VueConstructor } from 'vue';
import type { ActionTree, Commit, Module, MutationTree } from 'vuex';
import type { SnackbarConfig } from '@/shared/modules/Layout/types';
import type { AssessmentEditMode } from '@/shared/modules/AssessmentEdit/types';

export const RIDE_ASSESSMENT_EDIT_NAMESPACE = 'RIDE_ASSESSMENT_EDIT_NAMESPACE';

function showSnackbar(commit: Commit, snackbar: SnackbarConfig) {
  commit(
    `${LAYOUT_NAMESPACE}/showSnackbar`,
    {
      ...snackbar,
      title: `rideAssessmentEdit.snackbar.${snackbar.title}`
    },
    { root: true }
  );
}

const state: RideAssessmentEditState = {
  initialRideAssessment: createRideAssessment('', '', '', '3er'),
  rideAssessment: createRideAssessment('', '', '', '3er'),
  rideAssessmentHasChanged: false,
  mode: 'view',
  readOnly: false,
  imagePrefix: ''
};

const mutations: MutationTree<RideAssessmentEditState> = {
  setRideAssessment(state, payload) {
    state.rideAssessment = payload;
  },
  setInitialRideAssessment(state, payload) {
    state.initialRideAssessment = payload;
  },
  addWagonType(state, payload: AssessmentWagonType) {
    state.rideAssessment.wagonTypes.values.push(payload);
  },
  deleteWagonType(state, index: number) {
    state.rideAssessment.wagonTypes.values.splice(index, 1);
  },
  updateWagonType(state, payload: { index: number; value: Partial<AssessmentWagonType> }) {
    state.rideAssessment.wagonTypes.values.splice(payload.index, 1, {
      ...state.rideAssessment.wagonTypes.values[payload.index],
      ...payload.value
    });
  },
  updateRideAssessment(state, payload: { path: string; value: unknown }) {
    setProperty(state.rideAssessment, payload.path, payload.value);
  },
  setReadOnly(state, payload: boolean) {
    state.readOnly = payload;
  },
  setImagePrefix(state, payload: ImagePrefix) {
    state.imagePrefix = payload;
  },
  setMode(state, payload: AssessmentEditMode) {
    state.mode = payload;
  },
  activateAllFields(state) {
    DeactivatableFields.forEach(path => {
      setProperty(state.rideAssessment, `${path}.deactivated`, false);
    });
  },
  deactivateFields(state) {
    calculateFieldsToDeactivate(state.rideAssessment).forEach(path => {
      setProperty(state.rideAssessment, `${path}.deactivated`, true);
    });
  },
  resetDeactivatedFields(state) {
    resetDeactivatedFields(state.rideAssessment);
  },
  activateAllForms(state) {
    Object.values(state.rideAssessment.forms).forEach(form => {
      form.deactivated = false;
    });
  },
  deactivateForms(state) {
    calculateFormsToDeactivate(state.rideAssessment).forEach(formName => {
      setProperty(state.rideAssessment, `forms.${formName}.deactivated`, true);
    });
  },
  setRideAssessmentHasChanged(state, payload: boolean) {
    state.rideAssessmentHasChanged = payload;
  },
  checkAssessmentHasChanged(state) {
    if (state.mode === 'edit' && !state.readOnly) {
      state.rideAssessmentHasChanged = rideAssessmentHasChanged(state.initialRideAssessment, state.rideAssessment);
    }
  }
};

const configureActions = (Vue: VueConstructor) => {
  const actions: ActionTree<RideAssessmentEditState, unknown> = {
    async addRideAssessmentImages({ state, dispatch }, payload: { path: string; images: string[] }): Promise<void> {
      if (payload.images.length === 0) {
        return;
      }

      const existingImages = getProperty<RideAssessment, string, string[] | undefined>(
        state.rideAssessment,
        payload.path
      );
      if (!Array.isArray(existingImages)) {
        return;
      }

      const saveResult = await saveImages(payload.images, state.imagePrefix);
      if (!saveResult.success) {
        return;
      }

      const updatedImages = [...existingImages, ...saveResult.ids];
      dispatch('updateRideAssessmentValue', { path: payload.path, value: updatedImages });
    },
    async deleteRideAssessmentImage({ state, dispatch }, payload: { path: string; imageIndex: number }): Promise<void> {
      const existingImages = getProperty<RideAssessment, string, string[] | undefined>(
        state.rideAssessment,
        payload.path
      );
      if (!Array.isArray(existingImages)) {
        return;
      }

      await deleteImages([existingImages[payload.imageIndex]]);

      const newImages = existingImages.filter((_, index) => index !== payload.imageIndex);
      dispatch('updateRideAssessmentValue', { path: payload.path, value: newImages });
    },
    async addWagonType({ commit, dispatch }, payload: AssessmentWagonType) {
      commit('addWagonType', payload);
      await dispatch('triggerRideAssessmentUpdateActions');
    },
    async updateWagonType({ commit, dispatch }, payload: { index: number; value: Partial<AssessmentWagonType> }) {
      commit('updateWagonType', payload);
      await dispatch('triggerRideAssessmentUpdateActions');
    },
    async deleteWagonType({ state, commit, dispatch }, index: number) {
      await deleteImages(state.rideAssessment.wagonTypes.values[index].imageIds);
      commit('deleteWagonType', index);
      await dispatch('triggerRideAssessmentUpdateActions');
    },
    async updateRideAssessmentValue({ commit, dispatch }, payload: { path: string; value: unknown }) {
      commit('updateRideAssessment', payload);
      await dispatch('triggerRideAssessmentUpdateActions');
    },
    async triggerRideAssessmentUpdateActions({ commit, dispatch }) {
      await dispatch('updateDependentFields');
      dispatch('validateRideAssessment');
      commit('checkAssessmentHasChanged');
    },
    async updateDependentFields({ state, commit }) {
      commit('activateAllFields');
      commit('deactivateFields');

      await deleteImages(getImageKeysOfDeactivatedFields(state.rideAssessment));

      commit('resetDeactivatedFields');
      commit('activateAllForms');
      commit('deactivateForms');
    },
    validateRideAssessment({ commit, state }) {
      validateRideAssessment(state.rideAssessment);
    }
  };
  return actions;
};

export const configureRideAssessmentEditStore = (Vue: VueConstructor) => {
  const module: Module<RideAssessmentEditState, unknown> = {
    namespaced: true,
    state,
    mutations,
    actions: configureActions(Vue)
  };
  return module;
};
