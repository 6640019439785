export default {
  assessmentEdit: {
    booleanOptions: {
      yes: 'Ja',
      no: 'Nein'
    },
    devaluationErrors: {
      missingValue: 'Bitte wählen Sie einen Wert',
      missingNote: 'Kommentar fehlt',
      missingImage: 'Bild fehlt'
    },
    cameraAndNotesModal: {
      images: 'Fotos',
      pictures: 'Bilder',
      max3Images: 'Du kannst maximal 3 Bilder hinzufügen.',
      noImagesAllowed: 'Zu diesem Merkmal werden keine Bilder erfasst.',
      takePhotos: 'Fotografieren',
      uploadImages: 'Bilder hochladen',
      noteLabel: 'Mein Kommentar',
      savingImage: 'Bild wird gespeichert',
      errorDialog: {
        title: 'Achtung!',
        text: 'Du kannst <strong>maximal drei Bilder</strong> hinzufügen. Bitte lösche Bilder, wenn du diese ersetzen möchtest.',
        button: 'OK'
      },
      deleteDialog: {
        title: 'Foto wirklich löschen?',
        text: 'Das ausgewählte Foto wird unwiderruflich gelöscht.',
        button: 'Löschen'
      }
    },
    dialogSelect: {
      searchLabel: 'Suche',
      noSearchResult: 'Keine Ergebnisse',
      noData: 'Keine Optionen'
    }
  }
};
