<template>
  <dialog-select
    :items="numberOptions"
    :disabled="disabled"
    :value="value"
    item-text="text"
    item-value="value"
    class="pt-0"
    height="34"
    data-test="count-select"
    hide-details
    no-search
    @input="$emit('input', !$event ? undefined : $event)"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

export default Vue.extend({
  components: {
    DialogSelect
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      numberOptions: [
        {
          text: 'k.A.',
          value: 'n/a'
        },
        {
          text: '0',
          value: '0'
        },
        {
          text: '1',
          value: '1'
        },
        {
          text: '2',
          value: '2'
        },
        {
          text: '3',
          value: '3'
        },
        {
          text: '4',
          value: '4'
        },
        {
          text: '5',
          value: '5'
        },
        {
          text: '6',
          value: '6'
        }
      ]
    };
  }
});
</script>
