const predefinedNotes: { key: string; notes: string[] }[] = [
  {
    key: 'cleanlinessFloor',
    notes: [
      'übermäßiger, alter Schmutz',
      'Essensreste, Getränkeflaschen',
      'jahreszeitliche/wetterbedingte Feuchtigkeit',
      'Müll'
    ]
  },
  {
    key: 'conductorBehavior',
    notes: [
      'Beleidigung von Fahrgästen',
      'nicht/schlecht ansprechbar für den Fahrgast',
      'keine Deeskalation bei Konflikten'
    ]
  },
  {
    key: 'conductorAppearance',
    notes: [
      'Dienstkleidung ungepflegt',
      'keine/unvollständige Dienstkleidung',
      'Ausrüstung unvollständig',
      'Tasche inkl. mobiles Terminal'
    ]
  },
  {
    key: 'conductorInactivity',
    notes: ['FSV (Führerstand vorne)', 'FSH (Führerstand hinten)', 'keine Präsenzdemonstration']
  },
  {
    key: 'exteriorGraffiti',
    notes: ['Tags', 'großflächiges Graffiti', 'Aufkleber', 'Graffitireste']
  },
  {
    key: 'cleanlinessExteriorShell',
    notes: ['übermäßiger Bremsstaub', 'verschmutzt']
  },
  {
    key: 'functionExteriorShell',
    notes: ['oberflächliche Beschädigungen', 'Folierungsschäden', 'Lackschäden/Rost']
  },
  {
    key: 'cleanlinessExteriorDoors',
    notes: ['heruntergelaufene Flüssigkeiten', 'starke, nicht flüssige Verschmutzung', 'schmutzige Putzwasserreste']
  },
  {
    key: 'functionExteriorDoors',
    notes: ['beschädigt durch Vandalismus', 'Tür defekt']
  },
  {
    key: 'cleanlinessToilets',
    notes: [
      'Geruch',
      'Mülleimer überfüllt',
      'überflutet/Wasser läuft ins Abteil',
      'kein Toilettenpapier',
      'fehlende Papiertücher',
      'keine Seife',
      'Spiegel beschmiert',
      'kein Handwaschwasser',
      'Boden nass'
    ]
  },
  {
    key: 'functionToilets',
    notes: [
      'defekte Spülung',
      'Scratching Spiegel',
      'Vandalismusschäden',
      'verschlossene Tür (nicht besetzt)',
      'Schutzklebefolie am Spiegel löst sich ab',
      'Türautomatik a. B.'
    ]
  },
  {
    key: 'functionHeating',
    notes: ['in einer Zughälfte ausgefallen', 'komplett ausgefallen', 'zu warm', 'zu kalt']
  },
  {
    key: 'interiorGraffiti',
    notes: ['Tags', 'großflächiges Graffiti', 'Aufkleber', 'grobe Tagreste']
  },
  {
    key: 'functionLights',
    notes: ['Licht flackert', 'Leuchtkörper fehlen', 'einzelne Beleuchtungen a. B.']
  },
  {
    key: 'cleanlinessInteriorScaffold',
    notes: [
      'klebende, abfärbende Verschmutzungen',
      'Müll im Gepäckfach',
      'runtergelaufene Flüssigkeiten',
      'Fettflecken'
    ]
  },
  {
    key: 'functionInteriorScaffold',
    notes: ['Scratching', 'Lackschäden', 'kaputtes Element']
  },
  {
    key: 'cleanlinessWasteBins',
    notes: ['überquellender Müllbehälter', 'klebende, abfärbende Verschmutzung', 'Gestank']
  },
  {
    key: 'functionWasteBins',
    notes: ['Deckel fehlt', 'keine Behälter in vorhandenen Aufhängungen']
  },
  {
    key: 'cleanlinessSeats',
    notes: [
      'Kaugummi',
      'Sitzkissen, Armlehnen, Kopfstützen dreckig, speckig',
      'Essensreste',
      'Mülleimer überfüllt',
      'loser Müll',
      'Flecken'
    ]
  },
  {
    key: 'functionSeats',
    notes: [
      'Sitzkissen, Armlehnen, Kopfstützen abgenutzt',
      'Brandflecken',
      'durch Vandalismus beschädigt',
      'loser Sitz, lose Sitzfläche',
      'fehlende Klappsitze'
    ]
  },
  {
    key: 'functionFloor',
    notes: ['Blasenbildung', 'Verformung des Materials (Löcher, Stolperfallen)']
  },
  {
    key: 'cleanlinessPassageDoors',
    notes: ['übermäßiger, alter Schmutz', 'Fettflecken']
  },
  {
    key: 'functionPassageDoors',
    notes: ['Scratching', 'schwergängig', 'verschlossen, nicht schließbar', 'Tür fehlt']
  },
  {
    key: 'cleanlinessWindows',
    notes: ['übermäßiger Schmutz außen', 'übermäßiger Schmutz innen', 'Fettflecken']
  },
  {
    key: 'functionWindows',
    notes: ['Scratching', 'Folierungsschäden', 'gesprungen', 'Isolierung der Fenster', 'Scheibe(n) blind']
  },
  {
    key: 'stationDestinationDisplay',
    notes: ['vollständig außer Betrieb', 'Darstellungsfehler', 'falsche/fehlende Information zu', 'falsche Zeit']
  },
  {
    key: 'stationDisruptionDisplay',
    notes: [
      'keine Störanzeige',
      'falsche/fehlende Information zu',
      'Verspätungsminuten',
      'Gleiswechsel',
      'Begründung für Störungen'
    ]
  },
  {
    key: 'stationDisruptionAnnouncement',
    notes: [
      'keine Ansage',
      'undeutliche Ansage',
      'falsche/fehlende Information zu',
      'Verspätungsminuten',
      'Gleisbelegung',
      'Begründung für Störung',
      'zu späte Ansage',
      'Gleiswechsel'
    ]
  },
  {
    key: 'stationTrainAnnouncement',
    notes: ['keine Ansage', 'undeutlich', 'unvollständig', 'falsche Ansage', 'zu späte Ansage', 'Bereitstellung']
  },
  {
    key: 'routeNetworkPlan',
    notes: [
      'kein Plan vorhanden',
      'kein aktueller Plan vorhanden',
      'Lesbarkeit eingeschränkt, weil',
      'verschmutzt',
      'zerstört'
    ]
  },
  {
    key: 'passengerInformationSystem',
    notes: [
      'außer Betrieb',
      'Darstellungsfehler/Schlecht lesbar',
      'falsche/fehlende Information zu',
      'Name der nächsten Haltestelle',
      'Anzeige teilweise a. B.'
    ]
  },
  {
    key: 'speakerAnnouncement',
    notes: [
      'außer Betrieb (=keine Durchsagen)',
      'zu leise bzw. unverständlich',
      'Name der nächsten Haltestelle fehlt',
      'Ausstiegsseite fehlt'
    ]
  },
  {
    key: 'trainLabel',
    notes: [
      'außer Betrieb',
      'Darstellungsfehler',
      'falsche/fehlende Informationen zu',
      'Liniennummer',
      'Zielhaltestelle'
    ]
  }
];

export { predefinedNotes };
