import { VueConstructor } from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import packageInfo from '@/../package.json';

export default (Vue: VueConstructor, router: Router) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      tracesSampleRate: 0.1,
      release: 'profitester@' + packageInfo.version,
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
    });
  }
};
