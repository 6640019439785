<template>
  <v-list class="assessment-navigation" :class="{ 'assessment-navigation--base': selectDefaultNavItem }" flat>
    <v-list-item-group>
      <v-list-item
        v-for="(section, index) in assessmentSections"
        class="navigation-section pr-0"
        :class="section.class"
        :key="index"
        :to="{ name: section.routeName }"
        :data-test="section.dataTest"
        :disabled="section.deactivated"
      >
        <div class="navigation-section__circle mr-4 flex-shrink-0 rounded-circle font-weight-bold">{{ index + 1 }}</div>
        <div class="navigation-section__name">{{ $t(section.label) }}</div>
        <v-icon class="navigation-section__arrow ml-auto" small>fas fa-arrow-right</v-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import type { NavigationSection } from '@/shared/modules/AssessmentEdit/types';

export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<NavigationSection[]>,
      default: () => []
    },
    selectDefaultNavItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    assessmentSections(): (NavigationSection & { class: string })[] {
      return this.items.map(item => ({
        ...item,
        class: `navigation-section--${item.status}`
      }));
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

.assessment-navigation {
  background-color: transparent;
}

.navigation-section {
  --text-color: var(--v-primary-base);
  --number-color: var(--v-primary-base);
  --fill: var(--v-white-base);

  border-bottom: 1px solid var(--v-white-darken1);
  color: var(--text-color);

  &--complete {
    --number-color: var(--v-white-base);
    --fill: var(--v-primary-base);
  }

  &--incomplete {
    --text-color: var(--v-error-base);
    --number-color: var(--v-white-base);
    --fill: var(--v-error-base);
  }

  &__name {
    color: var(--text-color);
    font-size: 0.875rem;
  }

  &__circle {
    background-color: var(--fill);
    border: 1px solid;
    border-color: var(--text-color);
    color: var(--number-color);
    font-size: 0.75rem;
    height: 32px;
    width: 32px;
    display: grid;
    place-items: center;
  }

  &__arrow {
    color: var(--text-color);
    display: none;
  }

  &.v-list-item--active,
  .assessment-navigation--base &:first-of-type {
    .navigation-section__arrow {
      @include md-and-up {
        display: inline-flex;
      }
    }
  }

  &.v-list-item--disabled {
    --text-color: var(--v-lightgrey-base);
    --number-color: var(--v-lightgrey-base);
  }
}
</style>
