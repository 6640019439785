<template>
  <v-snackbar
    v-model="visible"
    :transition="$vuetify.breakpoint.xs ? 'slide-y-reverse-transition' : 'slide-y-transition'"
    :min-width="$vuetify.breakpoint.xs ? '100%' : undefined"
    app
    light
    v-bind="$attrs"
    class="snackbar"
  >
    <div class="border" :class="color"></div>
    <div class="snackbar__content">
      <v-icon class="snackbar__icon" v-if="icon" :color="color">{{ icon }}</v-icon>
      <h2 class="snackbar__heading font-weight-bold"><slot></slot></h2>
      <p class="snackbar__description mb-0"><slot name="description"></slot></p>
    </div>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    visible: {
      get(): boolean {
        return this.value;
      },
      set(visible: boolean): void {
        this.$emit('input', visible);
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

.border {
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
}

.snackbar {
  &__content {
    align-items: center;
    column-gap: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
  }

  &__icon {
    grid-row: 1 / -1;
  }

  &__heading {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &__description {
    font-size: 0.625rem;
    line-height: 1.5;
  }
}

::v-deep .v-snack__wrapper {
  border-radius: 0 !important;
  margin-block: 0;
  padding-bottom: 25px;

  @include sm-and-up {
    border-radius: 4px !important;
    margin-block: 12px;
    overflow: hidden;
    padding-block: 0;
  }
}

::v-deep .v-snack__content {
  padding-block: 8px;
}
</style>
