<template>
  <v-item-group
    :value="selectedNotes"
    :class="{ 'mb-4': selectableNotes.length > 0 }"
    data-test="predefined-notes-list"
    multiple
    @change="emitSortedNotes"
  >
    <v-item
      v-for="note in selectableNotes"
      :key="note"
      #default="{ active, toggle }"
      :value="note"
      :disabled="disabled"
    >
      <v-checkbox
        :input-value="active"
        :label="note"
        :disabled="disabled"
        hide-details
        dense
        @change="toggle(!active)"
      ></v-checkbox>
    </v-item>
  </v-item-group>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { predefinedNotes } from '@/shared/modules/AssessmentEdit/services/predefined-notes';

export default Vue.extend({
  props: {
    devaluationUnitKey: {
      type: String,
      required: true
    },
    selectedNotes: {
      type: Array as PropType<string[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectableNotes(): string[] {
      const noteItem = predefinedNotes.find(item => item.key === this.devaluationUnitKey);
      return noteItem ? noteItem.notes : [];
    }
  },
  methods: {
    // the notes have to be in order because multiple
    // notes can be selected to form phrases
    emitSortedNotes(newSelectedNotes: string[]) {
      const notesWithIndices = newSelectedNotes.map(note => ({
        note,
        index: this.selectableNotes.indexOf(note)
      }));
      const newNotesSorted = notesWithIndices.sort((a, b) => a.index - b.index).map(item => item.note);
      this.$emit('update:selected-notes', newNotesSorted);
    }
  }
});
</script>

<style scoped></style>
