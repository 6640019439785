/**
 * This mixin can be used to persist data when the user navigates away from a page.
 * It works for navigations within the app and when navigating away from the app but
 * for some reason it doesn't work when the user closes the browser tab.
 */

import Vue from 'vue';

const PageLeaveMixin = function (callback: string) {
  return Vue.extend({
    created() {
      // @ts-ignore
      window.addEventListener('beforeunload', this[callback]);
    },
    beforeDestroy() {
      // @ts-ignore
      window.removeEventListener('beforeunload', this[callback]);
    },
    beforeRouteLeave(to, from, next) {
      // @ts-ignore
      this[callback]();
      next();
    }
  });
};

export { PageLeaveMixin };
