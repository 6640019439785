import { authCheck } from '@/shared/helper/vue-auth';
import { Route, RouteConfig } from 'vue-router';

/**
 * Returns the sub menu items of the top most named parent route.
 */
export const getSubMenu = (currentRoute: Route, allRoutes: RouteConfig[]): RouteConfig[] | null => {
  const subMenuParentRoute = currentRoute.matched.find(route => route.meta.showSubMenu);

  if (subMenuParentRoute?.name === undefined) {
    return null;
  }

  const subMenuParentRouteConfig = findRouteConfig(allRoutes, subMenuParentRoute.name);

  if (subMenuParentRouteConfig?.children === undefined) {
    return null;
  }

  const subMenuItems = subMenuParentRouteConfig.children
    .filter(route => route.meta?.title !== undefined)
    .filter(route => authCheck(route.meta?.auth));

  return subMenuItems.length > 1 ? subMenuItems : null;
};

/**
 * Recursively searches all routes for the named route.
 * Searching based on the path would be tricky especially with nested relative or root paths. See https://github.com/vuejs/vue-router/issues/1149
 */
export const findRouteConfig = (routes: RouteConfig[], name: string): RouteConfig | undefined => {
  for (const route of routes) {
    if (route.name === name) {
      return route;
    }

    if (route.children) {
      const childRoute = findRouteConfig(route.children, name);

      if (childRoute) {
        return childRoute;
      }
    }
  }

  return undefined;
};

/**
 * Returns whether the current sub menu should show in the bottom navigation.
 */
export const showBottomNavigation = (currentRoute: Route) => {
  return currentRoute.matched.find(route => route.meta.showSubMenu && route.meta.showBottomNavigation) !== undefined;
};
