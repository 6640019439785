<template>
  <v-expand-transition>
    <div v-show="show" class="section-error">
      <v-alert
        border="left"
        type="error"
        color="red"
        icon="fal fa-exclamation-circle"
        class="my-0"
        data-test="section-error"
        dense
        text
      >
        <slot />
      </v-alert>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
// use opaque colors so that the error can overlay the form with sticky positioning
::v-deep .v-alert--text::before {
  opacity: 1;
  background-color: #fde8e6;
}

::v-deep .v-alert__border {
  opacity: 1;
  background-color: #fbbdb8;
}

::v-deep .v-alert__content {
  position: relative;
}
</style>
