<template>
  <v-navigation-drawer
    ref="drawer"
    :class="{ disabled }"
    :mini-variant-width="130"
    :width="320"
    :expand-on-hover="expandOnHover && !disabled"
    :mini-variant.sync="miniVariant"
    color="transparent"
    data-test="drawer"
    app
    clipped
    dark
    floating
    permanent
    touchless
  >
    <div class="background"></div>
    <v-list v-if="!disabled" class="mt-6 pt-6" data-test="list">
      <v-list-item v-if="!expandOnHover" class="mb-6">
        <v-list-item-action @click="miniVariant = !miniVariant" class="pointer" data-test="trigger">
          <burger-menu-icon :opened="!miniVariant" class="ma-auto" />
        </v-list-item-action>
      </v-list-item>
      <div v-for="link in permittedNavigationItems" :key="link.path">
        <v-list-item :to="link.path" :class="{ 'v-list-item--active': link.active }" class="text-uppercase">
          <v-list-item-action>
            <v-badge :value="link.badgeValue === undefined ? false : link.badgeValue" color="white" dot>
              <v-icon :size="link.iconSize">{{ link.icon }}</v-icon>
            </v-badge>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t(link.label) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition v-if="link.subItems.length > 0">
          <v-list v-show="!miniVariant" class="submenu py-0">
            <template v-for="subItem in link.subItems">
              <v-list-item :key="subItem.path" :to="subItem.path" class="submenu__item">
                <v-list-item-action> </v-list-item-action>
                <v-list-item-content>
                  <v-badge
                    :value="subItem.badgeValue === undefined ? false : subItem.badgeValue"
                    color="primary"
                    dot
                    inline
                  >
                    <v-list-item-title>{{ $t(subItem.label) }}</v-list-item-title>
                  </v-badge>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-expand-transition>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import { DrawerComputed, DrawerData, DrawerProps, NavigationItem } from '@/shared/modules/Layout/types';
import BurgerMenuIcon from '@/shared/modules/Layout/components/BurgerMenuIcon.vue';
import { filterPermittedNavigationItems, setParentItemValues } from '@/shared/modules/Layout/services/drawer-service';
import { getViewableRoutes } from '@/shared/helper/permissions';

import type { RestrictedRoute } from '@/shared/types';

export default Vue.extend<DrawerData, unknown, DrawerComputed, DrawerProps>({
  components: {
    BurgerMenuIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    stationAssessmentCountUnapproved: {
      type: Number,
      default: 0
    },
    stationAssessmentImagesNotReviewed: {
      type: Number,
      default: 0
    },
    rideAssessmentCountExported: {
      type: Number,
      default: 0
    },
    currentRoutePath: {
      type: String,
      default: ''
    },
    expandOnHover: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      miniVariant: true
    };
  },
  computed: {
    navigationItems(): NavigationItem[] {
      return [
        {
          path: '/assessment',
          icon: 'far fa-clipboard-list-check',
          label: 'layout.assessment'
        },
        {
          icon: 'far fa-train',
          label: 'layout.ride',
          subItems: [
            {
              path: '/ride/analysis',
              label: 'layout.analysis',
              permission: 'RideAnalysis'
            },
            {
              path: '/ride/data-overview',
              label: 'layout.dataOverview',
              permission: 'RideDataOverview',
              badgeValue: this.rideAssessmentCountExported
            }
          ]
        },
        {
          icon: '$trainStationWhite',
          iconSize: 34,
          label: 'layout.station',
          subItems: [
            {
              path: '/station/analysis',
              label: 'layout.analysis',
              permission: 'StationAnalysis'
            },
            {
              path: '/station/map-analysis',
              label: 'layout.mapAnalysis',
              permission: 'StationAnalysis'
            },
            {
              path: '/station/data-overview',
              label: 'layout.dataOverview',
              permission: 'StationDataOverview',
              badgeValue: this.stationAssessmentCountUnapproved || this.stationAssessmentImagesNotReviewed
            },
            {
              path: '/station/base-data',
              label: 'layout.baseData',
              permission: 'StationBaseData'
            }
          ]
        },
        {
          icon: 'far fa-gear',
          label: 'layout.settings',
          subItems: [
            {
              path: '/user-management',
              label: 'layout.userManagement',
              permission: 'UserManagement'
            }
          ]
        }
      ];
    },
    permittedRoutes(): RestrictedRoute[] {
      return getViewableRoutes();
    },
    permittedNavigationItems(): NavigationItem[] {
      return setParentItemValues(
        filterPermittedNavigationItems(this.navigationItems, this.permittedRoutes),
        this.currentRoutePath
      );
    }
  },
  created() {
    const unregisterRouterHook = this.$router.afterEach(() => {
      this.miniVariant = true;
    });

    this.$once('hook:beforeDestroy', () => {
      unregisterRouterHook();
    });
  }
});
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  --list-item-height: 60px;

  .background {
    background: var(--v-white-base);
    box-shadow: rgba(0, 0, 0, 0.15) 5px 0 10px;
    position: absolute;
    top: 0;
    left: 130px;
    right: 20px;
    bottom: 0;
  }

  ::v-deep .v-list-item {
    padding: 0;
    margin-right: 20px;
    justify-content: start !important;

    .v-list-item__action {
      height: var(--list-item-height);
      width: 130px !important;
      min-width: 130px;
      margin: 0;
      padding: 0;

      i,
      & > span {
        margin: auto;
      }

      .v-icon {
        width: 34px;
      }
    }

    .v-list-item__content {
      height: var(--list-item-height);

      .v-list-item__title {
        color: var(--v-lightgrey-darken2);
        margin: 0 20px;
      }
    }

    &.v-list-item--active {
      &::before {
        opacity: 0.15;
        border-top: 1px solid var(--v-black-base);
        border-bottom: 1px solid var(--v-black-base);
      }

      .v-list-item__content {
        .v-list-item__title {
          color: var(--v-primary-base);
          font-weight: bold;
        }
      }
    }
  }

  .submenu {
    --list-item-height: 28px;

    &__item {
      min-height: var(--list-item-height);

      &:last-child {
        margin-bottom: 32px;
      }

      &.v-list-item--active::before,
      &:hover::before {
        opacity: 0 !important;
      }

      & ::v-deep .v-list-item__content {
        padding-block: 4px;
      }

      ::v-deep .v-badge__wrapper {
        margin: 0 12px 0 0;
      }
    }
  }

  &.v-navigation-drawer--mini-variant {
    border-right: 1px solid rgba(255, 255, 255, 0.25) !important;

    ::v-deep .v-list-item {
      margin-right: 0;
    }

    &.disabled {
      border-right: none !important;
    }
  }
}
</style>
