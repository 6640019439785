<template>
  <div v-if="mode !== 'view'" class="assessment-actions">
    <template v-if="mode === 'edit'">
      <v-btn
        v-if="changed"
        color="error"
        elevation="2"
        data-test="button-discard-assessment"
        fab
        @click="() => $emit('close')"
      >
        <v-icon>far fa-times</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="assessment-actions__btn--bg-white"
        color="primary"
        elevation="2"
        data-test="button-close-assessment"
        fab
        outlined
        @click="() => $emit('close')"
      >
        <v-icon>far fa-arrow-left</v-icon>
      </v-btn>
    </template>
    <v-btn
      v-if="mode === 'edit' && readOnly"
      color="info"
      elevation="2"
      data-test="button-edit-assessment"
      fab
      @click="() => $emit('edit')"
    >
      <v-icon>far fa-pen</v-icon>
    </v-btn>
    <v-btn
      v-if="(mode === 'edit' && !readOnly) || (mode === 'create' && !disableButtonComplete)"
      :disabled="disableButtonComplete"
      color="primary"
      elevation="2"
      fab
      data-test="button-complete-assessment"
      @click="checkComplete"
    >
      <v-icon>far fa-check</v-icon>
    </v-btn>
    <app-confirm-dialog
      :value="confirmSaveDialogVisible"
      :loading="loading"
      color="error"
      @cancel="confirmSaveDialogVisible = false"
      @confirm="emitComplete"
    >
      <template #title>
        <div data-test="save-dialog-title">{{ saveDialogTitle }}</div>
      </template>
      <template #text>{{ saveDialogDescription }}</template>
    </app-confirm-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import { AssessmentEditMode } from '@/shared/modules/AssessmentEdit/types';

export default Vue.extend({
  components: {
    AppConfirmDialog
  },
  props: {
    saveDialogTitle: {
      type: String,
      required: true
    },
    saveDialogDescription: {
      type: String,
      required: true
    },
    mode: {
      type: String as PropType<AssessmentEditMode>,
      default: 'create'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    complete: {
      type: Boolean,
      default: false
    },
    changed: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmSaveDialogVisible: false
    };
  },
  computed: {
    disableButtonComplete(): boolean {
      if (this.mode === 'create') {
        return !this.complete && !this.readOnly;
      }

      if (this.mode === 'edit') {
        return !this.complete || !this.changed;
      }

      return true;
    }
  },
  methods: {
    emitComplete() {
      this.$emit('complete');
    },
    checkComplete() {
      if (this.mode === 'edit') {
        this.confirmSaveDialogVisible = true;
      } else {
        this.emitComplete();
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.assessment-actions {
  display: flex;
  gap: 8px;

  &__btn--bg-white {
    background-color: white;
  }

  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: $button-disabled-background-opaque !important;
  }
}
</style>
