import { ApiStation, Profitester, Station } from '@/shared/plugins/database/profitester';
import { AxiosInstance } from 'axios';
import { axiosInstance } from '@/shared/plugins/axios';

class ImportHelper {
  database!: Profitester;
  http!: AxiosInstance;

  constructor() {
    this.database = new Profitester();
    this.http = axiosInstance;
  }

  async updateStations() {
    const response = await this.http.get('/base-data-management/station/list');

    if (response?.data) {
      const stations = response.data.map(this.convertApiStation);
      await this.database.stations.clear();
      await this.database.stations.bulkAdd(stations);
    }
  }

  async updateLines() {
    const response = await this.http.get('/base-data-management/line/list');

    if (response?.data) {
      const lines = response.data;
      await this.database.lines.clear();
      await this.database.lines.bulkAdd(lines);
    }
  }

  async updateWagonTypes() {
    const response = await this.http.get('/base-data-management/wagon-type/list');

    if (response?.data) {
      const wagonTypes = response.data;
      await this.database.wagonTypes.clear();
      await this.database.wagonTypes.bulkAdd(wagonTypes);
    }
  }

  async updateVehicles() {
    const response = await this.http.get('/base-data-management/vehicle-number/list');

    if (response?.data) {
      const vehicles = response.data;
      await this.database.vehicles.clear();
      await this.database.vehicles.bulkAdd(vehicles);
    }
  }

  public async updateDatabase() {
    try {
      await Promise.all([this.updateStations(), this.updateLines(), this.updateWagonTypes(), this.updateVehicles()]);
    } catch (error) {
      console.log(error);
    }
  }

  private convertApiStation(apiStation: ApiStation): Station {
    return {
      ...apiStation,
      // convert boolean to number because dexie can't index a boolean field
      showInStationReporting: apiStation.showInStationReporting ? 1 : 0
    };
  }
}

export default new ImportHelper();
