import Login from '@/pwa/modules/Login/views/Login.vue';
import PasswordChange from '@/pwa/modules/Account/views/PasswordChange.vue';
import StationAssessmentOverview from '@/pwa/modules/Assessment/views/StationAssessmentOverview.vue';
import StationAssessmentEditWrapper from '@/pwa/modules/Assessment/views/StationAssessmentEditWrapper.vue';
import QualityOfStay from '@/shared/modules/AssessmentEdit/views/station/QualityOfStay.vue';
import PassengerInformation from '@/shared/modules/AssessmentEdit/views/station/PassengerInformation.vue';
import Accessibility from '@/shared/modules/AssessmentEdit/views/station/Accessibility.vue';
import RideAssessmentOverview from '@/pwa/modules/Assessment/views/RideAssessmentOverview.vue';
import RideAssessmentEditWrapper from '@/pwa/modules/Assessment/views/RideAssessmentEditWrapper.vue';
import RideBaseData from '@/shared/modules/AssessmentEdit/views/ride/RideBaseData.vue';
import WagonState from '@/shared/modules/AssessmentEdit/views/ride/WagonState.vue';
import StationInformation from '@/shared/modules/AssessmentEdit/views/ride/StationInformation.vue';
import InTrainInformation from '@/shared/modules/AssessmentEdit/views/ride/InTrainInformation.vue';
import Utilization from '@/shared/modules/AssessmentEdit/views/ride/Utilization.vue';
import Conductors from '@/shared/modules/AssessmentEdit/views/ride/Conductors.vue';
import SecurityStaff from '@/shared/modules/AssessmentEdit/views/ride/SecurityStaff.vue';
import OnboardService from '@/shared/modules/AssessmentEdit/views/ride/OnboardService.vue';
import Disruption from '@/shared/modules/AssessmentEdit/views/ride/Disruption.vue';
import AssessmentComment from '@/shared/modules/AssessmentEdit/views/ride/AssessmentComment.vue';
import Wagons from '@/shared/modules/AssessmentEdit/views/ride/Wagons.vue';
import AssessmentIndex from '@/pwa/modules/Assessment/views/AssessmentIndex.vue';
import { getPermissionsForRoute } from '@/shared/helper/permissions';

import type { RouteConfig } from 'vue-router';

// These constants ensure that some paths are in sync with each other so that the navigation drawer highlights the correct item
const assessmentPath = '/assessment';
const stationPath = 'station';
const ridePath = 'ride';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      title: ''
    }
  },
  {
    path: assessmentPath,
    name: 'assessment',
    component: AssessmentIndex,
    meta: {
      auth: true,
      title: 'Erfassung',
      showSubMenu: true,
      showBottomNavigation: true
    },
    children: [
      {
        path: stationPath,
        name: 'station-assessment-list',
        component: StationAssessmentOverview,
        meta: {
          auth: getPermissionsForRoute('StationAssessment'),
          title: 'Station'
        }
      },
      {
        path: ridePath,
        name: 'ride-assessment-list',
        component: RideAssessmentOverview,
        meta: {
          auth: getPermissionsForRoute('RideAssessment'),
          title: 'Fahrt'
        }
      }
    ]
  },
  {
    path: `${assessmentPath}/${stationPath}/:id`,
    component: StationAssessmentEditWrapper,
    // Don't use name for navigation. It is only specified for title sub menu check
    name: 'station-assessment-base',
    meta: {
      auth: getPermissionsForRoute('StationAssessment'),
      title: 'Stationserfassung'
    },
    children: [
      {
        path: '',
        name: 'station-assessment',
        component: QualityOfStay,
        meta: {
          title: 'Stationserfassung'
        }
      },
      {
        path: 'quality-of-stay',
        name: 'station-assessment-quality-of-stay',
        component: QualityOfStay,
        meta: {
          title: 'Aufenthaltsqualität'
        }
      },
      {
        path: 'passenger-information',
        name: 'station-assessment-passenger-information',
        component: PassengerInformation,
        meta: {
          title: 'Fahrgastinformationen'
        }
      },
      {
        path: 'accessibility',
        name: 'station-assessment-accessibility',
        component: Accessibility,
        meta: {
          title: 'Barrierefreiheit'
        }
      }
    ]
  },
  {
    path: `${assessmentPath}/${ridePath}/:config/:id`,
    component: RideAssessmentEditWrapper,
    // Don't use name for navigation. It is only specified for title sub menu check
    name: 'ride-assessment-base',
    meta: {
      auth: getPermissionsForRoute('RideAssessment'),
      title: 'Fahrterfassung'
    },
    children: [
      {
        path: '',
        name: 'ride-assessment',
        component: RideBaseData,
        meta: {
          title: 'Fahrterfassung'
        }
      },
      {
        path: 'base-data',
        name: 'ride-assessment-base-data',
        component: RideBaseData,
        meta: {
          title: 'Grunddaten'
        }
      },
      {
        path: 'station-information',
        name: 'ride-assessment-station-information',
        component: StationInformation,
        meta: {
          title: 'Info an Station'
        }
      },
      {
        path: 'wagon-types',
        name: 'ride-assessment-wagon-types',
        component: Wagons,
        meta: {
          title: 'Behängung'
        }
      },
      {
        path: 'wagon-state',
        name: 'ride-assessment-wagon-state',
        component: WagonState,
        meta: {
          title: 'Zustand Wagen'
        }
      },
      {
        path: 'conductors',
        name: 'ride-assessment-conductors',
        component: Conductors,
        meta: {
          title: 'Zugbegleiter'
        }
      },
      {
        path: 'security-staff',
        name: 'ride-assessment-security-staff',
        component: SecurityStaff,
        meta: {
          title: 'Sicherheitspersonal'
        }
      },
      {
        path: 'onboard-service',
        name: 'ride-assessment-onboard-service',
        component: OnboardService,
        meta: {
          title: 'Bordservice'
        }
      },
      {
        path: 'utilization',
        name: 'ride-assessment-utilization',
        component: Utilization,
        meta: {
          title: 'Auslastung'
        }
      },
      {
        path: 'in-train-information',
        name: 'ride-assessment-in-train-information',
        component: InTrainInformation,
        meta: {
          title: 'Info im Zug'
        }
      },
      {
        path: 'disruption',
        name: 'ride-assessment-disruption',
        component: Disruption,
        meta: {
          title: 'Störung im Zug'
        }
      },
      {
        path: 'comment',
        name: 'ride-assessment-comment',
        component: AssessmentComment,
        meta: {
          title: 'Bemerkung'
        }
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    redirect: { name: 'account-password-change' }
  },
  {
    path: '/account/password-change',
    name: 'account-password-change',
    component: PasswordChange,
    meta: {
      auth: true,
      title: 'Passwort ändern'
    }
  }
] as RouteConfig[];
