<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    this.$auth.load();
  }
};
</script>

<style lang="scss">
@import '~@/shared/styles/fonts.scss';
@import '~@/shared/styles/helpers.scss';
</style>
