export default {
  rideAssessment: {
    statistics: {
      heading: 'Statistik'
    },
    lineName: 'Linie',
    drafts: 'Entwürfe',
    synchronized: 'Synchronisiert',
    revision: 'Korrektur',
    trainNumber: 'Zug-Nr.',
    departureStation: 'Bahnhof ab',
    statuses: {
      complete: 'vollständig',
      incomplete: 'unvollständig'
    },
    tableHeaders: {
      lineName: 'Linie',
      trainNumber: 'Zugnummer',
      assessmentDateTime: 'Erfassungszeitpunkt',
      departureStationCode: 'Ab',
      arrivalStationCode: 'An',
      status: 'Status'
    },
    actions: {
      createAssessment: 'Neue Fahrterfassung',
      synchronizeAssessment: 'Fahrterfassung synchronisieren',
      synchronizeAssessmentShort: 'synchronisieren',
      reviseAssessment: 'Fahrterfassung korrigieren',
      reviseAssessmentShort: 'Korrigieren',
      deleteAssessment: 'Fahrterfassung löschen',
      exportAssessment: 'Fahrterfassungen exportieren',
      exportAssessmentShort: 'exportieren'
    },
    deleteDialog: {
      title: 'Erfassung wirklich löschen?',
      description: 'Die Fahrterfassung für "{0}" wird unwiderruflich gelöscht.'
    },
    synchronizeDialog: {
      title: 'Fahrterfassung synchronisieren?',
      description:
        'Die Fahrterfassung für "{0}" wird synchronisiert und kann auch von anderen Geräten bearbeitet werden.'
    },
    exportDialog: {
      title: 'Fahrterfassungen exportieren?',
      description: 'Die ausgewählten Fahrterfassungen werden exportiert und können nicht mehr bearbeitet werden.'
    },
    reviseDialog: {
      title: 'Fahrterfassung korrigieren?',
      description:
        'Die Fahrterfassung für "{0}" wird in die Liste der synchronisierten Erfassungen verschoben. Bitte bearbeiten Sie die Erfassung dort und exportieren Sie sie erneut.'
    },
    snackbar: {
      rideAssessmentComplete: 'Die Fahrterfassung ist nun vollständig.',
      reviseSuccess: 'Die Fahrterfassung kann nun korrigiert werden.',
      reviseError: 'Die Fahrterfassung konnte nicht verschoben werden.',
      deleteSuccess: 'Die Fahrterfassung wurde gelöscht.',
      deleteError: 'Die Fahrterfassung konnte nicht gelöscht werden.',
      synchronizeSuccess: 'Die Fahrterfassung wurde synchronisiert.',
      synchronizeError: 'Die Fahrterfassung konnte nicht synchronisiert werden.',
      exportError: 'Die Fahrterfassungen konnten nicht exportiert werden.',
      fetchError: 'Die Erfassung konnte nicht geladen werden.',
      updateSuccess: 'Erfassung aktualisiert',
      updateError: 'Die Erfassung konnte nicht aktualisiert werden.'
    }
  }
};
