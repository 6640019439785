<template>
  <v-container class="pt-0 px-6">
    <section-heading subheading="rideAssessmentEdit.sections.wagonState" />
    <div class="form-grid">
      <devaluation-input
        :title="vehicleNumberUnit.title"
        :show-info="vehicleNumberUnit.showInfo"
        :has-comment="vehicleNumberUnit.hasComment"
        :error-message="vehicleNumberUnit.errorMessage"
        @info-icon-clicked="openInfoModal(vehicleNumberUnit.key)"
        @open-modal="openCameraAndNotesModal(vehicleNumberUnit.key)"
      >
        <vehicle-number-select
          :value="vehicleNumberUnit.value"
          :wagon-types="rideAssessment.wagonTypes.values"
          :disabled="vehicleNumberUnit.disabled"
          data-test="vehicle-number-select"
          @input="updateVehicleNumber($event)"
        />
      </devaluation-input>
      <devaluation-input
        v-for="input in wagonStateDevaluationInputs"
        :key="input.key"
        :title="input.title"
        :show-info="input.showInfo"
        :has-comment="input.hasComment"
        :error-message="input.errorMessage"
        @info-icon-clicked="openInfoModal(input.key)"
        @open-modal="openCameraAndNotesModal(input.key)"
      >
        <component
          :is="input.component"
          :value="input.value"
          :type="input.type"
          :disabled="input.disabled"
          @input="updateValue(input.key, $event)"
        />
      </devaluation-input>
    </div>
    <assessment-overview-button :complete="sectionIsComplete" to-route-name="ride-assessment" />
    <devaluation-information-modal
      v-model="devaluationInformationModalVisible"
      :title="devaluationInformationModalTitle"
      :text="devaluationInformationModalText"
    />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalDevaluationUnitKey"
      :devaluation-unit-name="cameraAndNotesModalDevaluationUnitKey"
      :title="cameraAndNotesModalTitle"
      :note="cameraAndNotesModalDevaluationUnit?.note ?? ''"
      :images="cameraAndNotesModalImages"
      :download-title="cameraAndNotesModalDownloadTitle"
      :read-only="cameraAndNotesModalReadOnly"
      :no-images-allowed="cameraAndNotesModalNoImagesAllowed"
      @add-note="updateValue(cameraAndNotesModalDevaluationUnitKey, $event, 'note')"
      @add-images="addImages"
      @delete-image="deleteImage"
      @closed="cameraAndNotesModalDevaluationUnitKey = ''"
    >
      <predefined-notes-list
        :devaluation-unit-key="cameraAndNotesModalDevaluationUnitKey"
        :selected-notes="cameraAndNotesModalDevaluationUnit?.predefinedNotes ?? []"
        :disabled="cameraAndNotesModalReadOnly"
        @update:selected-notes="updateValue(cameraAndNotesModalDevaluationUnitKey, $event, 'predefinedNotes')"
      />
    </camera-and-notes-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import PredefinedNotesList from '@/shared/modules/AssessmentEdit/components/PredefinedNotesList.vue';
import DevaluationInformationModal from '@/shared/modules/AssessmentEdit/components/DevaluationInformationModal.vue';
import VehicleNumberSelect from '@/shared/modules/AssessmentEdit/components/VehicleNumberSelect.vue';
import { RideFormMixin, RideFormMixinType } from '@/shared/modules/AssessmentEdit/mixins/ride-form-mixin';
import { WagonType } from '@/shared/plugins/database/profitester';

export default (Vue as VueConstructor<Vue & RideFormMixinType>).extend({
  mixins: [RideFormMixin('wagonState')],
  components: {
    SectionHeading,
    DevaluationInput,
    VehicleNumberSelect,
    AssessmentOverviewButton,
    CameraAndNotesModal,
    PredefinedNotesList,
    DevaluationInformationModal
  },
  computed: {
    vehicleNumberUnit() {
      return this.devaluationInputs.filter(item => item.key === 'vehicleNumber')[0];
    },
    wagonStateDevaluationInputs() {
      return this.devaluationInputs.filter(item => item.key !== 'vehicleNumber');
    }
  },
  methods: {
    async updateVehicleNumber(vehicleNumber: string) {
      this.rideAssessment.assessedWagonTypeId = '';
      this.rideAssessment.assessedWagonTypeName = '';
      const wagonType = await this.getWagonTypeByVehicleNumber(vehicleNumber);
      if (wagonType) {
        this.rideAssessment.assessedWagonTypeId = wagonType.id;
        this.rideAssessment.assessedWagonTypeName = wagonType.name;
      }

      this.updateValue('vehicleNumber', !vehicleNumber ? undefined : vehicleNumber);
    },
    async getWagonTypeByVehicleNumber(vehicleNumber: string): Promise<WagonType | undefined> {
      if (vehicleNumber === '') {
        return undefined;
      }

      if (this.rideAssessment.wagonTypes.values.length === 0) {
        return undefined;
      }

      const wagonType = this.rideAssessment.wagonTypes.values.find(wagonType =>
        wagonType.vehicleNumbers.includes(vehicleNumber)
      );

      if (!wagonType) {
        return undefined;
      }

      try {
        return await this.$database.wagonTypes.get(wagonType.wagonTypeId);
      } catch (error) {
        console.error(error);
      }

      return undefined;
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-grid {
  @include dynamic-grid(40px, 300px, 3);

  @include md-and-up {
    row-gap: 40px;
    justify-content: start;
  }
}
</style>
