var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper d-flex flex-column text-center pa-6"},[_c('v-row',{staticClass:"flex-grow-0",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"heading text-uppercase font-weight-bold mb-2 mb-sm-8"},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.xs ? _vm.$t('stationAssessment.statistics.headingShort') : _vm.$t('stationAssessment.statistics.heading', { quarter: _vm.currentQuarter, year: _vm.currentYear }))+" ")]),_c('div',{staticClass:"subheading font-weight-bold d-sm-none mb-3"},[_vm._v(_vm._s(`${_vm.currentQuarter}/${_vm.currentYear}`))])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-space-around",class:[_vm.showLastUpdatedHint ? 'align-start' : 'align-center']},[_c('radial-bar-chart',{attrs:{"color":'#fa585e',"value":_vm.daysUntilCurrentQuarterEnd,"total":_vm.totalDaysOfCurrentQuarter,"labels":[
          _vm.$tc('stationAssessment.statistics.daysUntil', _vm.daysUntilCurrentQuarterEnd),
          _vm.$t('stationAssessment.statistics.endOfQuarter')
        ]}}),_c('div',{staticClass:"station-reports"},[(_vm.stationReportsLoading)?_c('v-progress-circular',{staticClass:"station-reports__loading-indicator",attrs:{"size":100,"width":"6","color":"primary","indeterminate":""}}):_vm._e(),_c('radial-bar-chart',{staticClass:"station-reports__chart",class:{ 'visibility-hidden': _vm.stationReportsLoading },attrs:{"value":_vm.stationReportsCountUnassessed,"total":_vm.stationReportsCountTotal,"labels":[
            _vm.$tc('stationAssessment.statistics.unassessed', _vm.stationReportsCountUnassessed),
            _vm.$tc('stationAssessment.statistics.stations', _vm.stationReportsCountUnassessed)
          ]}}),(_vm.showLastUpdatedHint)?_c('p',{staticClass:"station-reports__last-updated-hint mb-0 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('stationAssessment.statistics.lastUpdatedAt', { date: _vm.formatDateTimeShort(_vm.stationReportsUpdated) }))+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }