<template>
  <div :data-test="dataTest" class="pos-relative">
    <v-select
      v-bind="$attrs"
      :value="value"
      :items="items"
      :disabled="disabled"
      :item-value="itemValue"
      :item-text="itemText"
      append-icon=""
    />
    <div class="activator" v-if="!disabled" @click="onOpen"></div>
    <button class="clearer" v-if="clearable && value && !disabled" @click="onClear">
      <v-icon>fal fa-times</v-icon>
    </button>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="390"
      scrollable
      attach=".v-application"
    >
      <v-card :class="{ full: $vuetify.breakpoint.xsOnly }" :data-test="`${dataTest}-dialog`">
        <v-card-title class="pt-2 pb-0 px-4" v-if="!noSearch">
          <v-text-field
            v-model="search"
            :placeholder="$t('assessmentEdit.dialogSelect.searchLabel')"
            append-icon="far fa-search"
            class="search"
            autocorrect="off"
            spellcheck="false"
            hide-details
            clearable
            solo
          ></v-text-field>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-data-iterator
            :items="items"
            :search="search"
            disable-pagination
            disable-sort
            hide-default-header
            hide-default-footer
          >
            <template #default="props">
              <v-list class="py-4">
                <v-list-item-group :value="value" color="primary">
                  <v-list-item
                    v-for="item in props.items"
                    :key="item.index"
                    :value="item[itemValue]"
                    @click="onClick(item[itemValue])"
                  >
                    <slot name="item" :item="item">
                      <v-list-item-content>
                        <v-list-item-title class="text-truncate">{{ item[itemText] }}</v-list-item-title>
                      </v-list-item-content>
                    </slot>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>

            <template #no-results>
              <div class="text-center pa-6">
                {{ $t('assessmentEdit.dialogSelect.noSearchResult') }}
              </div>
            </template>

            <template #no-data>
              <div class="text-center pa-6">
                {{ $t('assessmentEdit.dialogSelect.noData') }}
              </div>
            </template>
          </v-data-iterator>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    itemValue: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      default: 'title'
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dataTest: {
      type: String,
      default: 'dialog-select'
    }
  },
  data() {
    return {
      dialog: false,
      search: ''
    };
  },
  methods: {
    onOpen() {
      this.dialog = true;
      this.$emit('open');
    },
    onClick(value: string | undefined) {
      this.dialog = false;
      this.search = '';
      this.$emit('input', value);
    },
    onClear() {
      this.$emit('input', '');
    }
  }
});
</script>

<style scoped>
.activator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.clearer {
  display: inline-block;
  padding: 15px 5px 15px;
  position: absolute;
  top: 0;
  right: 0;
}

:deep(.search .v-input__slot) {
  font-weight: normal;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

:deep(.search .v-icon) {
  font-size: 1.25rem;
}

:deep(.v-card.full) {
  width: 90vw;
  height: 90vh;
}

:deep(.v-input--is-disabled .v-icon--disabled) {
  color: rgba(0, 0, 0, 0.5) !important;
}

:deep(.v-select__selection) {
  max-width: 80%;
}
</style>
