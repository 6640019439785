import { isAxiosError } from '@/shared/helper/axios';
import { ApiAnswer } from '@/pwa/modules/Account/types';

import type { ActionTree, Module } from 'vuex';
import type { VueConstructor } from 'vue';

export const ACCOUNT_NAMESPACE = 'ACCOUNT_NAMESPACE';

const configureActions = (Vue: VueConstructor) => {
  const actions: ActionTree<unknown, unknown> = {
    async changePassword(_, { userId, password, newPassword }) {
      let response: ApiAnswer = { success: false };
      try {
        response = await Vue.prototype.$http.post('/commands/user-management/user/change-password', {
          userId,
          password,
          newPassword
        });
        response.success = true;
      } catch (error) {
        if (isAxiosError<ApiAnswer>(error)) {
          response.code = error.response?.data.code;
          response.message = error.response?.data.message;
        }
        response.success = false;
      }

      return response;
    }
  };
  return actions;
};

export const configureAccountStore = (Vue: VueConstructor) => {
  const module: Module<unknown, unknown> = {
    namespaced: true,
    actions: configureActions(Vue)
  };
  return module;
};
