<template>
  <div class="wrapper d-flex flex-column text-center pa-6">
    <v-row no-gutters class="flex-grow-0">
      <v-col>
        <div class="heading text-uppercase font-weight-bold mb-2 mb-sm-8">
          {{
            $vuetify.breakpoint.xs
              ? $t('stationAssessment.statistics.headingShort')
              : $t('stationAssessment.statistics.heading', { quarter: currentQuarter, year: currentYear })
          }}
        </div>
        <div class="subheading font-weight-bold d-sm-none mb-3">{{ `${currentQuarter}/${currentYear}` }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="d-flex justify-space-around" :class="[showLastUpdatedHint ? 'align-start' : 'align-center']">
        <radial-bar-chart
          :color="'#fa585e'"
          :value="daysUntilCurrentQuarterEnd"
          :total="totalDaysOfCurrentQuarter"
          :labels="[
            $tc('stationAssessment.statistics.daysUntil', daysUntilCurrentQuarterEnd),
            $t('stationAssessment.statistics.endOfQuarter')
          ]"
        />
        <div class="station-reports">
          <v-progress-circular
            v-if="stationReportsLoading"
            :size="100"
            class="station-reports__loading-indicator"
            width="6"
            color="primary"
            indeterminate
          />
          <radial-bar-chart
            :class="{ 'visibility-hidden': stationReportsLoading }"
            class="station-reports__chart"
            :value="stationReportsCountUnassessed"
            :total="stationReportsCountTotal"
            :labels="[
              $tc('stationAssessment.statistics.unassessed', stationReportsCountUnassessed),
              $tc('stationAssessment.statistics.stations', stationReportsCountUnassessed)
            ]"
          />
          <p v-if="showLastUpdatedHint" class="station-reports__last-updated-hint mb-0 mt-2">
            {{ $t('stationAssessment.statistics.lastUpdatedAt', { date: formatDateTimeShort(stationReportsUpdated) }) }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import RadialBarChart from '@/pwa/modules/Assessment/components/RadialBarChart.vue';
import { getQuarter, startOfQuarter, differenceInCalendarDays, endOfQuarter } from 'date-fns';
import { mapActions, mapState } from 'vuex';
import { STATION_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/stationAssessment';
import { VueOfflineMixin } from '@/shared/modules/Global/mixins/VueOfflineMixin';
import { formatDateTimeShort } from '@/shared/helper/date';

import type { StationAssessmentState } from '@/pwa/modules/Assessment/types/station';

type VuexBindings = {
  stationReportsUpdateFailed: StationAssessmentState['stationReportsUpdateFailed'];
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof VueOfflineMixin> & VuexBindings>).extend({
  components: {
    RadialBarChart
  },
  mixins: [VueOfflineMixin],
  data() {
    const currentDate = new Date();

    return {
      currentQuarter: `Q${getQuarter(currentDate)}`,
      currentYear: currentDate.getFullYear(),
      totalDaysOfCurrentQuarter: differenceInCalendarDays(endOfQuarter(currentDate), startOfQuarter(currentDate)) + 1,
      daysUntilCurrentQuarterEnd: differenceInCalendarDays(endOfQuarter(currentDate), currentDate) + 1
    };
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_NAMESPACE, [
      'stationReportsUpdated',
      'stationReportsUpdateFailed',
      'stationReportsCountTotal',
      'stationReportsCountUnassessed',
      'stationReportsLoading'
    ]),
    showLastUpdatedHint(): boolean {
      return this.isOffline || this.stationReportsUpdateFailed;
    }
  },
  methods: {
    ...mapActions(STATION_ASSESSMENT_NAMESPACE, ['fetchStationReports']),
    formatDateTimeShort
  },
  mounted() {
    this.fetchStationReports();
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

.wrapper {
  height: 100%;
}

.heading {
  letter-spacing: 0.0875em;

  @include xs-only {
    color: var(--v-primary-base);
    font-size: 0.75rem;
  }
}

.subheading {
  font-size: 1.25rem;
  letter-spacing: 0.0375em;
  line-height: 1.1;
}

.station-reports {
  display: grid;
  grid-template-areas: 'main';

  &__loading-indicator,
  &__chart {
    grid-area: main;
    margin: auto;
  }

  &__last-updated-hint {
    color: var(--v-lightgrey-darken2);
    font-size: 0.75rem;
  }
}
</style>
