<template>
  <div class="statistics d-flex flex-column pt-6">
    <p class="heading text-uppercase text-center font-weight-bold mb-2 mb-sm-8">
      {{ $t('stationAssessment.statistics.headingShort') }}
    </p>
    <div class="statistics__grid flex-grow-1 grey lighten-2">
      <ride-assessment-statistics-count :count="draftCount" :label="$t('rideAssessment.drafts')" color="warning" />
      <ride-assessment-statistics-count
        :count="synchronizedCount"
        :label="$t('rideAssessment.synchronized')"
        color="success"
      />
      <ride-assessment-statistics-count :count="revisionCount" :label="$t('rideAssessment.revision')" color="error" />
      <div class="d-sm-none white">
        <animated-icon icon="train" intermission="8000" loop autoplay />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import RideAssessmentStatisticsCount from '@/pwa/modules/Assessment/components/RideAssessmentStatisticsCount.vue';
import AnimatedIcon from '@/shared/modules/Global/components/AnimatedIcon.vue';

export default Vue.extend({
  components: {
    RideAssessmentStatisticsCount,
    AnimatedIcon
  },
  props: {
    draftCount: {
      type: Number,
      default: 0
    },
    synchronizedCount: {
      type: Number,
      default: 0
    },
    revisionCount: {
      type: Number,
      default: 0
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';

.heading {
  letter-spacing: 0.0875em;

  @include xs-only {
    color: var(--v-primary-base);
    font-size: 0.75rem;
  }
}

.statistics {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // different row heights for visual balance
    grid-template-rows: 90px 100px;
    grid-gap: 1px;

    @include sm-and-up {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 215px;
    }
  }
}
</style>
