<template>
  <div v-observe-visibility="onVisibilityChange" class="v-picker v-card v-picker--time theme--light">
    <div class="v-picker__title picker-header">
      <div class="v-time-picker-title">
        <div class="v-time-picker-title__time">
          <div class="display-3 font-weight-medium">{{ value }}</div>
        </div>
      </div>
    </div>
    <div class="v-picker__body theme--light" style="width: 290px">
      <v-row no-gutters>
        <v-col cols="6">
          <div>
            <ul class="picker-hour v-date-picker-years">
              <li
                v-for="count in 24"
                :key="count"
                :class="{
                  'active primary--text': String(count - 1).padStart(2, '0') === hour,
                  disabled: isHourDisabled(count - 1)
                }"
                :ref="String(count - 1).padStart(2, '0') === hour ? 'activeHour' : ''"
                @click="onClickHour(count - 1)"
                class="picker-entry"
              >
                {{ String(count - 1).padStart(2, '0') }}
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <ul class="picker-minute v-date-picker-years">
              <li
                v-for="count in 60"
                :key="count"
                :class="{
                  'active primary--text': String(count - 1).padStart(2, '0') === minute,
                  disabled: isMinuteDisabled(count - 1)
                }"
                :ref="String(count - 1).padStart(2, '0') === minute ? 'activeMinute' : ''"
                @click="onClickMinute(count - 1)"
                class="picker-entry"
              >
                {{ String(count - 1).padStart(2, '0') }}
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" class="pt-6 pb-4 px-6" :class="resettable ? 'd-flex justify-space-between' : 'text-right'">
          <v-btn v-if="resettable" @click="$emit('reset')" class="px-5" data-test="button-time-picker-reset" depressed>
            {{ $t('layout.common.reset') }}
          </v-btn>
          <v-btn @click="$emit('ok')" color="primary" class="px-9" data-test="button-time-picker-ok" depressed>
            {{ $t('layout.common.ok') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { format, setHours, setMinutes, isFuture, parseISO } from 'date-fns';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true
    },
    futureDisabled: {
      type: Boolean,
      default: false
    },
    resettable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hour(): string {
      return format(parseISO('1990-01-01 ' + this.value), 'HH');
    },
    minute(): string {
      return format(parseISO('1990-01-01 ' + this.value), 'mm');
    }
  },
  methods: {
    async onVisibilityChange() {
      await Vue.nextTick();
      const activeHour = this.$refs.activeHour as HTMLElement[];
      if (activeHour?.[0]) {
        activeHour[0].scrollIntoView({ behavior: 'instant', block: 'center' });
      }

      const activeMinute = this.$refs.activeMinute as HTMLElement[];
      if (activeMinute?.[0]) {
        activeMinute[0].scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    },
    isHourDisabled(hour: number) {
      if (!this.futureDisabled) {
        return false;
      }

      return isFuture(setHours(new Date(), hour));
    },
    isMinuteDisabled(minutes: number) {
      if (!this.futureDisabled) {
        return false;
      }

      return isFuture(setHours(setMinutes(new Date(), minutes), parseInt(this.hour)));
    },
    onClickHour(hour: number) {
      if (this.isHourDisabled(hour)) {
        return;
      }

      this.$emit('input', format(setHours(parseISO('1990-01-01 ' + this.value), hour), 'HH:mm'));
    },
    onClickMinute(minute: number) {
      if (this.isMinuteDisabled(minute)) {
        return;
      }

      this.$emit('input', format(setMinutes(parseISO('1990-01-01 ' + this.value), minute), 'HH:mm'));
    }
  },
  mounted() {
    if (!this.value) {
      this.$emit('input', format(Date.now(), 'yyyy-MM-dd HH:mm:ss'));
    }
  }
});
</script>

<style scoped>
.picker-entry.disabled {
  color: var(--v-lightgrey-base);
}
.picker-entry.disabled:hover {
  background: none;
  cursor: initial;
}
</style>
