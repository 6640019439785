<template>
  <v-autocomplete
    :items="items"
    :label="$t(label)"
    :filter="filterNameAndCode"
    item-text="name"
    item-value="id"
    hide-details="auto"
    data-test="base-data-autocomplete"
    @input="$emit('item-selected', $event)"
  >
    <template #append>
      <v-icon small class="mt-1">fal fa-search</v-icon>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { contains } from '@/pwa/modules/Assessment/helpers/string';
import { BaseDataListItem } from '@/pwa/modules/Assessment/types';

export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<BaseDataListItem[]>,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    filterNameAndCode(item: BaseDataListItem, queryText: string, itemText: string) {
      return contains(item.code, queryText) || contains(item.name, queryText);
    }
  }
});
</script>

<style scoped lang="scss">
::v-deep .v-input__slot {
  padding-inline: 24px;

  &::before,
  &::after {
    left: 24px !important;
    width: calc(100% - 48px) !important;
  }
}

::v-deep .v-list-item {
  padding-inline: 24px;
}
</style>
