<template>
  <station-assessment-edit
    v-if="stationAssessment"
    :initial-station-assessment="stationAssessment"
    @delete="deleteAssessment"
    @assessment-complete="completeStationAssessment"
    @navigate-back="goToAssessmentList"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import { mapState, mapActions } from 'vuex';
import { STATION_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/stationAssessment';
import StationAssessmentEdit from '@/shared/modules/AssessmentEdit/components/StationAssessmentEdit.vue';

import type { StationAssessment } from '@/shared/modules/AssessmentEdit/types/station';
import type { StationAssessmentState } from '@/pwa/modules/Assessment/types/station';

type VuexBindings = {
  stationAssessments: StationAssessmentState['stationAssessments'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: { StationAssessmentEdit },
  data() {
    return {
      stationAssessment: undefined as StationAssessment | undefined
    };
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_NAMESPACE, ['stationAssessments'])
  },
  methods: {
    ...mapActions(STATION_ASSESSMENT_NAMESPACE, ['showStationAssessmentCompleteSnackbar', 'deleteStationAssessment']),
    async goToAssessmentList() {
      await this.$router.push({ name: 'station-assessment-list' });
    },
    async deleteAssessment() {
      await this.deleteStationAssessment(this.stationAssessment);
      await this.goToAssessmentList();
    },
    async completeStationAssessment() {
      await this.goToAssessmentList();
      await this.showStationAssessmentCompleteSnackbar();
    }
  },
  mounted() {
    const assessmentId = this.$route.params.id;
    this.stationAssessment = this.stationAssessments.find(
      (assessment: StationAssessment) => assessment.assessmentId === assessmentId
    );
  }
});
</script>
