import { Project, RestrictedRoute, UserRole } from '@/shared/types';
import { authUser, getUserRoleAndProjects } from '@/shared/helper/vue-auth';
import type { PermissionName } from '@/shared/types';

const SUPER_ADMINISTRATOR = 'ROLE_SUPER_ADMINISTRATOR';
const ADMINISTRATOR = 'ROLE_ADMINISTRATOR';
const SUPERVISOR = 'ROLE_SUPERVISOR';
const REPORTER = 'ROLE_REPORTER';

const RIDE = 'ROLE_RIDE';
const STATION = 'ROLE_STATION';

const permissionNames: PermissionName[] = [
  'canViewStationAnalysis',
  'canViewStationDataOverview',
  'canViewStationBaseData',
  'canViewStationAssessment',
  'canViewRideAnalysis',
  'canViewRideDataOverview',
  'canViewRideAssessment',
  'canViewUserManagement',
  'canManageSuperAdministrator',
  'canManageAdministrator',
  'canManageSupervisor',
  'canManageReporter'
];

/**
 * This permission object currently defines all possible permissions and properties by which a check function can
 * determine whether a user has a certain permission. When the permission requirements get more complex and possibly
 * depend on runtime values, permission specific check functions can be added to this object that are then used by the
 * generic permission check function.
 */
const permissions: Record<
  PermissionName,
  { requiredUserRoles: UserRole[]; requiredProjects: Project[]; route?: RestrictedRoute; role?: UserRole }
> = {
  canManageSuperAdministrator: {
    requiredUserRoles: [SUPER_ADMINISTRATOR],
    requiredProjects: [],
    role: SUPER_ADMINISTRATOR
  },
  canManageAdministrator: {
    requiredUserRoles: [SUPER_ADMINISTRATOR],
    requiredProjects: [],
    role: ADMINISTRATOR
  },
  canManageSupervisor: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR],
    requiredProjects: [],
    role: SUPERVISOR
  },
  canManageReporter: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR],
    requiredProjects: [],
    role: REPORTER
  },
  canViewStationAnalysis: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR],
    requiredProjects: [STATION],
    route: 'StationAnalysis'
  },
  canViewStationDataOverview: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR],
    requiredProjects: [STATION],
    route: 'StationDataOverview'
  },
  canViewStationBaseData: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR],
    requiredProjects: [STATION],
    route: 'StationBaseData'
  },
  canViewStationAssessment: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR, REPORTER],
    requiredProjects: [STATION],
    route: 'StationAssessment'
  },
  canViewRideAnalysis: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR],
    requiredProjects: [RIDE],
    route: 'RideAnalysis'
  },
  canViewRideDataOverview: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR],
    requiredProjects: [RIDE],
    route: 'RideDataOverview'
  },
  canViewRideAssessment: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR, REPORTER],
    requiredProjects: [RIDE],
    route: 'RideAssessment'
  },
  canViewUserManagement: {
    requiredUserRoles: [SUPER_ADMINISTRATOR, ADMINISTRATOR, SUPERVISOR],
    requiredProjects: [],
    route: 'UserManagement'
  }
};

function getEditableRoles(editorRole: UserRole) {
  return permissionNames
    .filter(permissionName => {
      const { requiredUserRoles } = permissions[permissionName];
      return requiredUserRoles.includes(editorRole);
    })
    .map(permissionName => permissions[permissionName].role)
    .filter((role): role is UserRole => role !== undefined);
}

// the permission check returns true if the user has at least one of the required roles and at least one of the required projects
function checkPermissionRequirements(permissionName: PermissionName, userRole: UserRole, projects: Project[]) {
  const { requiredUserRoles, requiredProjects } = permissions[permissionName];
  return (
    requiredUserRoles.includes(userRole) &&
    (requiredProjects.length === 0
      ? true
      : requiredProjects.some(requiredProject => projects.includes(requiredProject)))
  );
}

function getPermissionsForUser(userRole: UserRole, projects: Project[]) {
  return permissionNames.filter(permissionName => {
    return checkPermissionRequirements(permissionName, userRole, projects);
  });
}

function getPermissionsForRoute(route: RestrictedRoute) {
  return permissionNames.filter(permissionName => permissions[permissionName].route === route);
}

function getViewableRoutes() {
  const result = getUserRoleAndProjects(authUser('roles') ?? []);
  if (result instanceof Error) {
    return [];
  }

  return permissionNames
    .filter(permissionName => {
      return checkPermissionRequirements(permissionName, result.userRole, result.projects);
    })
    .map(permissionName => permissions[permissionName].route)
    .filter((route): route is RestrictedRoute => route !== undefined);
}

function hasPermission(permissionName: PermissionName) {
  const result = getUserRoleAndProjects(authUser('roles') ?? []);
  if (result instanceof Error) {
    return false;
  }

  return checkPermissionRequirements(permissionName, result.userRole, result.projects);
}

export {
  getEditableRoles,
  getPermissionsForRoute,
  getPermissionsForUser,
  getViewableRoutes,
  hasPermission,
  SUPER_ADMINISTRATOR,
  ADMINISTRATOR,
  SUPERVISOR,
  REPORTER,
  RIDE,
  STATION
};
