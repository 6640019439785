import { DevaluationUnitName, ScaleTypeName } from '@/shared/modules/AssessmentEdit/types/ride';

function getDevaluationUnitInformation(
  devaluationUnitName: DevaluationUnitName,
  scaleType: ScaleTypeName
): string | undefined {
  const information = devaluationUnitInformation[devaluationUnitName];
  if (!information) {
    return undefined;
  }
  if (typeof information === 'string') {
    return information;
  }
  return information[scaleType];
}

const devaluationUnitInformation: Partial<Record<DevaluationUnitName, string | Record<ScaleTypeName, string>>> = {
  cleanlinessExteriorDoors:
    'Nicht o.K. sind klebende und abfärbende Verschmutzung von innen.<br>Die Scheiben der Außentüren bitte unter -Sauberkeit Scheiben- bewerten.',
  cleanlinessExteriorShell:
    'Ok ist normaler, sich während der Fahrt auftragender Schmutz (z.B. Bremsstaub).<br>Nicht ok ist Schmutz, der über die vorhergehende Kategorie hinausgeht (z.B. alter Bremsstaub, grob verschmierte Karosserie).',
  cleanlinessFloor:
    'Ok ist normaler, witterungs- und jahreszeitlich bedingter, sich während der Fahrt auftragender Schmutz (z.B. Staub, Wasserflecken, im Winter: Split im normalen Maß).<br>Nicht ok sind Essensreste, loser Müll, klebende Flecken, über das normale Maß auftretender Split (im Winter).',
  cleanlinessWindows:
    'O.K. ist normaler, sich während der Fahrt auftragender Schmutz (z.B. Bremsstaub, aber auch Wasserflecken etc.)<br>Nicht o.K. sind Fettflecken, Essensreste etc. Bitte auch die Scheiben der Außentüren prüfen.',
  cleanlinessInteriorScaffold:
    'Ok ist frei von Verschmutzung. Nicht ok sind klebende und/oder abfärbende Verschmutzung, zerfledderte Zeitschrift und/oder Müll im Gepäckfach.',
  cleanlinessPassageDoors: 'Nicht ok sind klebende/abfärbende Flecken.',
  cleanlinessSeats:
    'Ok ist normaler, sich während der Fahrt auftragender Schmutz (einige Krümel, ordentlich zusammengelegte Zeitungen etc.).<br>Nicht ok sind Essensreste, loser Müll, klebende Flecken, zerfledderte Zeitungen etc.',
  cleanlinessToilets:
    'Ok ist normale, durch reguläre Benutzung auftretende Verschmutzung.<br>Nicht ok sind fehlende Seife, Klopapier, Handtuchpapier, überquellender Abfallbehälter, störende, geruchsbelästigender Verschmutzung.<br>Beachte: Dieses Bewertungsfeld frei lassen, wenn Tür verschlossen ist (keine Bewertung möglich).',
  cleanlinessWasteBins:
    'Ok sind nicht überquellende, saubere Abfallbehälter. Nicht ok sind überquellende Abfallbehälter, klebende und/oder abfärbende Verschmutzung.',
  conductorAppearance:
    'Erscheinungsbild und Ausrüstung:<br>100% = Gepflegtes Erscheinungsbild,<br>50% = Dienstbekleidung oder Ausrüstung unvollständig,<br>0% = Dienstbekleidung und Ausrüstung unvollständig.',
  conductorBehavior:
    'Auftreten und Verhalten:<br>100% = Keine Beanstandungen,<br>75% = Unfreundlich/ hektisch, aber hinreichende Situationsbewältigung,<br>25% = Vereinzelt defizitäre Situationsbewältigung,<br>0% = in der Regel defizitäre Situationsbewältigung oder eskalierendes Handeln.',
  conductorInactivity:
    'Personal ist mehr als zehn Minuten inaktiv.<br>Inaktivität = keine Ticketkontrolle, Kontrollgänge oder Präsenz-Demonstration im Zug.',
  exteriorGraffiti: {
    '3er':
      '100% = frei von Graffitis,<br>75% = vereinzelte, kleinflächige Schmierereien,<br>0% = großflächige Schmierereien.',
    '4er':
      '100% = 100% der Außenhaut und Fenster frei von Graffiti,<br>90% = 90-99% der Außenhaut und Fenster frei von Graffiti,<br>75% = 50-89% der Außenhaut und Fenster frei von Graffiti,<br>0% = 0-49% der Außenhaut und Fenster frei von Graffiti.'
  },
  passengerInformationSystem: {
    '3er':
      'In 0-49%, 50-99% oder 100% der Anzeigen sind Durchführung und einwandfreier Informationsgehalt (Liniennummer, Zugziel, nächster Halt) gewährleistet.',
    '4er':
      'In 0-49%, 50-89%, 90-99% oder 100% der Anzeigen sind Durchführung und einwandfreier Informationsgehalt (Liniennummer, Zugziel, nächster Halt) gewährleistet.'
  },
  functionExteriorDoors:
    'Nicht o.K. sind Beschädigung durch Vandalismus, schwergängige bzw. verschlossene Türen, fehlende Scheiben in Außentüren.<br>Wichtig! Die Scheiben der Außentüren bitte unter -Funktion Scheiben- bewerten.',
  functionExteriorShell: 'Nicht ok sind Beulen, Löcher und/oder Risse in der Karosserie.',
  functionFloor: 'Nicht ok sind Unebenheiten (z.B. Löcher, Blasen etc.).',
  functionWindows:
    'Nicht o.K. sind Kratzer, Löcher und/oder Risse etc. Bei verklebten Scheiben und Einfachverglasung (z.B. bei Türen) bitte den kompletten Wagen mit 0% bewerten!<br>Bitte auch die Scheiben der Außentüren prüfen.',
  functionHeating:
    'Bewertung mit 100%, wenn Heizung bzw. Klimaanlage eine angemessene (dem Wagen- und Anlagentyp entsprechende) Belüftung bzw. Temperatur schafft.<br>Bewertung mit 0%, wenn Heizung bzw. Klimaanlage keine angemessene (dem Wagen- und Anlagentyp entsprechende) Belüftung bzw. Temperatur schafft.',
  functionInteriorScaffold:
    'Nicht ok sind Löcher, Risse, Beschädigung durch Vandalismus (z.B. Haltestange fehlt, Scratching etc.).',
  functionLights:
    'Funktionsfähigkeit der Leuchtkörper in Prozent.<br>Hinweise: Sind alle Leuchtkörper defekt wird der komplette Wagen mit 0% bewertet! Ist die Beleuchtung im Sommer (vor Einbruch Dunkelheit) aus, erfolgt keine Bewertung!',
  functionPassageDoors: 'Nicht ok sind schwergängige oder verschlossene Türen, zerkratze Scheiben etc.',
  functionSeats:
    'Nicht ok ist Beschädigung durch Vandalismus (z.B. Brandflecken, aufgeschlitzte oder verschlissene Sitze etc.).',
  functionToilets:
    'Beachte: Bei Vandalismusschäden oder verschlossener Tür der Toilette Bewertung der Funktionalität mit 0%! Die Sauberkeit ist dann nicht bewertbar!<br>Wenn kein WC im Wagen vorhanden ist, dieses Bewertungsfeld frei lassen.',
  functionWasteBins: 'Nicht ok ist Beschädigung durch Vandalismus (z.B. Deckel fehlt, Abfallbehälter fehlt ganz).',
  onboardServiceBehavior:
    'Auftreten und Verhalten:<br>100% = Keine Beanstandungen,<br>75% = Unfreundlich/ hektisch, aber hinreichende Situationsbewältigung,<br>25% = Vereinzelt defizitäre Situationsbewältigung,<br>0% = in der Regel defizitäre Situationsbewältigung oder eskalierendes Handeln.',
  interiorGraffiti: {
    '3er':
      '100% = frei von Graffitis,<br>75% = vereinzelte, kleinflächige Schmierereien,<br>0% = großflächige Schmierereien.',
    '4er':
      '100% = 100% des Fahrzeuginneren frei von Graffiti,<br>90% = 90-99% des Fahrzeuginneren frei von Graffiti,<br>75% = 50-89% des Fahrzeuginneren frei von Graffiti,<br>0% = 0-49% des Fahrzeuginneren frei von Graffiti.'
  },
  securityStaffAppearance:
    'Erscheinungsbild und Ausrüstung:<br>100% = Gepflegtes Erscheinungsbild,<br>50% = Dienstbekleidung oder Ausrüstung unvollständig,<br>0% = Dienstbekleidung und Ausrüstung unvollständig.',
  securityStaffBehavior:
    'Auftreten und Verhalten:<br>100% = Keine Beanstandungen,<br>75% = Unfreundlich/ hektisch, aber hinreichende Situationsbewältigung,<br>25% = Vereinzelt defizitäre Situationsbewältigung,<br>0% = in der Regel defizitäre Situationsbewältigung oder eskalierendes Handeln.',
  securityStaffInactivity:
    'Personal ist mehr als zehn Minuten inaktiv.<br>Inaktivität = keine Ticketkontrolle, Kontrollgänge oder Präsenz-Demonstration im Zug.',
  safetyEndangeringDefects:
    'Prüfung sämtlicher Messgrößen (ausgenommen: Sitze, Abfallbehälter, Wände & Decken, Klimaanlage, Toilette) auf sicherheitsgefährdende Mängel.',
  stationDestinationDisplay:
    'Bei 0-49%, 50-99% oder 100% der Anzeiger sind Durchführung und einwandfreier Informationsgehalt gewährleistet.<br>Einwandfreier Informationsgehalt: Nennung von Abfahrtzeit, Zugziel und ggf. Zwischenhalte, Liniennummer und/oder Linienname.<br>Bemerkung: Doppelseitige Zugzielanzeiger werden als zwei Zugzielanzeiger gezählt!',
  stationTrainAnnouncement:
    'Ja: Ansagen wurden durchgeführt, waren einwandfrei und verständlich.<br>Nein: Ansagen wurden nicht durchgeführt oder waren weder einwandfrei noch verständlich Einwandfreier Informationsgehalt: Gleis, Liniennummer oder Linienname, Zugziel usw.',
  stationDisruptionAnnouncement:
    '(Im Störungsfall) Ja: Zusätzliche Ansagen wurden verständlich und einwandfrei durchgeführt.<br>Nein: Es wurden keine Ansagen für den Störungsfall durchgeführt oder sie waren weder verständlich noch einwandfrei. Einwandfreier Informationsgehalt: Anzahl Verspätungsminuten, Grund der Verspätung, wenn sinnvoll: Durchsage alternativer Fahrmöglichkeiten, Anschlüsse an wichtigen Halten.',
  stationDisruptionDisplay:
    '(Im Störungsfall) Bei 0-49%, 50-99% oder 100% der Anzeiger sind Durchführung, einwandfreier und verständlicher Informationsgehalt gewährleistet.<br>Einwandfreier Informationsgehalt: Anzahl Verspätungsminuten, abweichendes Gleis.<br>Bemerkung: Doppelseitige Zugzielanzeiger werden als zwei Zugzielanzeiger gezählt!',
  trainLabel:
    'Bei 0-49%, 50-99% oder 100% der Beschriftungen sind Durchführung und einwandfreier Informationsgehalt gewährleistet.',
  routeNetworkPlan: {
    '3er':
      'In 0-49%, 50-99% oder 100% der Wagen sind Liniennetzpläne vorhanden, lesbar, aktuell und gültig!<br>Zulässig sind sowohl VRR-Schnellverkehrspläne als auch der Liniennetzplan des Landes NRW.',
    '4er':
      'In 0-49%, 50-89%, 90-99% oder 100%  der Wagen sind Liniennetzpläne vorhanden, lesbar, aktuell und gültig!<br>Zulässig sind sowohl VRR-Schnellverkehrspläne als auch der Liniennetzplan des Landes NRW.'
  },
  speakerAnnouncement: {
    '3er':
      'In 0-49%, 50-99% oder 100% der Durchsagen sind Durchführung, Informationsgehalt und Verständlichkeit gewährleistet.',
    '4er':
      'In 0-49%, 50-89%, 90-99% oder 100% der Durchsagen sind Durchführung, Informationsgehalt und Verständlichkeit gewährleistet.'
  },
  disruptionAnnouncement:
    'Verständliche und einwandfreie Durchführung zusätzlicher Ansagen.<br>Einwandfrei = Anzahl Verspätungsminuten, Grund der Verspätung, wenn sinnvoll: Durchsage alternativer Fahrmöglichkeiten, Anschlüsse an wichtigen Halten.',
  seats: 'Auslastung der Sitzplätze in Prozent.',
  standingRoom: 'Auslastung der Stehplätze in Prozent.',
  vehicleNumber: 'Zu prüfender Wagen.'
};

export { getDevaluationUnitInformation };
