export default {
  rideAssessmentEdit: {
    deleteDialog: {
      title: 'Fahrterfassung wirklich löschen?',
      description: 'Die Fahrterfassung für "{0}" wird unwiderruflich gelöscht.'
    },
    discardDialog: {
      discard: 'verwerfen?',
      title: 'Fahrterfassung wirklich verwerfen?',
      description: 'Die Änderungen der Fahrterfassung für "{0}" werden verworfen.'
    },
    saveDialog: {
      title: 'Fahrterfassung wirklich überschreiben?',
      description: 'Die Fahrterfassung für "{0}" wird überschrieben.',
      descriptionWithQumaHint:
        'Die Fahrterfassung für "{0}" wird überschrieben, muss allerdings auch in QUMA neu importiert werden.'
    },
    wagonTypeDeleteDialog: {
      title: 'Wagentyp wirklich löschen?',
      description: 'Der Wagentyp wird unwiderruflich gelöscht.'
    },
    baseData: 'Grunddaten',
    revisionComment: 'Änderungskommentar',
    unknownLineName: 'Unbekannte Linie',
    wagonType: 'Wagentyp',
    wagonTypeCount: 'Anzahl',
    vehicleNumber: 'Fahrzeugnummer',
    sections: {
      baseData: 'Grunddaten',
      stationInformation: 'Info an Station',
      wagonTypes: 'Behängung',
      wagonState: 'Zustand Wagen',
      conductors: 'Zugbegleiter',
      securityStaff: 'Sicherheitspersonal',
      onboardService: 'Bordservice',
      utilization: 'Auslastung',
      inTrainInformation: 'Info im Zug',
      disruption: 'Störung im Zug',
      comment: 'Bemerkung'
    },
    devaluationUnits: {
      trainNumber: 'Zugnummer',
      departureStation: 'Bahnhof Abfahrt',
      departureTimeNominal: 'SOLL Ankunft ein',
      departureTimeActual: 'IST Ankunft ein',
      arrivalStation: 'Bahnhof Ankunft',
      arrivalTimeNominal: 'SOLL Ankunft aus',
      arrivalTimeActual: 'IST Ankunft aus',
      cancelled: 'Ausfall',
      stationTrainAnnouncement: 'Zugansage',
      stationDisruptionAnnouncement: 'Störansage',
      stationDestinationDisplay: 'Zugzielanzeige',
      stationDisruptionDisplay: 'Anzeige Störungen',
      trainLabel: 'Zugbeschriftung',
      vehicleNumber: 'Fahrzeugnummer',
      cleanlinessExteriorShell: 'Sauberkeit Außenhaut',
      exteriorGraffiti: 'Graffiti außen',
      safetyEndangeringDefects: 'Sicherheitsgefährdende Mängel',
      functionWindows: 'Funktion Scheiben',
      cleanlinessWindows: 'Sauberkeit Scheiben',
      functionPassageDoors: 'Funktion Durchgangstüren',
      cleanlinessPassageDoors: 'Sauberkeit Durchgangstüren',
      functionFloor: 'Funktion Boden',
      cleanlinessFloor: 'Sauberkeit Boden',
      functionSeats: 'Funktion Sitze',
      cleanlinessSeats: 'Sauberkeit Sitze',
      functionWasteBins: 'Funktion Abfallbehälter',
      cleanlinessWasteBins: 'Sauberkeit Abfallbehälter',
      functionInteriorScaffold: 'Funktion Innengerüst',
      cleanlinessInteriorScaffold: 'Sauberkeit Innengerüst',
      interiorGraffiti: 'Graffiti innen',
      functionLights: 'Funktion Beleuchtung',
      functionHeating: 'Funktion Heizung/Klima',
      functionToilets: 'Funktion Toilette',
      cleanlinessToilets: 'Sauberkeit Toilette',
      functionExteriorDoors: 'Funktion Außentür',
      cleanlinessExteriorDoors: 'Sauberkeit Außentür',
      functionExteriorShell: 'Funktion Außenhaut',
      conductorCount: 'Anzahl',
      conductorInactivity: '> 10 min. inaktiv',
      conductorAppearance: 'Erscheinungsbild',
      conductorBehavior: 'Verhalten',
      securityStaffCount: 'Anzahl',
      securityStaffInactivity: '> 10 min. inaktiv',
      securityStaffAppearance: 'Erscheinungsbild',
      securityStaffBehavior: 'Verhalten',
      onboardServiceCount: 'Anzahl',
      onboardServiceBehavior: 'Verhalten',
      seats: 'Auslastung Sitzplätze',
      standingRoom: 'Auslastung Stehplätze',
      routeNetworkPlan: 'Liniennetzplan',
      speakerAnnouncement: 'Lautsprecheransagen',
      passengerInformationSystem: 'Dynamische Haltestellenanzeige',
      disrupted: 'Störung?',
      disruptionAnnouncement: 'Ansage',
      disruptionStartStationId: 'Letzte Station',
      disruptionEndStationId: 'Nächste Station',
      noComment: 'Keine Bemerkungen',
      comment: 'Bemerkungen'
    },
    error: {
      wagonTypes: 'Bitte tragen Sie eine Fahrzeugnummer ein.',
      assessmentComment: 'Bitte setzen Sie eine Bemerkung.'
    }
  }
};
