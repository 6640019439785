import Vue from 'vue';
import App from '@/pwa/App.vue';
import vuetify from '@/shared/plugins/vuetify';
import routes from '@/pwa/plugins/vue-router/routes';
import configureRouter from '@/shared/plugins/vue-router';
import configureAxios from '@/shared/plugins/axios';
import configureAuth from '@/shared/plugins/vue-auth';
import configureApexCharts from '@/shared/plugins/vue-apex-charts';
import configureDatabase from '@/shared/plugins/database';
import configureDebounce from '@/shared/plugins/vue-debounce';
import configureObserveVisiblity from '@/shared/plugins/vue-observe-visibility';
import configureSentry from '@/shared/plugins/sentry';
import { addRouteGuards } from '@/pwa/plugins/vue-router/vue-router';
import i18n from '@/pwa/plugins/vue-i18n';
import store from '@/pwa/plugins/vuex';
import '@/pwa/registerServiceWorker';
import '@/shared/plugins/async-computed';

const router = configureRouter(Vue, routes, false);
addRouteGuards(router, store);
configureAxios(Vue);
configureAuth(Vue, router);
configureSentry(Vue, router);
configureApexCharts(Vue);
configureDatabase(Vue);
configureDebounce(Vue);
configureObserveVisiblity(Vue);

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
