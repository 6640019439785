<template>
  <lottie-player ref="lottieElement" :src="`/assets/${icon}.json`" @ready="emitReadyEvent"></lottie-player>
</template>

<script lang="ts">
import Vue from 'vue';
import '@lottiefiles/lottie-player';

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  methods: {
    emitReadyEvent() {
      this.$emit('ready', this.$refs.lottieElement);
    }
  }
});
</script>

<style scoped lang="scss">
.vue-lottie-player {
  background-color: transparent;
}

::v-deep .lf-spinner {
  border-color: var(--v-primary-base);
  border-right-color: transparent;
}
</style>
