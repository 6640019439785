<template>
  <header class="mt-8 mt-sm-11 mb-9 mb-sm-16">
    <p class="heading primary--text text-uppercase font-weight-bold text-center text-md-start">{{ $t(heading) }}</p>
    <p class="subheading font-weight-bold text-center text-md-start">{{ $t(subheading) }}</p>
  </header>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    heading: {
      type: String,
      default: ''
    },
    subheading: {
      type: String,
      required: true
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

p {
  margin-bottom: 0;
}

.heading {
  font-size: 0.75rem;
  letter-spacing: 0.057em;

  @include sm-and-up {
    font-size: 1rem;
  }
}

.subheading {
  font-size: 1.375rem;
  letter-spacing: 0.035em;

  @include sm-and-up {
    font-size: 1.5rem;
  }
}
</style>
