import {
  AccessiblityDevaluationUnits,
  DynamicPassengerInformationDevaluationUnits,
  QualityOfStayOneDevaluationUnits,
  QualityOfStayTwoDevaluationUnits,
  StaticPassengerInformationDevaluationUnits
} from '@/shared/modules/AssessmentEdit/services/station-assessment-statics';

import type {
  DevaluationBooleanUnit,
  DevaluationConditionUnit,
  DevaluationRangeUnit,
  DevaluationUnit,
  StationAssessment,
  StationAssessmentSectionKey,
  StationAssessmentSectionValidation,
  StationAssessmentStatus,
  StationAssessmentUnitValidation,
  StationAssessmentValidation
} from '@/shared/modules/AssessmentEdit/types/station';
import type { AssessmentValidationUnitStatus } from '@/shared/modules/AssessmentEdit/types';

function validateStationAssessment(stationAssessment: StationAssessment): {
  status: StationAssessmentStatus;
  validation: StationAssessmentValidation;
} {
  const validation = {
    qualityOfStayOne: validateQualityOfStayOne(stationAssessment),
    qualityOfStayTwo: validateQualityOfStayTwo(stationAssessment),
    passengerInformation: validatePassengerInformation(stationAssessment),
    accessibility: validateAccessibility(stationAssessment)
  };
  const status =
    validation.qualityOfStayOne.status === 'complete' &&
    validation.qualityOfStayTwo.status === 'complete' &&
    validation.passengerInformation.status === 'complete' &&
    validation.accessibility.status === 'complete'
      ? 'complete'
      : 'incomplete';

  return {
    status,
    validation
  };
}

function validateQualityOfStayOne(assessment: StationAssessment): StationAssessmentSectionValidation {
  let containsInitialDevaluationUnit = false;
  let containsIncompleteDevaluationUnit = false;
  const validationResult: StationAssessmentSectionValidation = {
    status: 'initial',
    units: {}
  };

  QualityOfStayOneDevaluationUnits.forEach(unitKey => {
    const devaluationUnit = assessment.qualityOfStay[unitKey];
    if (devaluationUnit === undefined) {
      return;
    }
    const devaluationUnitStatus = validateDevaluationUnit(devaluationUnit);

    if (devaluationUnitStatus.status === 'initial') {
      containsInitialDevaluationUnit = true;
    } else if (devaluationUnitStatus.status === 'incomplete') {
      containsIncompleteDevaluationUnit = true;
    }
    validationResult.units[unitKey] = devaluationUnitStatus;
  });

  if (assessment.sections.qualityOfStayOne.visited === false) {
    validationResult.units = removeInitialErrorMessages(validationResult.units);
  }

  validationResult.status = getSectionAggregateStatus(
    containsIncompleteDevaluationUnit,
    containsInitialDevaluationUnit,
    assessment.sections.qualityOfStayOne.visited
  );

  return validationResult;
}

function validateQualityOfStayTwo(assessment: StationAssessment): StationAssessmentSectionValidation {
  let containsInitialDevaluationUnit = false;
  let containsIncompleteDevaluationUnit = false;
  const validationResult: StationAssessmentSectionValidation = {
    status: 'initial',
    units: {}
  };

  QualityOfStayTwoDevaluationUnits.forEach(unitKey => {
    const devaluationUnit = assessment.qualityOfStay[unitKey];
    if (devaluationUnit === undefined) {
      return;
    }
    const devaluationUnitStatus = validateDevaluationUnit(devaluationUnit);

    if (devaluationUnitStatus.status === 'initial') {
      containsInitialDevaluationUnit = true;
    } else if (devaluationUnitStatus.status === 'incomplete') {
      containsIncompleteDevaluationUnit = true;
    }
    validationResult.units[unitKey] = devaluationUnitStatus;
  });

  if (assessment.sections.qualityOfStayTwo.visited === false) {
    validationResult.units = removeInitialErrorMessages(validationResult.units);
  }

  validationResult.status = getSectionAggregateStatus(
    containsIncompleteDevaluationUnit,
    containsInitialDevaluationUnit,
    assessment.sections.qualityOfStayTwo.visited
  );

  return validationResult;
}

function validatePassengerInformation(assessment: StationAssessment): StationAssessmentSectionValidation {
  let containsInitialDevaluationUnit = false;
  let containsIncompleteDevaluationUnit = false;
  const validationResult: StationAssessmentSectionValidation = {
    status: 'initial',
    units: {}
  };

  [...StaticPassengerInformationDevaluationUnits, ...DynamicPassengerInformationDevaluationUnits].forEach(unitKey => {
    const devaluationUnit = assessment.passengerInformation[unitKey];
    if (devaluationUnit === undefined) {
      return;
    }
    const devaluationUnitStatus = validateDevaluationUnit(devaluationUnit);

    if (devaluationUnitStatus.status === 'initial') {
      containsInitialDevaluationUnit = true;
    } else if (devaluationUnitStatus.status === 'incomplete') {
      containsIncompleteDevaluationUnit = true;
    }
    validationResult.units[unitKey] = devaluationUnitStatus;
  });

  if (assessment.sections.passengerInformation.visited === false) {
    validationResult.units = removeInitialErrorMessages(validationResult.units);
  }

  validationResult.status = getSectionAggregateStatus(
    containsIncompleteDevaluationUnit,
    containsInitialDevaluationUnit,
    assessment.sections.passengerInformation.visited
  );

  return validationResult;
}

function validateAccessibility(assessment: StationAssessment): StationAssessmentSectionValidation {
  let containsInitialDevaluationUnit = false;
  let containsIncompleteDevaluationUnit = false;
  const validationResult: StationAssessmentSectionValidation = {
    status: 'initial',
    units: {}
  };

  AccessiblityDevaluationUnits.forEach(unitKey => {
    const devaluationUnit = assessment.accessibility[unitKey];
    if (devaluationUnit === undefined) {
      return;
    }
    const devaluationUnitStatus = validateDevaluationUnit(devaluationUnit);

    if (devaluationUnitStatus.status === 'initial') {
      containsInitialDevaluationUnit = true;
    } else if (devaluationUnitStatus.status === 'incomplete') {
      containsIncompleteDevaluationUnit = true;
    }
    validationResult.units[unitKey] = devaluationUnitStatus;
  });

  if (assessment.sections.accessibility.visited === false) {
    validationResult.units = removeInitialErrorMessages(validationResult.units);
  }

  validationResult.status = getSectionAggregateStatus(
    containsIncompleteDevaluationUnit,
    containsInitialDevaluationUnit,
    assessment.sections.accessibility.visited
  );

  return validationResult;
}

function getSectionAggregateStatus(
  containsIncompleteDevaluationUnit: boolean,
  containsInitialDevaluationUnit: boolean,
  sectionVisited: boolean
): AssessmentValidationUnitStatus {
  if (containsIncompleteDevaluationUnit || (containsInitialDevaluationUnit && sectionVisited)) {
    return 'incomplete';
  } else if (containsInitialDevaluationUnit) {
    return 'initial';
  } else {
    return 'complete';
  }
}

function removeInitialErrorMessages(units: StationAssessmentSectionValidation['units']) {
  return Object.fromEntries(
    Object.entries(units).map(([key, value]) => {
      return value.status === 'initial' ? [key, { ...value, errorMessage: undefined }] : [key, value];
    })
  );
}

function validateDevaluationUnit(unit: DevaluationUnit): StationAssessmentUnitValidation {
  switch (unit.type) {
    case 'range':
      return validateRangeDevaluationUnit(unit);
    case 'condition':
      return validateConditionDevaluationUnit(unit);
    case 'boolean':
      return validateBooleanDevaluationUnit(unit);
    default:
      return { status: 'initial' };
  }
}

function validateRangeDevaluationUnit(unit: DevaluationRangeUnit): StationAssessmentUnitValidation {
  const { images, note, value } = unit;
  if (value === undefined && note === '' && images.length === 0) {
    return { status: 'initial', errorMessage: 'missingValue' };
  }

  // missing value
  if (value === undefined) {
    return { status: 'incomplete', errorMessage: 'missingValue' };
  }

  // missing note
  if (value === 'veryNegative' && note === '') {
    return { status: 'incomplete', errorMessage: 'missingNote' };
  }

  // missing image
  if (value === 'veryNegative' && images.length === 0) {
    return { status: 'incomplete', errorMessage: 'missingImage' };
  }

  return { status: 'complete' };
}

function validateConditionDevaluationUnit(unit: DevaluationConditionUnit): StationAssessmentUnitValidation {
  const { images, note, value } = unit;
  if (value === undefined && note === '' && images.length === 0) {
    return { status: 'initial', errorMessage: 'missingValue' };
  }

  // missing value
  if (value === undefined || value === 'exists') {
    return { status: 'incomplete', errorMessage: 'missingValue' };
  }

  return { status: 'complete' };
}

function validateBooleanDevaluationUnit(unit: DevaluationBooleanUnit): StationAssessmentUnitValidation {
  const { value } = unit;

  if (value === undefined) {
    return { status: 'initial', errorMessage: 'missingValue' };
  }

  return { status: 'complete' };
}

function getQualityOfStayStatus(
  qualityOfStayOneStatus?: AssessmentValidationUnitStatus,
  qualityOfStayTwoStatus?: AssessmentValidationUnitStatus
): AssessmentValidationUnitStatus {
  if (qualityOfStayOneStatus === undefined || qualityOfStayTwoStatus === undefined) {
    return 'initial';
  }

  if (qualityOfStayOneStatus === 'incomplete' || qualityOfStayTwoStatus === 'incomplete') {
    return 'incomplete';
  }

  if (qualityOfStayOneStatus === 'initial' || qualityOfStayTwoStatus === 'initial') {
    return 'initial';
  }

  return 'complete';
}

function getNoteErrorMessage(
  validation: StationAssessmentValidation | undefined,
  sectionKey: StationAssessmentSectionKey
) {
  return validation?.[sectionKey].noteErrorMessage ?? '';
}

export { validateStationAssessment, getQualityOfStayStatus, getNoteErrorMessage };
