<template>
  <div :class="{ opened }" class="burger-menu-icon" data-test="icon">
    <span :class="color"></span>
    <span :class="color"></span>
    <span :class="color"></span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { BurgerMenuIconProps } from '@/shared/modules/Layout/types';

export default Vue.extend<unknown, unknown, unknown, BurgerMenuIconProps>({
  props: {
    opened: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: 'white',
      validator: (v: string) => ['white', 'primary'].includes(v)
    }
  }
});
</script>

<style lang="scss" scoped>
.burger-menu-icon {
  width: 24px;
  height: 21px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  position: relative;

  span {
    display: block;
    height: 3px;
    opacity: 1;
    border-radius: 2px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      width: 70%;
      transform-origin: left center;
      top: 0;
    }

    &:nth-child(2) {
      width: 100%;
      transform-origin: left center;
      top: 8px;
    }

    &:nth-child(3) {
      width: 60%;
      transform-origin: left center;
      top: 16px;
    }
  }

  &.opened {
    span {
      &:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        left: 3px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 17px;
        left: 3px;
      }
    }
  }
}
</style>
