<template>
  <v-container class="pt-0 px-6" v-if="stationAssessment">
    <section-heading
      heading="stationAssessmentEdit.sections.missingFeatures"
      subheading="stationAssessmentEdit.sections.forDisabled"
    />
    <div class="form-icon-grid">
      <div class="form-grid">
        <devaluation-input
          v-for="devaluationUnit in accessibilityDevaluationUnits"
          :key="devaluationUnit.key"
          v-bind="devaluationUnit"
        >
          <boolean-option v-model="stationAssessment.accessibility[devaluationUnit.key].value" :disabled="readOnly" />
        </devaluation-input>
        <devaluation-input
          title="stationAssessmentEdit.sectionNotes.accessibility"
          :error-message="getSectionNoteErrorMessage()"
          :has-comment="hasComment(stationAssessment.accessibility.note, stationAssessment.accessibility.images)"
          @open-modal="openCameraAndNotesModal('accessibility')"
        >
          <section-note-input v-model="stationAssessment.accessibility.note" />
        </devaluation-input>
      </div>
      <circle-icon class="form-icon-grid__icon" icon="person-wheelchair" autoplay loop intermission="10000" />
    </div>
    <assessment-overview-button :complete="accessibilityComplete" to-route-name="station-assessment" />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalDevaluationUnit"
      :devaluation-unit-name="cameraAndNotesModalDevaluationUnit"
      :icon="getImage(cameraAndNotesModalDevaluationUnit)"
      :title="cameraAndNotesModalTitle"
      :images="cameraAndNotesModalImages"
      :note="cameraAndNotesModalNote"
      :read-only="readOnly"
      :download-title="cameraAndNotesDownloadTitle"
      :no-note-character-limit="true"
      @add-images="addImages"
      @add-note="addNote"
      @closed="() => this.setCameraAndNotesModalDevaluationUnit(null)"
      @delete-image="deleteImage"
    />
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import SectionNoteInput from '@/shared/modules/AssessmentEdit/components/SectionNoteInput.vue';
import BooleanOption from '@/shared/modules/AssessmentEdit/components/BooleanOption.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CircleIcon from '@/shared/modules/AssessmentEdit/components/CircleIcon.vue';
import {
  DevaluationUnitImages,
  AccessiblityDevaluationUnits
} from '@/shared/modules/AssessmentEdit/services/station-assessment-statics';
import { getNoteErrorMessage } from '@/shared/modules/AssessmentEdit/services/station-assessment-validation';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';

import type {
  AccessibilityDevaluationUnitKey,
  CameraAndNotesModalDevaluationUnit,
  DevaluationUnitInputs,
  StationAssessmentEditState
} from '@/shared/modules/AssessmentEdit/types/station';
import { STATION_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/station-assessment-edit';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/station-assessment-service';

type VuexBindings = {
  stationAssessment: StationAssessmentEditState['stationAssessment'];
  assessmentValidation: StationAssessmentEditState['assessmentValidation'];
  cameraAndNotesModalDevaluationUnit: StationAssessmentEditState['cameraAndNotesModalDevaluationUnit'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    SectionHeading,
    SectionNoteInput,
    BooleanOption,
    DevaluationInput,
    CameraAndNotesModal,
    AssessmentOverviewButton,
    CircleIcon
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'stationAssessment',
      'assessmentValidation',
      'readOnly',
      'cameraAndNotesModalDevaluationUnit'
    ]),
    accessibilityDevaluationUnits(): DevaluationUnitInputs<typeof AccessiblityDevaluationUnits> {
      return AccessiblityDevaluationUnits.map(unitKey => {
        return {
          key: unitKey,
          title: `stationAssessmentEdit.devaluationUnits.${unitKey}`,
          icon: this.getImage(unitKey),
          errorMessage: this.getErrorMessage(unitKey),
          showComment: false
        };
      });
    },
    accessibilityComplete(): boolean {
      return this.assessmentValidation?.accessibility.status === 'complete';
    },
    cameraAndNotesModalTitle(): string {
      if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
        return '';
      }

      if ('accessibility' === this.cameraAndNotesModalDevaluationUnit) {
        return `stationAssessmentEdit.sectionNotes.${this.cameraAndNotesModalDevaluationUnit}`;
      }

      return `stationAssessmentEdit.devaluationUnits.${this.cameraAndNotesModalDevaluationUnit}`;
    },
    cameraAndNotesModalNote(): string {
      if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
        return '';
      }

      if ('accessibility' === this.cameraAndNotesModalDevaluationUnit) {
        return this.stationAssessment.accessibility.note;
      }

      // @ts-ignore
      return this.stationAssessment.accessibility[this.cameraAndNotesModalDevaluationUnit].note;
    },
    cameraAndNotesDownloadTitle(): string {
      return getImageDownloadTitle(
        this.stationAssessment?.stationName ?? '',
        this.stationAssessment?.assessmentDateTime ?? '',
        this.$t(this.cameraAndNotesModalTitle).toString()
      );
    }
  },
  asyncComputed: {
    cameraAndNotesModalImages: {
      get(): Promise<string[]> {
        if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
          return Promise.resolve([]);
        }

        if ('accessibility' === this.cameraAndNotesModalDevaluationUnit) {
          return getImages(this.stationAssessment.accessibility.images);
        }

        // @ts-ignore
        return getImages(this.stationAssessment.accessibility[this.cameraAndNotesModalDevaluationUnit].images);
      },
      default: []
    }
  },
  methods: {
    ...mapMutations(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'setStationAssessmentSectionVisited',
      'setCameraAndNotesModalDevaluationUnit'
    ]),
    ...mapActions(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'addStationAssessmentImages',
      'addStationAssessmentNote',
      'deleteStationAssessmentImage'
    ]),
    getImage(devaluationUnit: AccessibilityDevaluationUnitKey) {
      return DevaluationUnitImages[devaluationUnit];
    },
    getErrorMessage(devaluationUnit: AccessibilityDevaluationUnitKey) {
      return this.assessmentValidation?.accessibility.units[devaluationUnit]?.errorMessage ?? '';
    },
    hasComment(note: string | undefined, images: string[] | undefined): boolean {
      return note !== '' || images?.length !== 0;
    },
    getSectionNoteErrorMessage() {
      return getNoteErrorMessage(this.assessmentValidation, 'accessibility');
    },
    setSectionVisited() {
      this.setStationAssessmentSectionVisited('accessibility');
    },
    openCameraAndNotesModal(unit: CameraAndNotesModalDevaluationUnit) {
      this.setCameraAndNotesModalDevaluationUnit(unit);
    },
    async addImages(event: Event) {
      const images = await getImagesFromFileList(event);
      await this.addStationAssessmentImages({ images, category: 'accessibility' });
    },
    addNote(note: string) {
      this.addStationAssessmentNote({ note, category: 'accessibility' });
    },
    deleteImage(imageIndex: number) {
      this.deleteStationAssessmentImage({ imageIndex, category: 'accessibility' });
    }
  },
  created() {
    window.addEventListener('beforeunload', this.setSectionVisited);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.setSectionVisited);
  },
  beforeRouteLeave(to, from, next) {
    this.setSectionVisited();
    next();
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-icon-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 0;

  &__icon {
    justify-self: normal;
    grid-column: 2 / 3;
    max-width: 300px;
  }

  @include xl-only {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);

    &__icon {
      justify-self: center;
    }
  }
}

.form-grid {
  @include dynamic-grid(40px, 600px, 1);

  @include md-and-up {
    row-gap: 40px;
    justify-content: start;
  }
}
</style>
