<template>
  <div>
    <div class="d-none d-sm-block">
      <h1 class="text-h1 font-weight-bold primary--text">
        {{ $t('login.title') }}
      </h1>
      <h2 class="subtitle text-subtitle-1 darkgrey--text text-uppercase">
        {{ $t('login.subtitle') }}
      </h2>
    </div>
    <div class="d-sm-none font-weight-bold">
      <h1 class="phone-title primary--text text-uppercase">{{ $t('login.phoneTitle') }}</h1>
      <h2 class="phone-subtitle darkgrey--text">{{ $t('login.phoneSubtitle') }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend<unknown, unknown, unknown, unknown>({});
</script>

<style lang="scss" scoped>
.subtitle {
  font-family: Lato, sans-serif !important;
}

.phone-title {
  font-size: 0.75rem;
  letter-spacing: 0.0575em;
  line-height: 1.25;
}

.phone-subtitle {
  font-size: 1.25rem;
  letter-spacing: 0.0375em;
  line-height: 1.5;
}
</style>
