<template>
  <v-menu v-model="menu" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" class="py-6" data-test="account" text>
        <v-icon color="black" class="mr-3" small>fal fa-chevron-down</v-icon>
        <div class="text-left text-none">
          <div class="black--text">{{ fullName }}</div>
          <div class="grey--text">{{ email }}</div>
        </div>
      </v-btn>
    </template>

    <v-card data-test="menu">
      <v-list class="py-0">
        <v-list-item to="/account/password-change">
          <v-list-item-action>
            <v-icon color="primary" class="ml-2">far fa-key</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('layout.passwordChange') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('logout')">
          <v-list-item-action>
            <v-icon color="primary" class="ml-2">far fa-sign-out</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('layout.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import { AccountMenuData, AccountMenuProps } from '@/shared/modules/Layout/types';

export default Vue.extend<AccountMenuData, unknown, unknown, AccountMenuProps>({
  props: {
    fullName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false
    };
  }
});
</script>
