<template>
  <div class="condition-option" data-test="condition-option">
    <v-btn-toggle
      :value="exists"
      group
      class="condition-option__buttons d-flex justify-space-between"
      :class="{ 'condition-option__buttons--selected': exists !== undefined }"
      @change="updateExists"
    >
      <v-btn
        v-for="option in existOptions"
        :key="option.value"
        :value="option.value"
        :ripple="false"
        :disabled="disabled"
        max-height="34"
        class="condition-option__button ma-0 font-weight-medium"
      >
        {{ $t(option.label) }}
      </v-btn>
    </v-btn-toggle>
    <v-btn-toggle
      :value="condition"
      :class="{ 'condition-option__buttons--selected': condition !== undefined }"
      class="condition-option__buttons d-flex justify-space-between mt-2"
      group
      @change="updateCondition"
    >
      <v-btn
        v-for="option in conditionOptions"
        :disabled="exists !== 'exists' || disabled"
        :key="option.value"
        :value="option.value"
        :ripple="false"
        max-height="34"
        class="condition-option__button ma-0 font-weight-medium"
      >
        {{ $t(option.label) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { DevaluationConditionOption } from '@/shared/modules/AssessmentEdit/types/station';

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<DevaluationConditionOption>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      existOptions: [
        {
          value: 'exists',
          label: 'stationAssessmentEdit.featureCondition.exists'
        },
        {
          value: 'doesNotExist',
          label: 'stationAssessmentEdit.featureCondition.doesNotExist'
        }
      ] as { value: Extract<DevaluationConditionOption, 'exists' | 'doesNotExist'>; label: string }[],
      conditionOptions: [
        {
          value: 'ok',
          label: 'stationAssessmentEdit.featureCondition.conditionOk'
        },
        {
          value: 'damaged',
          label: 'stationAssessmentEdit.featureCondition.conditionDamaged'
        }
      ] as { value: Extract<DevaluationConditionOption, 'ok' | 'damaged'>; label: string }[]
    };
  },
  computed: {
    exists(): Extract<DevaluationConditionOption, 'exists' | 'doesNotExist'> | undefined {
      return this.value === undefined ? undefined : this.value === 'doesNotExist' ? 'doesNotExist' : 'exists';
    },
    condition(): Extract<DevaluationConditionOption, 'ok' | 'damaged'> | undefined {
      return this.value !== 'ok' && this.value !== 'damaged' ? undefined : this.value;
    }
  },
  methods: {
    updateExists(newExists?: Extract<DevaluationConditionOption, 'exists' | 'doesNotExist'>) {
      this.emitInput(newExists === undefined ? undefined : newExists);
    },
    updateCondition(newCondition?: Extract<DevaluationConditionOption, 'ok' | 'damaged'>) {
      this.emitInput(newCondition === undefined ? 'exists' : newCondition);
    },
    emitInput(updatedConditionOption?: DevaluationConditionOption) {
      this.$emit('input', updatedConditionOption);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';
@import '@/shared/styles/media.scss';

.condition-option {
  max-width: 300px;

  &__buttons {
    gap: 8px;

    & > .condition-option__button.v-btn:where(:not(.v-btn--disabled)) {
      opacity: 1;
    }

    &--selected > .condition-option__button.v-btn {
      opacity: 0.3;
    }
  }

  &__button {
    --border-color: var(--v-darkgrey-darken1);

    border-width: 1px !important;
    border-style: solid;
    border-color: var(--border-color) !important;
    border-radius: 5px !important;

    letter-spacing: 0;
    text-transform: none;
    transition-property: box-shadow, transform, opacity, border-color, background-color;

    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 56%;
    }

    &.v-btn.v-btn--active {
      --border-color: var(--v-primary-base);
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base);
      opacity: 1;

      &.v-btn--disabled {
        color: var(--v-white-darken2) !important;
      }
    }

    &.v-btn.v-btn--disabled {
      --border-color: transparent;
      cursor: not-allowed;
      pointer-events: auto;
    }

    &::before {
      display: none;
    }
  }
}
</style>
