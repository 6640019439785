import Vue from 'vue';
import Vuetify, { Ripple } from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import '@fortawesome/fontawesome-pro/css/all.css';

import IconWrapper from '@/shared/modules/Global/components/IconWrapper.vue';

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007E32',
        secondary: '#55AB26',
        accent: '#55AB26',
        error: '#FA585E',
        info: '#0297BC',
        success: '#007E32',
        warning: '#FFCC00',
        orange: '#FF7E00',
        darkgrey: '#4D4B4A',
        lightgrey: '#B6B5B5',
        white: '#FFFFFF',
        black: '#000000'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'fa',
    values: {
      clear: 'fal fa-times',
      offline: {
        component: IconWrapper,
        props: { name: 'signal' }
      },
      trainStationWhite: {
        component: IconWrapper,
        props: { name: 'trainStationWhite' }
      },
      trainStationGreen: {
        component: IconWrapper,
        props: { name: 'trainStationGreen' }
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
