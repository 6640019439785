import { render, staticRenderFns } from "./AssessmentComment.vue?vue&type=template&id=e1b5e0be&scoped=true"
import script from "./AssessmentComment.vue?vue&type=script&lang=ts"
export * from "./AssessmentComment.vue?vue&type=script&lang=ts"
import style0 from "./AssessmentComment.vue?vue&type=style&index=0&id=e1b5e0be&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1b5e0be",
  null
  
)

export default component.exports