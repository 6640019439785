<template>
  <v-card outlined>
    <v-card-text>
      <div v-if="initial" class="d-flex justify-center">
        <v-btn color="primary" data-test="button-add-wagon-type" fab @click="initial = false">
          <v-icon>far fa-plus</v-icon>
        </v-btn>
      </div>
      <div v-else>
        <button-toggle v-model="selectWagonType" :input-options="inputOptions" data-test="toggle-input-option" />
        <wagon-type-select
          v-if="selectWagonType === true"
          :value="selectedWagonTypeId"
          :wagon-types="wagonTypes"
          :hide-details="true"
          @input="emitWagonTypeWithId"
        />
        <dialog-select
          v-if="selectWagonType === false"
          :value="selectedVehicleNumber"
          :items="lineVehicles"
          :label="$t('rideAssessmentEdit.vehicleNumber')"
          item-text="name"
          item-value="name"
          data-test="vehicle-number-select"
          hide-details
          @input="emitWagonTypeWithVehicle"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ButtonToggle from '@/shared/modules/AssessmentEdit/components/ButtonToggle.vue';
import { AssessmentWagonType, ListWagonType } from '@/shared/modules/AssessmentEdit/types/ride';
import { Vehicle } from '@/shared/plugins/database/profitester';
import WagonTypeSelect from '@/shared/modules/AssessmentEdit/components/WagonTypeSelect.vue';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

export default Vue.extend({
  components: {
    DialogSelect,
    ButtonToggle,
    WagonTypeSelect
  },
  props: {
    wagonTypes: {
      type: Array as PropType<ListWagonType[]>,
      default: () => []
    },
    lineVehicles: {
      type: Array as PropType<Vehicle[]>,
      default: () => []
    }
  },
  data() {
    return {
      initial: true,
      selectWagonType: undefined as boolean | undefined,
      selectedWagonTypeId: undefined,
      selectedVehicleNumber: undefined,
      inputOptions: [
        {
          label: this.$t('rideAssessmentEdit.wagonType'),
          value: true
        },
        {
          label: this.$t('rideAssessmentEdit.vehicleNumber'),
          value: false
        }
      ]
    };
  },
  methods: {
    resetForm() {
      this.initial = true;
      this.selectWagonType = undefined;
      this.selectedWagonTypeId = undefined;
      this.selectedVehicleNumber = undefined;
    },
    emitWagonTypeWithId(wagonTypeId: string) {
      const selectedWagonType = this.wagonTypes.find(wagonType => wagonType.id === wagonTypeId);
      if (!selectedWagonType) {
        return;
      }
      const wagonType: AssessmentWagonType = {
        wagonTypeId: selectedWagonType.id,
        wagonTypeExternalId: selectedWagonType.externalId,
        wagonTypeName: selectedWagonType.name,
        count: 1,
        vehicleNumbers: [''],
        note: '',
        imageIds: []
      };
      this.$emit('input', wagonType);
      this.resetForm();
    },
    emitWagonTypeWithVehicle(vehicleNumber: string) {
      const wagonTypeId = this.lineVehicles.find(vehicle => vehicle.name === vehicleNumber)?.wagonTypeId;
      if (!wagonTypeId) {
        return;
      }
      const selectedWagonType = this.wagonTypes.find(wagonType => wagonType.id === wagonTypeId);
      if (!selectedWagonType) {
        return;
      }
      const wagonType: AssessmentWagonType = {
        wagonTypeId,
        wagonTypeExternalId: selectedWagonType.externalId,
        wagonTypeName: selectedWagonType.name,
        count: 1,
        vehicleNumbers: [vehicleNumber],
        note: '',
        imageIds: []
      };
      this.$emit('input', wagonType);
      this.resetForm();
    }
  }
});
</script>
