import Vue from 'vue';
import VueI18n from 'vue-i18n';
import layoutDe from '@/shared/modules/Layout/translations/de';
import assessmentEditDe from '@/shared/modules/AssessmentEdit/translations/de';
import assessmentEditStationDe from '@/shared/modules/AssessmentEdit/translations/station-de';
import assessmentEditRideDe from '@/shared/modules/AssessmentEdit/translations/ride-de';
import dateTimeFormats from '@/shared/plugins/vue-i18n-datetime';
import loginDe from '@/pwa/modules/Login/translations/de';
import accountDe from '@/pwa/modules/Account/translations/de';
import assessmentDe from '@/pwa/modules/Assessment/translations/de';
import assessmentStationDe from '@/pwa/modules/Assessment/translations/station-de';
import assessmentRideDe from '@/pwa/modules/Assessment/translations/ride-de';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'de',
  messages: {
    de: {
      ...layoutDe,
      ...assessmentEditDe,
      ...assessmentEditStationDe,
      ...assessmentEditRideDe,
      ...loginDe,
      ...accountDe,
      ...assessmentDe,
      ...assessmentStationDe,
      ...assessmentRideDe
    }
  },
  dateTimeFormats: {
    de: {
      ...dateTimeFormats
    }
  }
});
