<template>
  <v-form @submit.prevent="$emit('submit')" data-test="form">
    <v-alert
      v-if="hasError"
      border="left"
      type="error"
      color="red"
      icon="fal fa-exclamation-circle"
      class="mb-8"
      data-test="alert"
      dense
      text
      >{{ $t('login.badCredentials') }}</v-alert
    >
    <v-text-field
      v-model="email"
      :label="$t('login.email')"
      data-test="email"
      autofocus
      required
      autocomplete="email"
    />
    <v-text-field
      type="password"
      v-model="password"
      :label="$t('login.password')"
      data-test="password"
      required
      autocomplete="current-password"
    />
    <div class="text-center">
      <v-btn
        :loading="isLoading"
        type="submit"
        color="primary"
        border="left"
        class="mt-6"
        data-test="submit"
        depressed
        block
      >
        {{ $t('login.submit') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoginFormProps, LoginFormComputed } from '@/pwa/modules/Login/types';

export default Vue.extend<unknown, unknown, LoginFormComputed, LoginFormProps>({
  props: {
    value: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    hasError: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    email: {
      get(): string {
        return this.value.email;
      },
      set(email: string): void {
        this.$emit('input', { ...this.value, email });
      }
    },
    password: {
      get(): string {
        return this.value.password;
      },
      set(password: string): void {
        this.$emit('input', { ...this.value, password });
      }
    }
  }
});
</script>
