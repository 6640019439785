export default {
  stationAssessment: {
    statistics: {
      heading: 'Statistik für {quarter}/{year}',
      headingShort: 'Statistik',
      daysUntil: 'Tag bis | Tage bis',
      endOfQuarter: 'Quartalsende',
      unassessed: 'offener | offene',
      stations: 'Haltepunkt | Haltepunkte',
      lastUpdatedAt: 'Stand vom {date}'
    },
    stationNameOrCode: 'Haltestelle | DS100',
    drafts: 'Entwürfe',
    transmitted: 'Übertragene',
    statuses: {
      complete: 'vollständig',
      incomplete: 'unvollständig'
    },
    tableHeaders: {
      stationName: 'Haltestelle',
      stationCode: 'DS100',
      assessmentDateTime: 'Erfassungszeitpunkt',
      exportDateTime: 'Übertragungszeitpunkt',
      status: 'Status'
    },
    actions: {
      createAssessment: 'Neue Erfassung',
      transmitAssessment: 'Erfassung übertragen',
      transmitAssessmentShort: 'übertragen',
      deleteAssessment: 'Erfassung löschen'
    },
    transmitDialog: {
      title: 'Stationserfassung übertragen?',
      description: 'Die Stationserfassung für "{0}" wird übertragen und kann danach nicht mehr bearbeitet werden.'
    },
    deleteDialog: {
      title: 'Erfassung wirklich löschen?',
      description: 'Die Stationserfassung für "{0}" wird unwiderruflich gelöscht.'
    },
    snackbar: {
      networkError: 'Netzwerkfehler',
      stationReportsError: 'Die Erfassungsstatistik konnte nicht geladen werden.',
      assessmentsListError: 'Die bereits erfassten Stationen konnten nicht geladen werden.',
      assessmentTransmissionError: {
        heading: 'Übertragungsfehler',
        default: 'Die Erfassung konnte nicht übertragen werden.',
        31: 'Dieser Haltepunkt wurde bereits erfasst.'
      },
      assessmentTransmissionSuccess: 'Übertragung erfolgreich',
      stationAssessmentComplete: 'Erfassung vollständig'
    }
  }
};
