<template>
  <v-bottom-navigation
    :input-value="showBottomNavigation"
    class="bottom-navigation"
    color="primary"
    data-test="bottom-navigation"
    grow
    app
  >
    <v-btn
      v-for="{ name, icon, iconSize, label } in navigationItems"
      :key="name"
      :to="{ name }"
      class="bottom-navigation__button"
    >
      <span class="font-weight-bold">{{ label }}</span>
      <v-icon :size="iconSize">{{ icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { RouteConfig } from 'vue-router';

export default Vue.extend({
  props: {
    subMenu: {
      type: Array as PropType<RouteConfig[] | null>,
      default: null
    }
  },
  data() {
    return {
      routeIcons: new Map([
        ['station-assessment-list', { icon: '$trainStationGreen', iconSize: 32 }],
        ['ride-assessment-list', { icon: 'far fa-train' }]
      ])
    };
  },
  computed: {
    showBottomNavigation(): boolean {
      return !!this.subMenu?.length;
    },
    navigationItems(): { name?: string; icon?: string; iconSize?: number; label?: string }[] | undefined {
      return this.subMenu?.map(route => {
        return {
          name: route.name,
          icon: this.routeIcons.get(route.name ?? '')?.icon,
          iconSize: this.routeIcons.get(route.name ?? '')?.iconSize,
          label: route.meta?.title
        };
      });
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.v-btn:not(.v-btn--active) .v-icon ::v-deep .v-image {
  filter: $filter-gray;
}

.bottom-navigation {
  padding-bottom: env(safe-area-inset-bottom) !important;
  height: calc(56px + env(safe-area-inset-bottom)) !important;

  &__button {
    height: 56px !important;
  }
}
</style>
