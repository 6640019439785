<template>
  <v-btn-toggle
    :value="value"
    class="range-option d-flex justify-space-between"
    :class="{ 'range-option--selected': value !== undefined }"
    data-test="range-option"
    group
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="disabled"
      max-height="36"
      class="range-option__button ma-0 font-weight-medium"
      :class="`range-option__button--${option.color}`"
    >
      {{ option.label }}
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { DevaluationRangeOption } from '@/shared/modules/AssessmentEdit/types/station';

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<DevaluationRangeOption | undefined>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [
        {
          value: 'positive',
          label: '+',
          color: 'blue'
        },
        {
          value: 'neutral',
          label: '0',
          color: 'green'
        },
        {
          value: 'negative',
          label: '–',
          color: 'yellow'
        },
        {
          value: 'veryNegative',
          label: '––',
          color: 'red'
        }
      ] as { value: DevaluationRangeOption; label: string; color: string }[]
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.range-option {
  max-width: 300px;
  gap: 12px;

  & > .range-option__button.v-btn {
    opacity: 1;
  }

  &--selected > .range-option__button.v-btn {
    opacity: 0.3;
  }

  &__button {
    --border-color: var(--v-darkgrey-darken1);
    --border-width: 1px;

    border-width: var(--border-width) !important;
    border-style: solid;
    border-color: var(--border-color) !important;
    border-radius: 8px !important;
    flex: 0 1 63px;

    transition-property: box-shadow, transform, opacity, border-color;

    &.v-btn.v-btn--active {
      --border-color: var(--border-color-active);
      --border-width: 2px;
      opacity: 1;
    }

    &.v-btn.v-btn--disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }

    &--blue {
      --border-color-active: #{$blue};
    }

    &--green {
      --border-color-active: var(--v-primary-base);
    }

    &--yellow {
      --border-color-active: #{$yellow};
    }

    &--red {
      --border-color-active: var(--v-error-base);
    }

    &::before {
      display: none;
    }

    & ::v-deep .v-ripple__container {
      border-radius: 6px;
    }
  }
}
</style>
