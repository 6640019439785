/** 
  This mixin allows components like dialogs to react to navigation events.
  This can be used to close a dialog when the user navigates back.

  A dialog using this mixin should be shown and hidden with v-if so that the
  router hook gets unregistered and doesn't prevent other navigations.

  Inspired by https://gist.github.com/tamirvs/d1a584f3fc9c494cf75d3ca76c54fb1b
  This is solved in Vuetify 3 with the closeOnBack prop of VOverlay.
*/

import Vue from 'vue';

const DialogNavigationMixin = function (callback: string) {
  return Vue.extend({
    created() {
      const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
        // @ts-ignore
        this[callback](next);
      });

      this.$once('hook:beforeDestroy', () => {
        unregisterRouterGuard();
      });
    }
  });
};

export { DialogNavigationMixin };
