<template>
  <v-card class="wagon-type" outlined>
    <v-btn v-if="!readonly" class="wagon-type__button-delete" small icon @click="$emit('delete-button-clicked')">
      <v-icon small>far fa-times</v-icon>
    </v-btn>
    <v-card-text>
      <div class="d-flex align-center">
        <v-text-field
          :label="$t('rideAssessmentEdit.wagonType')"
          :value="selectedWagonTypeName"
          :disabled="readonly"
          readonly
        />
        <v-icon
          class="wagon-type__note-button ml-4"
          :class="{
            'wagon-type__note-button--filled': hasComment
          }"
          @click="$emit('open-modal')"
        >
          fa-comment {{ hasComment ? 'fas' : 'far' }}
        </v-icon>
      </div>
      <v-text-field
        :label="$t('rideAssessmentEdit.wagonTypeCount')"
        :value="count"
        :disabled="readonly"
        min="1"
        max="99"
        type="number"
        inputmode="numeric"
        pattern="[0-9]*"
        @input="updateCount"
      />
      <template v-if="selectedWagonTypeIsOnLine || this.wagonTypeId === undefined">
        <dialog-select
          v-for="(vehicleNumber, index) in vehicleNumbers"
          :key="index"
          :items="selectableVehicles"
          :label="$t('rideAssessmentEdit.vehicleNumber')"
          :value="vehicleNumber"
          :disabled="readonly"
          item-text="name"
          item-value="name"
          data-test="vehicle-number-select"
          hide-details
          @input="updateVehicleNumber(index, $event)"
        />
      </template>
      <template v-else>
        <v-text-field
          v-for="(vehicleNumber, index) in vehicleNumbers"
          :key="index"
          :label="$t('rideAssessmentEdit.vehicleNumber')"
          :value="vehicleNumber"
          :disabled="readonly"
          hide-details
          @input="updateVehicleNumber(index, $event)"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AssessmentWagonType, ListWagonType } from '@/shared/modules/AssessmentEdit/types/ride';
import { Vehicle } from '@/shared/plugins/database/profitester';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

export default Vue.extend({
  components: {
    DialogSelect
  },
  props: {
    wagonTypeId: {
      type: String as PropType<string | undefined>
    },
    count: {
      type: Number,
      required: true
    },
    vehicleNumbers: {
      type: Array as PropType<string[]>,
      required: true
    },
    note: {
      type: String,
      required: true
    },
    imageIds: {
      type: Array as PropType<string[]>,
      required: true
    },
    wagonTypes: {
      type: Array as PropType<ListWagonType[]>,
      default: () => []
    },
    lineVehicles: {
      type: Array as PropType<Vehicle[]>,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedWagonTypeIsOnLine(): boolean {
      return this.wagonTypes.find(wagonType => wagonType.id === this.wagonTypeId)?.onLine ?? false;
    },
    selectedWagonTypeName(): string {
      return this.wagonTypes.find(wagonType => wagonType.id === this.wagonTypeId)?.name ?? '';
    },
    selectableVehicles(): Vehicle[] {
      if (this.wagonTypeId === undefined) {
        return this.lineVehicles;
      }
      return this.lineVehicles.filter(vehicle => vehicle.wagonTypeId === this.wagonTypeId);
    },
    hasComment() {
      return this.note !== '' || this.imageIds.length > 0;
    }
  },
  methods: {
    updateCount(count: string) {
      const countNumber = Number(count);
      const newCount = isNaN(countNumber) || countNumber < 1 ? 1 : countNumber > 99 ? 99 : countNumber;
      const newVehicleNumbers = [...Array(newCount).keys()].map(index => this.vehicleNumbers[index] ?? '');
      this.emitWagonTypeUpdate({ count: newCount, vehicleNumbers: newVehicleNumbers });
    },
    updateVehicleNumber(index: number, vehicleNumber: string) {
      const newVehicleNumbers = [...this.vehicleNumbers];
      newVehicleNumbers[index] = vehicleNumber;
      if (this.wagonTypeId !== undefined) {
        this.emitWagonTypeUpdate({ vehicleNumbers: newVehicleNumbers });
      } else {
        const newWagonTypeId = this.lineVehicles.find(vehicle => vehicle.name === vehicleNumber)?.wagonTypeId;
        if (newWagonTypeId !== undefined) {
          this.emitWagonTypeUpdate({ wagonTypeId: newWagonTypeId, vehicleNumbers: newVehicleNumbers });
        }
      }
    },
    emitWagonTypeUpdate(wagonType: Partial<AssessmentWagonType>) {
      this.$emit('update:wagon-type', {
        wagonTypeId: this.wagonTypeId,
        count: this.count,
        vehicleNumbers: this.vehicleNumbers,
        note: this.note,
        ...wagonType
      });
    }
  }
});
</script>

<style scoped lang="scss">
.wagon-type {
  &__button-delete {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &__note-button {
    font-size: 1.0625rem;
  }

  &__note-button--filled {
    color: var(--v-primary-base);
  }
}
</style>
