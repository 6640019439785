<template>
  <v-textarea
    :value="value"
    :placeholder="$t('stationAssessmentEdit.sectionNotes.placeholder')"
    class="section-note"
    disabled
    dense
    auto-grow
    hide-details
    rows="1"
    @change="$emit('input', $event)"
  ></v-textarea>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.section-note ::v-deep > .v-input__control > .v-input__slot::before {
  content: none;
}
</style>
