import { AssessmentEditConfig } from '@/shared/modules/AssessmentEdit/types';

const configurations: Record<string, AssessmentEditConfig> = {
  draft: {
    goBackRouteName: 'ride-assessment-list',
    mode: 'create',
    deletable: true
  },
  edit: {
    goBackRouteName: 'ride-assessment-list',
    mode: 'edit',
    deletable: true
  },
  view: {
    goBackRouteName: 'ride-assessment-list',
    mode: 'view',
    deletable: false
  }
};

function getConfigurationByName(name: string) {
  const config = configurations[name];
  return config || configurations.draft;
}

export { getConfigurationByName };
