import imageCompression from 'browser-image-compression';
import { Profitester } from '@/shared/plugins/database/profitester';

import type { ImagePrefix } from '@/shared/modules/AssessmentEdit/types';

const database = new Profitester();

const getNewImageId = () => crypto.randomUUID();

const saveImages = async (
  images: string[],
  prefix: ImagePrefix = ''
): Promise<{ success: true; ids: string[] } | { success: false; error: unknown }> => {
  const dbImages = images.map(image => ({ id: `${prefix}${getNewImageId()}`, data: image }));
  try {
    await database.transaction('rw?', database.images, async () => {
      await database.images.bulkAdd(dbImages);
    });
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
  return { success: true, ids: dbImages.map(dbImage => dbImage.id) };
};

const getImages = async (ids: string[]) => {
  return (await database.images.bulkGet(ids)).map(dbImage => dbImage?.data ?? '');
};

const deleteAllImagesWithPrefix = async (prefix: ImagePrefix) => {
  await database.images.where('id').startsWith(prefix).delete();
};

const deleteImages = async (ids: string[]) => {
  return await database.images.bulkDelete(ids);
};

const compressImage = async (uploadedFile: File) => {
  const compressedImage = await imageCompression(uploadedFile, {
    maxSizeMB: 0.05,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: 'image/jpeg'
  });

  return await imageCompression.getDataUrlFromFile(compressedImage);
};

const getImagesFromFileList = async (event: Event): Promise<string[]> => {
  const files: FileList | null = (event.target as HTMLInputElement).files;

  if (files === null || files.length === 0) {
    return [];
  }

  const compressedImages: string[] = [];

  for (const file of files) {
    compressedImages.push(await compressImage(file));
  }

  return compressedImages;
};

const getImageCount = (event: Event) => {
  const files: FileList | null = (event.target as HTMLInputElement).files;
  return files === null ? 0 : files.length;
};

export { getImagesFromFileList, getImageCount, saveImages, getImages, deleteImages, deleteAllImagesWithPrefix };
