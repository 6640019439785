import VueRouter from 'vue-router';

function addRouteGuards(router: VueRouter, store: unknown) {
  router.beforeEach(async (to, from, next) => {
    await (store as { restored: Promise<unknown> }).restored;
    next();
  });
}

export { addRouteGuards };
