<template>
  <div
    class="devaluation-input pos-relative"
    :class="{ 'devaluation-input--error': errorMessage }"
    data-test="devaluation-input"
  >
    <div class="d-flex align-center mb-4">
      <img v-if="icon" :src="icon" width="17" height="17" contain class="devaluation-input__icon flex-grow-0 mr-2" />
      <h3 v-if="!inline" class="devaluation-input__title flex-grow-1 primary--text font-weight-regular">
        {{ $t(title) }}
      </h3>
      <div v-if="inline" class="flex-grow-1">
        <slot />
      </div>
      <v-icon
        v-if="showComment"
        class="devaluation-input__icon-button devaluation-input__note-button mr-1"
        :class="{
          'devaluation-input__note-button--error': noteOrImageError,
          'devaluation-input__note-button--filled': hasComment
        }"
        data-test="button-devaluation-comment"
        @click="$emit('open-modal')"
      >
        fa-comment {{ hasComment ? 'fas' : 'far' }}
      </v-icon>
      <v-icon
        v-if="showInfo"
        class="devaluation-input__icon-button"
        color="black"
        data-test="button-devaluation-info"
        @click="$emit('info-icon-clicked')"
      >
        far fa-circle-info
      </v-icon>
    </div>

    <slot v-if="!inline" />

    <p class="devaluation-input__error-message">{{ $t(errorMessageTranslationKey) }}</p>
    <v-divider class="devaluation-input__divider" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import type { ErrorMessage } from '@/shared/modules/AssessmentEdit/types';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: undefined
    },
    errorMessage: {
      type: String as PropType<ErrorMessage | ''>,
      default: '' as ErrorMessage | ''
    },
    showComment: {
      type: Boolean,
      default: true
    },
    hasComment: {
      type: Boolean,
      default: false
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorMessageTranslationKey(): string {
      return this.errorMessage ? `assessmentEdit.devaluationErrors.${this.errorMessage}` : '';
    },
    noteOrImageError(): boolean {
      return this.errorMessage === 'missingNote' || this.errorMessage === 'missingImage';
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';
@import '@/shared/styles/colors.scss';

.devaluation-input {
  &__icon {
    object-fit: contain;
    max-width: 100%;

    @include md-and-up {
      width: 1.375rem;
      height: 1.375rem;
    }
  }

  &__title {
    font-size: 0.875rem;
    line-height: 1.25;

    @include md-and-up {
      font-size: 1rem;
    }
  }

  &__icon-button {
    font-size: 1.0625rem;
  }

  &__note-button--filled {
    color: var(--v-primary-base);
  }

  &__error-message {
    color: var(--v-error-base);
    font-size: 0.75rem;
    line-height: 1.25;
    margin: 0;
    position: absolute;
    bottom: 10px;
    opacity: 0;
    transition: 0.25s ease-in-out;

    @include md-and-up {
      font-size: 0.875rem;
    }
  }

  &__divider {
    transition: 0.25s ease-in-out;
    margin-top: 20px;
  }

  &--error &__icon {
    filter: $filter-red;
  }

  &--error &__title,
  &--error &__note-button--error {
    color: var(--v-error-base) !important;
  }

  &--error &__error-message {
    opacity: 1;
  }

  &--error &__divider {
    border-color: var(--v-error-base);
    margin-top: 34px;
  }
}
</style>
