<template>
  <v-app-bar color="white" :height="isPhone ? 50 : 85" class="app-bar" clipped-left app>
    <v-col cols="auto" class="pa-0">
      <div class="same-space-wrapper">
        <router-link to="/">
          <v-img
            src="@/shared/assets/logo.svg"
            alt="VRR"
            :width="isPhone ? 41 : 65"
            :height="isPhone ? 55 : 85"
            position="left bottom"
            class="ml-8"
            contain
          />
        </router-link>
      </div>
    </v-col>
    <v-col class="text-center pa-0 text-truncate grey--text" data-test="center">
      <span v-if="subMenu && !showBottomNavigation">
        <v-tabs :value="currentRouteName" centered>
          <v-tab
            v-for="route in subMenu"
            :key="route.name"
            :tab-value="route.name"
            :to="{ name: route.name }"
            class="font-weight-bold"
          >
            <v-badge :value="getBadgeValue(route.name)" :content="getBadgeContent(route.name)">
              {{ route.meta.title }}
            </v-badge>
          </v-tab>
        </v-tabs>
      </span>
      <span v-else class="page-title text-sm-uppercase font-weight-sm-bold">
        {{ title }}
      </span>
    </v-col>
    <v-col cols="auto" class="text-right pa-0">
      <div class="same-space-wrapper d-flex justify-end">
        <slot name="default" />
      </div>
    </v-col>
    <div class="triangle-wrapper ml-8">
      <div class="triangle"></div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import { AppBarProps } from '@/shared/modules/Layout/types';

export default Vue.extend<unknown, unknown, unknown, AppBarProps>({
  props: {
    title: {
      type: String,
      required: true
    },
    subMenu: {
      type: Array,
      default: null
    },
    currentRouteName: {
      type: String,
      default: ''
    },
    stationAssessmentCountUnapproved: {
      type: Number,
      default: 0
    },
    stationAssessmentImagesNotReviewed: {
      type: Number,
      default: 0
    },
    rideAssessmentCountExported: {
      type: Number,
      default: 0
    },
    showBottomNavigation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPhone() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    getBadgeValue(routeName: string): boolean {
      if (routeName === 'station-data-overview-assessment-approval' && this.stationAssessmentCountUnapproved) {
        return true;
      }

      if (routeName === 'station-data-overview-image-review' && this.stationAssessmentImagesNotReviewed) {
        return true;
      }

      if (routeName === 'ride-data-overview-assessment-approval' && this.rideAssessmentCountExported) {
        return true;
      }

      return false;
    },
    getBadgeContent(routeName: string) {
      if (routeName === 'station-data-overview-assessment-approval') {
        return this.stationAssessmentCountUnapproved;
      }

      if (routeName === 'station-data-overview-image-review') {
        return this.stationAssessmentImagesNotReviewed;
      }

      if (routeName === 'ride-data-overview-assessment-approval') {
        return this.rideAssessmentCountExported;
      }

      return 0;
    }
  }
});
</script>

<style lang="scss" scoped>
@import '~@/shared/styles/media.scss';

::v-deep .v-toolbar__content {
  padding-left: 0;

  @include xs-only {
    padding-right: 0;
  }
}

.app-bar {
  // elevate above phone navigation drawer
  z-index: 7 !important;
}

.page-title {
  font-size: 0.875rem;
  letter-spacing: 0;

  @include sm-and-up {
    font-size: 1rem;
    letter-spacing: 0.0875em;
  }
}

.triangle-wrapper {
  --triangle-height: 16px;
  --triangle-width: calc(var(--triangle-height) * 1.93);
  --app-bar-height: 50px;
  --shadow-blur: 5px;

  position: absolute;
  left: -10px;
  top: var(--app-bar-height);

  // clip shadow except on the bottom
  overflow: hidden;
  min-height: calc(var(--triangle-height) + var(--shadow-blur) * 2);

  // position triangle behind logo
  z-index: -1;

  @include sm-and-up {
    --triangle-height: 22px;
    --app-bar-height: 85px;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: var(--triangle-height) var(--triangle-width) 0 var(--triangle-width);
  border-color: white transparent transparent transparent;
  filter: drop-shadow(0 0 var(--shadow-blur) rgba(0, 0, 0, 0.6));
}

.same-space-wrapper {
  width: 85px;

  @include sm-and-up {
    width: 180px;
  }
}
</style>
