export default {
  account: {
    passwordChangeRequired: 'Bevor dieser Zugang genutzt werden kann, muss das Passwort geändert werden.',
    passwordMustSecure: 'Das Passwort muss den folgenden Kriterien entsprechen:',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    newPasswordRepeat: 'Neues Passwort wiederholen',
    changePasswordNow: 'Passwort jetzt ändern',
    success: 'Passwort erfolgreich geändert',
    criteria: {
      length: '8 Stellen',
      number: 'Mindestens eine Zahl',
      letter: 'Mindestens ein Buchstabe',
      specialCharacter: 'Mindestens ein Sonderzeichen'
    },
    error: {
      required: 'Dieses Feld ist erforderlich',
      passwordsNotEqual: 'Die Passwortwiederholung stimmt nicht mit dem neuen Passwort überein.',
      passwordDontMatchCriteria: 'Das Passwort entspricht nicht den erforderlichen Kriterien',
      passwordWrong: 'Das aktuelle Passwort ist falsch',
      newPasswordEqualsCurrentPassword: 'Das neue Passwort muss sich vom aktuellen unterscheiden'
    }
  }
};
