<template>
  <v-dialog :value="value" @input="closeDialog" max-width="400">
    <v-card>
      <v-card-title class="headline pa-6">
        {{ $t(title) }}
      </v-card-title>
      <v-card-text class="subtitle-1 px-6 py-0" data-test="card-info-content">
        <p v-html="text"></p>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="primary" depressed data-test="button-info-close">
          {{ $t('layout.common.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  word-break: normal;
}
</style>
