import {
  CameraAndNotesModalDevaluationUnit,
  DevaluationCategory,
  ImagePrefix,
  StationAssessment,
  StationAssessmentEditState,
  StationAssessmentSectionKey,
  StationAssessmentStatus,
  StationAssessmentValidation
} from '@/shared/modules/AssessmentEdit/types/station';
import { VueConstructor } from 'vue';
import { ActionTree, Module, MutationTree } from 'vuex';
import { deleteImages, saveImages } from '@/shared/modules/AssessmentEdit/services/image-service';

export const STATION_ASSESSMENT_EDIT_NAMESPACE = 'STATION_ASSESSMENT_EDIT_NAMESPACE';

const state: StationAssessmentEditState = {
  stationAssessment: undefined,
  assessmentValidation: undefined,
  readOnly: false,
  imagePrefix: '',
  cameraAndNotesModalDevaluationUnit: null
};

const mutations: MutationTree<StationAssessmentEditState> = {
  setStationAssessment(state, payload: StationAssessment) {
    state.stationAssessment = payload;
  },
  updateStationAssessment(state, payload: StationAssessment) {
    if (state.stationAssessment !== undefined) {
      state.stationAssessment = payload;
    }
  },
  setStationAssessmentValidationStatus(state, payload: StationAssessmentStatus) {
    if (state.stationAssessment) {
      state.stationAssessment.assessmentStatus = payload;
    }
  },
  setStationAssessmentSectionVisited(state, payload: StationAssessmentSectionKey) {
    if (state.stationAssessment) {
      state.stationAssessment.sections[payload].visited = true;
    }
  },
  setAssessmentValidation(state, payload: StationAssessmentValidation) {
    state.assessmentValidation = payload;
  },
  setReadOnly(state, payload: boolean) {
    state.readOnly = payload;
  },
  setImagePrefix(state, payload: ImagePrefix) {
    state.imagePrefix = payload;
  },
  setCameraAndNotesModalDevaluationUnit(state, payload: CameraAndNotesModalDevaluationUnit) {
    state.cameraAndNotesModalDevaluationUnit = payload;
  },
  saveState() {
    // noop mutation to trigger vuex-persist
  }
};

const configureActions = () => {
  const actions: ActionTree<StationAssessmentEditState, unknown> = {
    async addStationAssessmentImages({ state, commit }, { images, category }): Promise<void> {
      if (images.length === 0) {
        return;
      }

      if (!state.cameraAndNotesModalDevaluationUnit) {
        return;
      }

      const stationAssessment = state.stationAssessment;
      const devaluationCategory = category as DevaluationCategory;

      // if devaluation unit equals devaluation category then set images to this category
      if (devaluationCategory === state.cameraAndNotesModalDevaluationUnit) {
        if (!stationAssessment?.[devaluationCategory]?.images) {
          return;
        }

        const saveResult = await saveImages(images, state.imagePrefix);
        if (!saveResult.success) {
          return;
        }

        stationAssessment[devaluationCategory].images = [
          ...stationAssessment[devaluationCategory].images,
          ...saveResult.ids
        ];
        commit('updateStationAssessment', stationAssessment);
        return;
      }

      // @ts-ignore
      if (!stationAssessment?.[devaluationCategory]?.[state.cameraAndNotesModalDevaluationUnit]?.images) {
        return;
      }

      const saveResult = await saveImages(images, state.imagePrefix);
      if (!saveResult.success) {
        return;
      }

      // @ts-ignore
      stationAssessment[devaluationCategory][state.cameraAndNotesModalDevaluationUnit].images = [
        // @ts-ignore
        ...stationAssessment[devaluationCategory][state.cameraAndNotesModalDevaluationUnit].images,
        ...saveResult.ids
      ];
      commit('updateStationAssessment', stationAssessment);
    },
    async addStationAssessmentNote({ state, commit }, { note, category }): Promise<void> {
      if (!state.cameraAndNotesModalDevaluationUnit) {
        return;
      }

      const stationAssessment = state.stationAssessment;
      const devaluationCategory = category as DevaluationCategory;

      // if devaluation unit equals devaluation category then set note to this category
      if (devaluationCategory === state.cameraAndNotesModalDevaluationUnit) {
        if (undefined === stationAssessment?.[devaluationCategory]?.note) {
          return;
        }

        stationAssessment[devaluationCategory].note = note;
        commit('updateStationAssessment', stationAssessment);
        return;
      }

      // @ts-ignore
      if (undefined === stationAssessment?.[devaluationCategory]?.[state.cameraAndNotesModalDevaluationUnit]?.note) {
        return;
      }

      // @ts-ignore
      stationAssessment[devaluationCategory][state.cameraAndNotesModalDevaluationUnit].note = note;
      commit('updateStationAssessment', stationAssessment);
    },
    async deleteStationAssessmentImage({ state, commit }, { imageIndex, category }): Promise<void> {
      if (undefined === imageIndex || null === imageIndex) {
        return;
      }

      if (!state.cameraAndNotesModalDevaluationUnit) {
        return;
      }

      const stationAssessment = state.stationAssessment;
      const devaluationCategory = category as DevaluationCategory;

      // if devaluation unit equals devaluation category then remove image from this category
      if (devaluationCategory === state.cameraAndNotesModalDevaluationUnit) {
        if (!stationAssessment?.[devaluationCategory]?.images) {
          return;
        }

        await deleteImages([stationAssessment[devaluationCategory].images[imageIndex]]);

        stationAssessment[devaluationCategory].images.splice(imageIndex, 1);
        commit('updateStationAssessment', stationAssessment);
        return;
      }

      // @ts-ignore
      if (!stationAssessment?.[devaluationCategory]?.[state.cameraAndNotesModalDevaluationUnit]?.images) {
        return;
      }

      await deleteImages([
        // @ts-ignore
        stationAssessment[devaluationCategory][state.cameraAndNotesModalDevaluationUnit].images[imageIndex]
      ]);

      // @ts-ignore
      stationAssessment[devaluationCategory][state.cameraAndNotesModalDevaluationUnit].images.splice(imageIndex, 1);
      commit('updateStationAssessment', stationAssessment);
    }
  };
  return actions;
};

export const configureStationAssessmentEditStore = (Vue: VueConstructor) => {
  const module: Module<StationAssessmentEditState, unknown> = {
    namespaced: true,
    state,
    mutations,
    actions: configureActions()
  };
  return module;
};
