import { render, staticRenderFns } from "./RideAssessmentStatistics.vue?vue&type=template&id=89fa9e28&scoped=true"
import script from "./RideAssessmentStatistics.vue?vue&type=script&lang=ts"
export * from "./RideAssessmentStatistics.vue?vue&type=script&lang=ts"
import style0 from "./RideAssessmentStatistics.vue?vue&type=style&index=0&id=89fa9e28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89fa9e28",
  null
  
)

export default component.exports