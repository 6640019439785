import { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { authUser } from '@/shared/helper/vue-auth';
import Layout from '@/shared/modules/Layout/views/Layout.vue';

export default (Vue: VueConstructor, routes: RouteConfig[], isFrontend: boolean) => {
  Vue.use(VueRouter);

  const pageTitleSuffix = 'Profitester Reporting';

  const router = new VueRouter({
    mode: 'history',
    base: isFrontend ? '/frontend' : '',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
    routes: [
      {
        path: '/',
        component: Layout,
        children: [
          {
            path: '',
            redirect: isFrontend ? undefined : { path: '/assessment' },
            beforeEnter: (to, from, next) => {
              const homepageURL = new URL(window.location.protocol + '//' + window.location.host + '/assessment');
              window.location.href = homepageURL.toString();
            }
          },
          ...routes
        ]
      },
      {
        path: '*',
        beforeEnter: (to, from, next) => {
          const currentURL = new URL(window.location.href);

          // Try to redirect to the path on the other instance if no redirection has been tried yet (no r=1 query param)
          if (!currentURL.searchParams.has('r')) {
            const instanceBase = isFrontend ? '' : '/frontend';
            const instanceURL = new URL(
              window.location.protocol + '//' + window.location.host + instanceBase + to.fullPath
            );
            instanceURL.searchParams.set('r', '1');
            window.location.href = instanceURL.toString();
            return;
          }

          // Redirect to global homepage
          next({ path: '/' });
        }
      }
    ]
  });

  router.beforeEach((to, from, next) => {
    const passwordChangeRouteName = 'account-password-change';
    if (authUser('passwordChangeRequired') && to.name !== passwordChangeRouteName) {
      next({ name: passwordChangeRouteName, query: { redirect: to.fullPath } });
    } else {
      next();
    }
  });

  router.afterEach(to => {
    if ('1' === to.query.r) {
      router.replace({ path: to.path, query: { ...to.query, r: undefined } });
    }
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title);

    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title + ' - ' + pageTitleSuffix;
    } else {
      document.title = pageTitleSuffix;
    }
  });

  return router;
};
