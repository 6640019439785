<template>
  <v-btn-toggle
    :value="value"
    group
    class="boolean-option d-flex"
    :class="{ 'boolean-option--selected': value !== undefined }"
    data-test="boolean-option"
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="option in booleanOptions"
      :key="option.value"
      :value="option.value"
      :ripple="false"
      :disabled="disabled"
      max-height="34"
      class="boolean-option__button ma-0 font-weight-medium flex-grow-1"
    >
      {{ $t(option.label) }}
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { DevaluationBooleanOption } from '@/shared/modules/AssessmentEdit/types';

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<DevaluationBooleanOption | undefined>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      booleanOptions: [
        {
          value: 'yes',
          label: 'assessmentEdit.booleanOptions.yes'
        },
        {
          value: 'no',
          label: 'assessmentEdit.booleanOptions.no'
        }
      ] as { value: DevaluationBooleanOption; label: string }[]
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';
@import '@/shared/styles/media.scss';

.boolean-option {
  max-width: 300px;
  gap: 8px;

  &--selected > .boolean-option__button.v-btn {
    opacity: 0.3;
  }

  &__button {
    --border-color: var(--v-darkgrey-darken1);

    border-width: 1px !important;
    border-style: solid;
    border-color: var(--border-color) !important;
    border-radius: 5px !important;

    letter-spacing: 0;
    text-transform: none;
    transition-property: box-shadow, transform, opacity, border-color, background-color;

    &.v-btn.v-btn--active {
      --border-color: var(--v-primary-base);
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base);
      opacity: 1;

      &.v-btn--disabled {
        color: var(--v-white-darken2) !important;
      }
    }

    &.v-btn.v-btn--disabled {
      --border-color: transparent;
      cursor: not-allowed;
      pointer-events: auto;
    }

    &::before {
      display: none;
    }
  }
}
</style>
