<template>
  <v-dialog :value="value" @input="toggleDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline pt-6" :class="`${this.color}--text`">
        <slot name="title" />
      </v-card-title>
      <v-card-text class="subtitle-1 px-6 py-0">
        <slot name="text" />
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn v-if="showCancelButton" @click="cancel" data-test="button-cancel" text>
          {{ $t('layout.common.cancel') }}
        </v-btn>
        <v-btn @click="confirm" :color="color" :loading="loading" data-test="button-confirm" depressed>
          {{ $t(confirmTextKey) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    confirmTextKey: {
      type: String,
      default: 'layout.common.save'
    },
    color: {
      type: String,
      default: 'primary'
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleDialog(open: boolean) {
      this.$emit('input', open);
    },
    cancel() {
      this.$emit('cancel');
      this.toggleDialog(false);
    },
    confirm() {
      this.$emit('confirm');
      this.toggleDialog(false);
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  word-break: normal;
}
</style>
