<template>
  <app-snackbar
    :value="isOffline"
    :timeout="-1"
    :top="$vuetify.breakpoint.smAndUp"
    :bottom="$vuetify.breakpoint.xsOnly"
    :color="'error'"
    :elevation="$vuetify.breakpoint.xs ? 0 : undefined"
    icon="$offline"
  >
    {{ $t('layout.offlineSnackbar.title') }}
    <template #description>
      {{ $t('layout.offlineSnackbar.description') }}
    </template>
  </app-snackbar>
</template>

<script lang="ts">
import { VueOfflineMixin } from '@/shared/modules/Global/mixins/VueOfflineMixin';
import Vue, { VueConstructor } from 'vue';
import AppSnackbar from '@/shared/modules/Global/components/AppSnackbar.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof VueOfflineMixin>>).extend({
  components: { AppSnackbar },
  mixins: [VueOfflineMixin]
});
</script>
