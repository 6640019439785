<template>
  <v-autocomplete
    :items="selectableVehicleNumbers"
    :placeholder="$t('rideAssessmentEdit.vehicleNumber')"
    :value="value"
    :disabled="disabled"
    :clearable="!disabled"
    :menu-props="menuProps"
    item-text="name"
    item-value="id"
    auto-select-first
    autocorrect="off"
    spellcheck="false"
    @input="$emit('input', $event)"
    hide-details
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AssessmentWagonType } from '@/shared/modules/AssessmentEdit/types/ride';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: ''
    },
    wagonTypes: {
      type: Array as PropType<AssessmentWagonType[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuProps: {
        closeOnClick: false,
        closeOnContentClick: false,
        openOnClick: false,
        disableKeys: true,
        maxHeight: 304,
        contentClass: 'e2e-vehicle-number-select'
      }
    };
  },
  computed: {
    selectableVehicleNumbers(): string[] {
      return this.wagonTypes.flatMap(wagonType =>
        wagonType.vehicleNumbers.filter(vehicleNumber => vehicleNumber !== '')
      );
    }
  }
});
</script>
