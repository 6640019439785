export default {
  layout: {
    logout: 'Ausloggen',
    analysis: 'Auswertung',
    mapAnalysis: 'Kartenauswertung',
    dataOverview: 'Datenübersicht',
    baseData: 'Stammdaten',
    userManagement: 'Nutzerverwaltung',
    assessment: 'Erfassung',
    ride: 'Fahrt',
    station: 'Station',
    settings: 'Einstellungen',
    passwordChange: 'Passwort ändern',
    rotateDeviceHint: 'Bitte drehen Sie das Gerät!',
    common: {
      cancel: 'Abbrechen',
      delete: 'Löschen',
      create: 'Anlegen',
      save: 'Speichern',
      date: 'Datum',
      time: 'Uhrzeit',
      ok: 'ok',
      reset: 'leeren'
    },
    time: '{time} Uhr',
    validations: {
      required: '{field} ist ein Pflichtfeld'
    },
    externalLinks: {
      dataPrivacy: 'Datenschutz',
      imprint: 'Impressum'
    },
    offlineSnackbar: {
      title: 'Sie sind offline',
      description: 'Vergewissern Sie sich, dass das WLAN eingeschaltet ist.'
    }
  }
};
