<template>
  <v-container fluid :class="{ 'full-width-page': isMobile && !isBasePage }">
    <v-row class="ma-sm-0">
      <v-card width="100%" min-height="70vh">
        <v-row v-if="!isMobile || isBasePage" class="showcase py-10 py-sm-13 px-4" no-gutters justify="center">
          <v-btn @click="emitNavigateBack" class="showcase__back" data-test="button-back" small icon>
            <v-icon color="primary" small>far fa-arrow-left</v-icon>
          </v-btn>
          <v-btn
            v-if="deletable"
            @click="deleteDialogVisible = true"
            class="showcase__delete"
            data-test="button-delete"
            small
            icon
          >
            <v-icon color="grey" small>far fa-trash</v-icon>
          </v-btn>
          <div v-if="stationAssessment" class="base-data">
            <p class="base-data__title span-2 mb-0 primary--text text-uppercase font-weight-bold">
              {{ $t('stationAssessmentEdit.baseData') }}
            </p>
            <h1 class="base-data__station-name span-2">{{ stationAssessment.stationName }}</h1>
            <div class="base-data__date">
              <app-date-picker
                v-model="assessmentDateTime"
                :disabled="readOnly"
                :max="maxDate"
                :min="minDate"
                hide-label
              />
            </div>
            <div class="base-data__time">
              <app-time-picker
                v-model="assessmentDateTime"
                :disabled="readOnly"
                :max="maxDate"
                hide-label
                hide-details
              />
            </div>
          </div>
        </v-row>
        <v-row no-gutters>
          <v-col v-if="!isMobile || isBasePage" cols="12" md="4">
            <div class="navigation-container mx-auto d-flex flex-column">
              <assessment-navigation :items="navigationSections" :select-default-nav-item="isBasePage" />
              <assessment-actions
                class="navigation-container__actions"
                :mode="mode"
                :read-only="readOnly"
                :complete="stationAssessmentIsComplete"
                :changed="stationAssessmentHasChanged"
                :loading="loading"
                :save-dialog-title="$t('stationAssessmentEdit.saveDialog.title')"
                :save-dialog-description="$t('stationAssessmentEdit.saveDialog.description', [currentStationName])"
                @close="emitNavigateBack"
                @edit="setReadOnly(false)"
                @complete="emitComplete"
              />
            </div>
          </v-col>
          <v-col v-if="!isMobile || !isBasePage" cols="12" md="8">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <app-confirm-dialog
      :value="deleteDialogVisible"
      color="error"
      confirm-text-key="layout.common.delete"
      @cancel="deleteDialogVisible = false"
      @confirm="emitDelete"
    >
      <template #title>{{ $t('stationAssessmentEdit.deleteDialog.title') }}</template>
      <template #text>{{ $t('stationAssessmentEdit.deleteDialog.description', [currentStationName]) }}</template>
    </app-confirm-dialog>
    <app-confirm-dialog
      :value="discardChangesDialogVisible"
      color="error"
      confirm-text-key="stationAssessmentEdit.discardDialog.discard"
      @cancel="discardChangesDialogVisible = false"
      @confirm="$emit('navigate-back')"
    >
      <template #title>
        <div data-test="discard-dialog-title">
          {{ $t('stationAssessmentEdit.discardDialog.title') }}
        </div>
      </template>
      <template #text>
        {{ $t('stationAssessmentEdit.discardDialog.description', [currentStationName]) }}
      </template>
    </app-confirm-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';
import { mapState, mapMutations } from 'vuex';
import AssessmentNavigation from '@/shared/modules/AssessmentEdit/components/AssessmentNavigation.vue';
import AppDatePicker from '@/shared/modules/Global/components/AppDatePicker.vue';
import AppTimePicker from '@/shared/modules/Global/components/AppTimePicker.vue';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import AssessmentActions from '@/shared/modules/AssessmentEdit/components/AssessmentActions.vue';
import { formatApiDateTime, getEndOfQuarter, getStartOfQuarter, min, parseApiDateTime } from '@/shared/helper/date';
import {
  getQualityOfStayStatus,
  validateStationAssessment
} from '@/shared/modules/AssessmentEdit/services/station-assessment-validation';
import { STATION_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/station-assessment-edit';
import {
  getStationAssessmentNavigationSections,
  stationAssessmentHasChanged
} from '@/shared/modules/AssessmentEdit/services/station-assessment-service';

import type { StationAssessment, StationAssessmentEditState } from '@/shared/modules/AssessmentEdit/types/station';
import type { AssessmentEditMode, NavigationSection } from '@/shared/modules/AssessmentEdit/types';

type VuexBindings = {
  stationAssessment: StationAssessmentEditState['stationAssessment'];
  readOnly: boolean;
  assessmentValidation: StationAssessmentEditState['assessmentValidation'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AssessmentNavigation,
    AppDatePicker,
    AppTimePicker,
    AppConfirmDialog,
    AssessmentActions
  },
  props: {
    initialStationAssessment: {
      type: Object as PropType<StationAssessment>,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String as PropType<AssessmentEditMode>,
      default: 'create'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteDialogVisible: false,
      stationAssessmentHasChanged: false,
      discardChangesDialogVisible: false,
      assessmentUnwatchFunctions: [] as (() => void)[]
    };
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_EDIT_NAMESPACE, ['stationAssessment', 'readOnly', 'assessmentValidation']),
    assessmentDateTime: {
      get(): string | undefined {
        return this.stationAssessment === undefined
          ? undefined
          : formatApiDateTime(this.stationAssessment.assessmentDateTime);
      },
      set(newDate: string): void {
        const newParsedDate = parseApiDateTime(newDate);
        if (this.stationAssessment !== undefined && newParsedDate !== undefined) {
          this.stationAssessment.assessmentDateTime = newParsedDate;
        }
      }
    },
    currentStationName(): string {
      return this.stationAssessment?.stationName ?? '';
    },
    isBasePage(): boolean {
      return this.$route.name === 'station-assessment';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    stationAssessmentIsComplete(): boolean {
      if (!this.stationAssessment) {
        return false;
      }

      return 'complete' === this.stationAssessment.assessmentStatus;
    },
    minDate(): Date | undefined {
      return this.mode === 'edit' ? getStartOfQuarter(this.assessmentDateTime) : undefined;
    },
    maxDate(): Date | undefined {
      return this.mode === 'edit'
        ? min([new Date(), getEndOfQuarter(this.assessmentDateTime) ?? new Date()])
        : undefined;
    },
    navigationSections(): NavigationSection[] {
      return getStationAssessmentNavigationSections(
        getQualityOfStayStatus(
          this.assessmentValidation?.qualityOfStayOne.status,
          this.assessmentValidation?.qualityOfStayTwo.status
        ),
        this.assessmentValidation?.passengerInformation.status || 'initial',
        this.assessmentValidation?.accessibility.status || 'initial'
      );
    }
  },
  methods: {
    ...mapMutations(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'setStationAssessment',
      'setAssessmentValidation',
      'setReadOnly',
      'setImagePrefix',
      'setStationAssessmentValidationStatus',
      'saveState'
    ]),
    validateAndSaveAssessment() {
      this.saveState();
      if (this.stationAssessment !== undefined) {
        const { status, validation } = validateStationAssessment(this.stationAssessment);
        this.setAssessmentValidation(validation);
        this.setStationAssessmentValidationStatus(status);
      }
    },
    checkAssessmentHasChanged() {
      if (this.mode === 'edit' && !this.readOnly && this.stationAssessment !== undefined) {
        this.stationAssessmentHasChanged = stationAssessmentHasChanged(
          this.initialStationAssessment,
          this.stationAssessment
        );
      }
    },
    emitDelete() {
      this.$emit('delete');
    },
    emitComplete() {
      this.$emit('assessment-complete', this.stationAssessment);
    },
    emitNavigateBack() {
      if (this.mode === 'edit' && !this.readOnly && this.stationAssessmentHasChanged) {
        this.discardChangesDialogVisible = true;
        return;
      }

      this.$emit('navigate-back');
    }
  },
  created() {
    // prevent date and time pickers from overwriting new assessment with data from the store
    this.setStationAssessment(undefined);
  },
  mounted() {
    if (this.mode === 'edit') {
      this.setStationAssessment(structuredClone(this.initialStationAssessment));
    } else {
      this.setStationAssessment(this.initialStationAssessment);
    }

    this.setReadOnly(['edit', 'view'].includes(this.mode));
    this.setImagePrefix(this.mode === 'edit' ? 'edit-' : '');

    if (this.stationAssessment !== undefined) {
      this.validateAndSaveAssessment();

      // watch specific station assessment keys for validation so that saving the validation result won't trigger the watcher
      const stationAssessmentKeysToWatch: (keyof StationAssessment)[] = [
        'qualityOfStay',
        'passengerInformation',
        'accessibility',
        'sections'
      ];
      this.assessmentUnwatchFunctions.push(
        ...stationAssessmentKeysToWatch.map(key => {
          return this.$watch(
            `stationAssessment.${key}`,
            function () {
              this.validateAndSaveAssessment();
              this.checkAssessmentHasChanged();
            },
            { deep: true }
          );
        })
      );
    }
  },
  beforeDestroy() {
    this.assessmentUnwatchFunctions.forEach(unwatch => unwatch());
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';

.full-width-page {
  margin-top: -32px;
  margin-inline: -20px;

  max-width: none;
  width: auto;
}

.showcase {
  @include sm-and-up {
    box-shadow: $shadow-lg;
  }

  &__back,
  &__delete {
    position: absolute;
    top: 4px;
  }

  &__back {
    left: 4px;
  }

  &__delete {
    right: 4px;
  }
}

.base-data {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  &__title {
    font-size: 0.75rem;
    letter-spacing: 0.057em;

    @include sm-and-up {
      font-size: 1rem;
    }
  }

  &__station-name {
    font-size: 1.375rem;
    letter-spacing: 0.035em;
    text-align: center;

    @include sm-and-up {
      font-size: 2.875rem;
    }
  }

  &__date,
  &__time {
    max-width: 160px;
  }

  &__date {
    justify-self: end;
  }

  &__time {
    justify-self: start;
  }
}

.navigation-container {
  @include md-and-up {
    max-width: 280px;
  }

  &__actions {
    position: fixed;
    bottom: 100px;
    right: 35px;

    @include sm-and-up {
      position: relative;
      bottom: 0;
      right: 0;
      align-self: flex-end;
      margin-right: 20px;
      margin-top: 64px;
    }

    @include md-and-up {
      margin-right: 0;
    }
  }
}

.span-2 {
  grid-column: span 2;
}
</style>
