import { VueConstructor } from 'vue';
import { Profitester } from '@/shared/plugins/database/profitester';

const DatabasePlugin = {
  install: (Vue: VueConstructor) => {
    Vue.prototype.$database = new Profitester();
  }
};

export default (Vue: VueConstructor) => {
  Vue.use(DatabasePlugin);
};
