<template>
  <v-container class="pt-0 px-6">
    <section-heading subheading="rideAssessmentEdit.sections.wagonTypes" />
    <div class="form-grid">
      <div class="section-error-wrapper">
        <section-error :show="rideAssessment.forms.wagonTypes.status === 'incomplete'">
          {{ $t('rideAssessmentEdit.error.wagonTypes') }}
        </section-error>
      </div>
      <wagon-type-form
        v-for="(wagonType, i) in rideAssessment.wagonTypes.values"
        :key="i"
        v-bind="wagonType"
        :wagon-types="selectableWagonTypes"
        :line-vehicles="vehiclesOnLine"
        :readonly="readOnly"
        @update:wagon-type="updateWagonTypeIndices({ index: i, value: $event })"
        @delete-button-clicked="showDeleteDialog(i)"
        @open-modal="openCameraAndNotesModal(i)"
      />
      <wagon-type-create-form
        v-if="showWagonTypeCreateForm"
        :wagon-types="selectableWagonTypes"
        :line-vehicles="vehiclesOnLine"
        @input="addWagonType"
      />
    </div>
    <assessment-overview-button
      :complete="rideAssessment.forms.wagonTypes.status === 'complete'"
      to-route-name="ride-assessment"
    />
    <app-confirm-dialog
      :value="wagonTypeToDeleteIndex !== -1"
      color="error"
      confirm-text-key="layout.common.delete"
      @confirm="deleteWagonTypeAtIndex"
      @cancel="closeDeleteDialog"
    >
      <template #title>{{ $t('rideAssessmentEdit.wagonTypeDeleteDialog.title') }}</template>
      <template #text>{{ $t('rideAssessmentEdit.wagonTypeDeleteDialog.description') }}</template>
    </app-confirm-dialog>
    <camera-and-notes-modal
      v-if="cameraAndNotesModalWagonType !== undefined"
      devaluation-unit-name="wagonTypes"
      :title="cameraAndNotesModalTitle"
      :note="cameraAndNotesModalWagonType?.note ?? ''"
      :images="cameraAndNotesModalImages"
      :download-title="cameraAndNotesModalDownloadTitle"
      :read-only="readOnly"
      @add-note="updateWagonType({ index: cameraAndNotesModalWagonTypeIndex, value: { note: $event } })"
      @add-images="addImages"
      @delete-image="deleteImage"
      @closed="cameraAndNotesModalWagonTypeIndex = -1"
    />
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import SectionError from '@/shared/modules/AssessmentEdit/components/SectionError.vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import WagonTypeForm from '@/shared/modules/AssessmentEdit/components/WagonTypeForm.vue';
import { mapActions, mapState } from 'vuex';
import { RIDE_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';
import { Vehicle, WagonType } from '@/shared/plugins/database/profitester';
import {
  AssessmentWagonType,
  ListWagonType,
  RideAssessmentEditState
} from '@/shared/modules/AssessmentEdit/types/ride';
import { PageLeaveMixin } from '@/shared/modules/AssessmentEdit/mixins/page-leave-mixin';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import WagonTypeCreateForm from '@/shared/modules/AssessmentEdit/components/WagonTypeCreateForm.vue';
import { numberSensitiveComparator } from '@/shared/helper/i18n';

type VuexBindings = {
  rideAssessment: RideAssessmentEditState['rideAssessment'];
  readOnly: boolean;
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  mixins: [PageLeaveMixin('setSectionVisited')],
  components: {
    SectionError,
    SectionHeading,
    AssessmentOverviewButton,
    CameraAndNotesModal,
    WagonTypeForm,
    AppConfirmDialog,
    WagonTypeCreateForm
  },
  data() {
    return {
      cameraAndNotesModalWagonTypeIndex: -1,
      selectableWagonTypes: [] as ListWagonType[],
      vehiclesOnLine: [] as Vehicle[],
      wagonTypeToDeleteIndex: -1
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['rideAssessment', 'readOnly']),
    cameraAndNotesModalTitle(): string {
      return 'rideAssessmentEdit.wagonType';
    },
    cameraAndNotesModalDownloadTitle(): string {
      return getImageDownloadTitle(
        this.rideAssessment.lineName,
        this.rideAssessment.assessmentDateTime,
        this.$t(this.cameraAndNotesModalTitle).toString()
      );
    },
    cameraAndNotesModalWagonType(): AssessmentWagonType | undefined {
      return this.rideAssessment.wagonTypes.values[this.cameraAndNotesModalWagonTypeIndex];
    },
    showWagonTypeCreateForm(): boolean {
      const uniqueWagonTypeIds = new Set(this.rideAssessment.wagonTypes.values.map(wagonType => wagonType.wagonTypeId));
      return uniqueWagonTypeIds.size < 3 && !this.readOnly && !this.rideAssessment.wagonTypes.deactivated;
    }
  },
  asyncComputed: {
    cameraAndNotesModalImages: {
      get(): Promise<string[]> {
        if (this.cameraAndNotesModalWagonType === undefined) return Promise.resolve([]);
        return getImages(this.cameraAndNotesModalWagonType.imageIds);
      },
      default: []
    }
  },
  methods: {
    ...mapActions(RIDE_ASSESSMENT_EDIT_NAMESPACE, [
      'updateRideAssessmentValue',
      'addWagonType',
      'updateWagonType',
      'deleteWagonType',
      'addRideAssessmentImages',
      'deleteRideAssessmentImage'
    ]),
    showDeleteDialog(index: number) {
      this.wagonTypeToDeleteIndex = index;
    },
    closeDeleteDialog() {
      this.wagonTypeToDeleteIndex = -1;
    },
    updateWagonTypeIndices(payload: { index: number; value: AssessmentWagonType }) {
      if (
        payload.value.wagonTypeId === this.rideAssessment.assessedWagonTypeId &&
        this.rideAssessment.wagonState[0].value !== undefined &&
        payload.value.vehicleNumbers.includes(this.rideAssessment.wagonState[0].value) === false
      ) {
        this.resetAssessedWagonType();
      }

      this.updateWagonType(payload);
    },
    deleteWagonTypeAtIndex() {
      const wagonType = this.rideAssessment.wagonTypes.values[this.wagonTypeToDeleteIndex];
      if (wagonType !== undefined && wagonType.wagonTypeId === this.rideAssessment.assessedWagonTypeId) {
        this.resetAssessedWagonType();
      }

      this.deleteWagonType(this.wagonTypeToDeleteIndex);
      this.closeDeleteDialog();
    },
    async getVehiclesOnLine(lineId: string) {
      let vehicles: Vehicle[] = [];
      try {
        vehicles = await this.$database.vehicles.where('lines').equals(lineId).toArray();
        vehicles.sort((a: Vehicle, b: Vehicle) => numberSensitiveComparator.compare(a.name, b.name));
      } catch (error) {
        console.error(error);
      }
      return vehicles;
    },
    async getAllWagonTypes() {
      let wagonTypes: WagonType[] = [];
      try {
        wagonTypes = await this.$database.wagonTypes.toArray();
      } catch (error) {
        console.error(error);
      }
      return wagonTypes;
    },
    setSectionVisited() {
      this.updateRideAssessmentValue({ path: `forms.wagonTypes.visited`, value: true });
    },
    openCameraAndNotesModal(index: number) {
      this.cameraAndNotesModalWagonTypeIndex = index;
    },
    async addImages(event: Event) {
      const images = await getImagesFromFileList(event);
      await this.addRideAssessmentImages({
        path: `wagonTypes.values[${this.cameraAndNotesModalWagonTypeIndex}].imageIds`,
        images
      });
    },
    async deleteImage(imageIndex: number) {
      this.deleteRideAssessmentImage({
        path: `wagonTypes.values[${this.cameraAndNotesModalWagonTypeIndex}].imageIds`,
        imageIndex
      });
    },
    resetAssessedWagonType() {
      this.rideAssessment.assessedWagonTypeId = '';
      this.rideAssessment.assessedWagonTypeName = '';
      this.rideAssessment.wagonState[0].value = undefined;
    }
  },
  async mounted() {
    const allWagonTypes = await this.getAllWagonTypes();
    this.vehiclesOnLine = await this.getVehiclesOnLine(this.rideAssessment.lineId);
    this.selectableWagonTypes = allWagonTypes.map(wagonType => {
      const onLine = this.vehiclesOnLine.some(vehicle => vehicle.wagonTypeId === wagonType.id);
      return { ...wagonType, onLine };
    });
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-grid {
  --row-gap: 16px;
  @include dynamic-grid(40px, 300px, 3);
  // set negative margin to compensate for the row gap when the section error is not shown
  margin-block-start: calc(-1 * var(--row-gap));

  @include md-and-up {
    --row-gap: 40px;
    row-gap: var(--row-gap);
    justify-content: start;
  }
}

.section-error-wrapper {
  grid-column: 1 / -1;
  position: sticky;
  top: 80px;
  z-index: 1;
}
</style>
