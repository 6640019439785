// a widened Array.includes from https://fettblog.eu/typescript-array-includes/ that solves https://github.com/Microsoft/TypeScript/issues/26255
function includes<T extends U, U>(collection: Array<T>, element: U): element is T {
  return collection.includes(element as T);
}

// Only use when object does not have more properties than specified by T
// see https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

// Object.entries that keeps the key type (https://stackoverflow.com/a/74891854)
// Only use when object does not have more properties than specified by T
// see https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
const entries = <T extends Record<PropertyKey, unknown>, K extends keyof T, V extends T[K]>(o: T) =>
  Object.entries(o) as [K, V][];

export { includes, keys, entries };
