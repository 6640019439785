<template>
  <v-container fluid :class="{ 'full-width-page': isMobile && !isBasePage }">
    <v-row class="ma-sm-0">
      <v-card width="100%" min-height="70vh" :loading="!isLoaded">
        <template v-if="isLoaded">
          <v-row v-if="!isMobile || isBasePage" class="showcase py-10 py-sm-13 px-4" no-gutters justify="center">
            <v-btn @click="emitNavigateBack" class="showcase__back" data-test="button-back" small icon>
              <v-icon color="primary" small>far fa-arrow-left</v-icon>
            </v-btn>
            <v-btn
              v-if="deletable"
              @click="deleteDialogVisible = true"
              class="showcase__delete"
              data-test="button-delete"
              small
              icon
            >
              <v-icon color="grey" small>far fa-trash</v-icon>
            </v-btn>
            <div class="base-data">
              <p class="base-data__title span-2 mb-0 primary--text text-uppercase font-weight-bold">
                {{ $t('rideAssessmentEdit.baseData') }}
              </p>
              <h1 class="base-data__line-name span-2">{{ rideAssessment.lineName }}</h1>
              <div class="base-data__date">
                <app-date-picker v-model="assessmentDateTime" :disabled="readOnly" hide-label />
              </div>
              <div class="base-data__time">
                <app-time-picker v-model="assessmentDateTime" :disabled="readOnly" hide-label hide-details />
              </div>
              <div v-if="rideAssessment.revisionComment" class="revision-comment" data-test="revision-comment">
                <div class="revision-comment__label">{{ $t('rideAssessmentEdit.revisionComment') }}:</div>
                <div>{{ rideAssessment.revisionComment }}</div>
              </div>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-col v-if="!isMobile || isBasePage" cols="12" md="4">
              <div class="navigation-container mx-auto d-flex flex-column">
                <assessment-navigation :items="navigationSections" :select-default-nav-item="isBasePage" />
                <assessment-actions
                  class="navigation-container__actions"
                  :mode="mode"
                  :read-only="readOnly"
                  :complete="rideAssessmentIsComplete"
                  :changed="rideAssessmentHasChanged"
                  :loading="loading"
                  :save-dialog-title="$t('rideAssessmentEdit.saveDialog.title')"
                  :save-dialog-description="$t(saveDialogTranslationKey, [rideAssessment.lineName])"
                  @close="emitNavigateBack"
                  @edit="setReadOnly(false)"
                  @complete="emitComplete"
                />
              </div>
            </v-col>
            <v-col v-if="!isMobile || !isBasePage" cols="12" md="8">
              <router-view></router-view>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-row>
    <app-confirm-dialog
      :value="deleteDialogVisible"
      color="error"
      confirm-text-key="layout.common.delete"
      @cancel="deleteDialogVisible = false"
      @confirm="emitDelete"
    >
      <template #title>{{ $t('rideAssessmentEdit.deleteDialog.title') }}</template>
      <template #text>{{ $t('rideAssessmentEdit.deleteDialog.description', [rideAssessment.lineName]) }}</template>
    </app-confirm-dialog>
    <app-confirm-dialog
      :value="discardChangesDialogVisible"
      color="error"
      confirm-text-key="rideAssessmentEdit.discardDialog.discard"
      @cancel="discardChangesDialogVisible = false"
      @confirm="$emit('navigate-back')"
    >
      <template #title>
        <div data-test="discard-dialog-title">
          {{ $t('rideAssessmentEdit.discardDialog.title') }}
        </div>
      </template>
      <template #text>
        {{ $t('rideAssessmentEdit.discardDialog.description', [rideAssessment.lineName]) }}
      </template>
    </app-confirm-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import AssessmentNavigation from '@/shared/modules/AssessmentEdit/components/AssessmentNavigation.vue';
import AppDatePicker from '@/shared/modules/Global/components/AppDatePicker.vue';
import AppTimePicker from '@/shared/modules/Global/components/AppTimePicker.vue';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import AssessmentActions from '@/shared/modules/AssessmentEdit/components/AssessmentActions.vue';
import { formatApiDateTime, parseApiDateTime } from '@/shared/helper/date';
import { RIDE_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';

import type { RideAssessment, RideAssessmentEditState } from '@/shared/modules/AssessmentEdit/types/ride';
import type { AssessmentEditMode, NavigationSection } from '@/shared/modules/AssessmentEdit/types';

type VuexBindings = {
  rideAssessment: RideAssessmentEditState['rideAssessment'];
  rideAssessmentHasChanged: RideAssessmentEditState['rideAssessmentHasChanged'];
  readOnly: boolean;
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AssessmentNavigation,
    AppDatePicker,
    AppTimePicker,
    AppConfirmDialog,
    AssessmentActions
  },
  props: {
    initialRideAssessment: {
      type: Object as PropType<RideAssessment>,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String as PropType<AssessmentEditMode>,
      default: 'create'
    },
    loading: {
      type: Boolean,
      default: false
    },
    saveDialogTranslationKey: {
      type: String,
      default: 'rideAssessmentEdit.saveDialog.description'
    }
  },
  data() {
    return {
      deleteDialogVisible: false,
      discardChangesDialogVisible: false,
      assessmentUnwatchFunctions: [] as (() => void)[],
      isLoaded: false
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['rideAssessment', 'rideAssessmentHasChanged', 'readOnly']),
    navigationSections(): NavigationSection[] {
      return [
        {
          label: 'rideAssessmentEdit.sections.baseData',
          routeName: 'ride-assessment-base-data',
          status: this.rideAssessment.forms.baseData.status,
          dataTest: 'nav-section-base-data',
          deactivated: this.rideAssessment.forms.baseData.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.stationInformation',
          routeName: 'ride-assessment-station-information',
          status: this.rideAssessment.forms.stationInformation.status,
          dataTest: 'nav-section-station-information',
          deactivated: this.rideAssessment.forms.stationInformation.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.wagonTypes',
          routeName: 'ride-assessment-wagon-types',
          status: this.rideAssessment.forms.wagonTypes.status,
          dataTest: 'nav-section-wagon-types',
          deactivated: this.rideAssessment.forms.wagonTypes.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.wagonState',
          routeName: 'ride-assessment-wagon-state',
          status: this.rideAssessment.forms.wagonState.status,
          dataTest: 'nav-section-wagon-state',
          deactivated: this.rideAssessment.forms.wagonState.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.conductors',
          routeName: 'ride-assessment-conductors',
          status: this.rideAssessment.forms.conductors.status,
          dataTest: 'nav-section-conductors',
          deactivated: this.rideAssessment.forms.conductors.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.securityStaff',
          routeName: 'ride-assessment-security-staff',
          status: this.rideAssessment.forms.securityStaff.status,
          dataTest: 'nav-section-security-staff',
          deactivated: this.rideAssessment.forms.securityStaff.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.onboardService',
          routeName: 'ride-assessment-onboard-service',
          status: this.rideAssessment.forms.onboardService.status,
          dataTest: 'nav-section-onboard-service',
          deactivated: this.rideAssessment.forms.onboardService.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.utilization',
          routeName: 'ride-assessment-utilization',
          status: this.rideAssessment.forms.utilization.status,
          dataTest: 'nav-section-utilization',
          deactivated: this.rideAssessment.forms.utilization.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.inTrainInformation',
          routeName: 'ride-assessment-in-train-information',
          status: this.rideAssessment.forms.inTrainInformation.status,
          dataTest: 'nav-section-in-train-information',
          deactivated: this.rideAssessment.forms.inTrainInformation.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.disruption',
          routeName: 'ride-assessment-disruption',
          status: this.rideAssessment.forms.disruption.status,
          dataTest: 'nav-section-disruption',
          deactivated: this.rideAssessment.forms.disruption.deactivated
        },
        {
          label: 'rideAssessmentEdit.sections.comment',
          routeName: 'ride-assessment-comment',
          status: this.rideAssessment.forms.assessmentComment.status,
          dataTest: 'nav-section-comment',
          deactivated: this.rideAssessment.forms.assessmentComment.deactivated
        }
      ];
    },
    assessmentDateTime: {
      get(): string | undefined {
        return formatApiDateTime(this.rideAssessment.assessmentDateTime);
      },
      set(newDate: string): void {
        const newParsedDate = parseApiDateTime(newDate);
        if (newParsedDate !== undefined) {
          this.rideAssessment.assessmentDateTime = newParsedDate;
        }
      }
    },
    isBasePage(): boolean {
      return this.$route.name === 'ride-assessment';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    rideAssessmentIsComplete(): boolean {
      return 'complete' === this.rideAssessment.assessmentValidationStatus;
    }
  },
  methods: {
    ...mapMutations(RIDE_ASSESSMENT_EDIT_NAMESPACE, [
      'setRideAssessment',
      'setInitialRideAssessment',
      'setReadOnly',
      'setMode',
      'setImagePrefix',
      'setRideAssessmentHasChanged'
    ]),
    ...mapActions(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['updateDependentFields']),
    emitDelete() {
      this.$emit('delete', this.rideAssessment.assessmentId);
    },
    emitComplete() {
      this.$emit('assessment-complete', this.rideAssessment);
    },
    emitNavigateBack() {
      if (this.mode === 'edit' && !this.readOnly && this.rideAssessmentHasChanged) {
        this.discardChangesDialogVisible = true;
        return;
      }

      this.$emit('navigate-back');
    }
  },
  async mounted() {
    this.setRideAssessment(this.initialRideAssessment);

    this.setRideAssessmentHasChanged(false);
    this.setMode(this.mode);
    this.setReadOnly(['edit', 'view'].includes(this.mode));
    this.setImagePrefix(this.mode === 'edit' ? 'ride-assessment-edit-' : '');
    await this.updateDependentFields();
    // the initial assessment to compare needs the correct dependent fields
    // because they don't come from the API
    if (this.mode === 'edit') {
      this.setInitialRideAssessment(structuredClone(this.initialRideAssessment));
    } else {
      this.setInitialRideAssessment(this.initialRideAssessment);
    }
    this.isLoaded = true;
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';

.full-width-page {
  margin-top: -32px;
  margin-inline: -20px;

  max-width: none;
  width: auto;
}

.showcase {
  @include sm-and-up {
    box-shadow: $shadow-lg;
  }

  &__back,
  &__delete {
    position: absolute;
    top: 4px;
  }

  &__back {
    left: 4px;
  }

  &__delete {
    right: 4px;
  }
}

.base-data {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  &__title {
    font-size: 0.75rem;
    letter-spacing: 0.057em;

    @include sm-and-up {
      font-size: 1rem;
    }
  }

  &__line-name {
    font-size: 1.375rem;
    letter-spacing: 0.035em;
    text-align: center;

    @include sm-and-up {
      font-size: 2.875rem;
    }
  }

  &__date,
  &__time {
    max-width: 160px;
  }

  &__date {
    justify-self: end;
  }

  &__time {
    justify-self: start;
  }
}

.revision-comment {
  margin-top: 20px;

  &__label {
    font-weight: bold;
  }
}

.navigation-container {
  @include md-and-up {
    max-width: 280px;
  }

  &__actions {
    position: fixed;
    bottom: 100px;
    right: 35px;

    @include sm-and-up {
      position: relative;
      bottom: 0;
      right: 0;
      align-self: flex-end;
      margin-right: 20px;
      margin-top: 64px;
      margin-bottom: 20px;
    }

    @include md-and-up {
      margin-right: 0;
    }
  }
}

.span-2 {
  grid-column: span 2;
}
</style>
