<template>
  <ride-assessment-edit
    v-if="assessmentLoaded"
    :initial-ride-assessment="rideAssessmentToEdit"
    :deletable="rideAssessmentConfig.deletable"
    :mode="rideAssessmentConfig.mode"
    :loading="updating"
    @delete="deleteAssessment"
    @assessment-complete="saveAssessmentAndQuit"
    @navigate-back="goBack"
  />
  <v-container v-else fluid>
    <v-card width="100%" min-height="70vh" loading />
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { RIDE_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/rideAssessment';
import RideAssessmentEdit from '@/shared/modules/AssessmentEdit/components/RideAssessmentEdit.vue';
import { getConfigurationByName } from '@/pwa/modules/Assessment/services/ride-assessment-edit-config';

import type { RideAssessment } from '@/shared/modules/AssessmentEdit/types/ride';
import type { RideAssessmentState } from '@/pwa/modules/Assessment/types/ride';

type VuexBindings = {
  draftRideAssessments: RideAssessmentState['draftRideAssessments'];
  rideAssessmentConfig: RideAssessmentState['rideAssessmentConfig'];
  rideAssessmentToEdit: RideAssessmentState['rideAssessmentToEdit'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: { RideAssessmentEdit },
  data() {
    return {
      updating: false,
      assessmentLoaded: false
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_NAMESPACE, ['draftRideAssessments', 'rideAssessmentConfig', 'rideAssessmentToEdit']),
    assessmentToEditIsLocal(): boolean {
      return this.rideAssessmentConfig.mode === 'create';
    }
  },
  methods: {
    ...mapMutations(RIDE_ASSESSMENT_NAMESPACE, ['setRideAssessmentConfig', 'setRideAssessmentToEdit']),
    ...mapActions(RIDE_ASSESSMENT_NAMESPACE, [
      'showRideAssessmentCompleteSnackbar',
      'deleteDraftRideAssessment',
      'deleteOnlineRideAssessment',
      'fetchRideAssessment',
      'updateOnlineRideAssessment'
    ]),
    async goBack() {
      await this.$router.push({ name: this.rideAssessmentConfig.goBackRouteName });
    },
    async deleteAssessment(assessmentId: string) {
      if (this.assessmentToEditIsLocal) {
        await this.deleteDraftRideAssessment(assessmentId);
      } else {
        await this.deleteOnlineRideAssessment(assessmentId);
      }
      await this.goBack();
    },
    async saveAssessmentAndQuit(rideAssessment: RideAssessment) {
      if (this.assessmentToEditIsLocal) {
        await this.goBack();
        await this.showRideAssessmentCompleteSnackbar();
      } else {
        this.updating = true;
        await this.updateOnlineRideAssessment(rideAssessment);
        this.updating = false;
        await this.goBack();
      }
    },
    async getRideAssessment(assessmentId: string) {
      if (this.assessmentToEditIsLocal) {
        this.setRideAssessmentToEdit(
          this.draftRideAssessments.find((assessment: RideAssessment) => assessment.assessmentId === assessmentId)
        );
      } else {
        await this.fetchRideAssessment(assessmentId);
      }
    }
  },
  async mounted() {
    const assessmentId = this.$route.params.id;
    const config = this.$route.params.config;
    this.setRideAssessmentConfig(getConfigurationByName(config));
    await this.getRideAssessment(assessmentId);
    if (this.rideAssessmentToEdit === undefined) {
      await this.goBack();
    }
    this.assessmentLoaded = true;
  }
});
</script>
