<template>
  <dialog-select
    :items="selectableWagonTypes"
    :label="$t('rideAssessmentEdit.wagonType')"
    :value="value"
    :hide-details="hideDetails"
    :clearable="clearable"
    item-text="name"
    item-value="id"
    data-test="wagon-type-select"
    @input="$emit('input', $event)"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title :class="{ 'lightgrey--text text--darken-2': !item.onLine }">
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </dialog-select>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ListWagonType } from '@/shared/modules/AssessmentEdit/types/ride';
import { numberSensitiveComparator } from '@/shared/helper/i18n';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

export default Vue.extend({
  components: {
    DialogSelect
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    wagonTypes: {
      type: Array as PropType<ListWagonType[]>,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectableWagonTypes(): ListWagonType[] {
      return [...this.wagonTypes].sort((a, b) =>
        a.onLine === b.onLine ? numberSensitiveComparator.compare(a.name, b.name) : a.onLine ? -1 : 1
      );
    }
  }
});
</script>
