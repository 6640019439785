import { NavigationItem } from '@/shared/modules/Layout/types';
import { RestrictedRoute } from '@/shared/types';

/**
 * This function takes a tree of navigation items and filters out items that are not navigable based on the user's permissions.
 * Some parent items don't have a path themselves, but act as a group for their sub-items. The path of the first sub-item is then assigned to the parent. If none of the sub-items are navigable, the parent is also filtered out.
 */
function filterPermittedNavigationItems(items: NavigationItem[], permittedRoutes: RestrictedRoute[]): NavigationItem[] {
  return (
    items
      // filter out items that are not permitted
      .filter(link => (link.permission ? permittedRoutes.includes(link.permission) : true))
      // recursively filter out sub-items
      .map(link => {
        const filteredSubItems = link.subItems ? filterPermittedNavigationItems(link.subItems, permittedRoutes) : [];
        return {
          ...link,
          subItems: filteredSubItems
        };
      })
      // assign path of first sub-item to parent if parent has no path
      .map(link => ({
        ...link,
        path: link.path === undefined && link.subItems.length ? link.subItems[0].path : link.path
      }))
      // filter out items that have neither a path nor sub-items
      .filter(link => link.path !== undefined || link.subItems.length)
  );
}

/**
 * This function sets which navigation items are active based on the current route path. A Parent item is also set to active if one of its sub-items is active so that the active item is still visible when the navigation drawer is minimized.
 * Parent items also display a badge if one of their sub-items has a badge for the same reason.
 */
function setParentItemValues(items: NavigationItem[] | undefined, currentRoutePath: string): NavigationItem[] {
  if (items === undefined) {
    return [];
  }
  return items.map(link => {
    const newSubItems = setParentItemValues(link.subItems, currentRoutePath);
    return {
      ...link,
      subItems: newSubItems,
      active: newSubItems.some(subItem => subItem.active)
        ? true
        : link.path
        ? currentRoutePath.startsWith(link.path)
        : false,
      badgeValue: link.badgeValue
        ? link.badgeValue
        : newSubItems.some(subItem => subItem.badgeValue !== undefined && subItem.badgeValue > 0)
        ? 1
        : undefined
    };
  });
}

export { filterPermittedNavigationItems, setParentItemValues };
