export default {
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  datetime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  },
  time: {
    hour: '2-digit',
    minute: '2-digit'
  }
} as const;
