import { VueConstructor } from 'vue';
import Qs from 'qs';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import VueAxios from 'vue-axios';

const paramsSerializer = function (params: object): string {
  return Qs.stringify(params, { arrayFormat: 'brackets' });
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_CORE_API_URL,
  timeout: 30000,
  paramsSerializer
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  if (response.headers['content-type'] === 'application/json' && response.data) {
    response.data = camelizeKeys(response.data);
  }

  return response;
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers['Content-Type'] === 'multipart/form-data') {
    return config;
  }

  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }

  return config;
});

export default (Vue: VueConstructor) => {
  Vue.use(VueAxios, axiosInstance);
};

export { axiosInstance };
