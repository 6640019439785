import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { LAYOUT_NAMESPACE, configureLayoutStore } from '@/shared/modules/Layout/stores/layout';
import { ACCOUNT_NAMESPACE, configureAccountStore } from '@/pwa/modules/Account/stores/account';
import {
  STATION_ASSESSMENT_NAMESPACE,
  configureStationAssessmentStore
} from '@/pwa/modules/Assessment/stores/stationAssessment';
import {
  RIDE_ASSESSMENT_NAMESPACE,
  configureRideAssessmentStore
} from '@/pwa/modules/Assessment/stores/rideAssessment';
import {
  STATION_ASSESSMENT_EDIT_NAMESPACE,
  configureStationAssessmentEditStore
} from '@/shared/modules/AssessmentEdit/stores/station-assessment-edit';
import {
  RIDE_ASSESSMENT_EDIT_NAMESPACE,
  configureRideAssessmentEditStore
} from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';
import { vuexPersist } from '@/pwa/plugins/vuex/vuex-persist';

import type { RootState } from '@/pwa/types';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    [LAYOUT_NAMESPACE]: configureLayoutStore(Vue),
    [ACCOUNT_NAMESPACE]: configureAccountStore(Vue),
    [STATION_ASSESSMENT_NAMESPACE]: configureStationAssessmentStore(Vue),
    [STATION_ASSESSMENT_EDIT_NAMESPACE]: configureStationAssessmentEditStore(Vue),
    [RIDE_ASSESSMENT_NAMESPACE]: configureRideAssessmentStore(Vue),
    [RIDE_ASSESSMENT_EDIT_NAMESPACE]: configureRideAssessmentEditStore(Vue)
  },
  plugins: [vuexPersist.plugin]
};

export default new Vuex.Store(store);
