import { formatImageDownloadDateTime } from '@/shared/helper/date';
import {
  DevaluationUnitDependencies,
  RideAssessmentDevaluationUnitSections,
  wagonTypeWithoutToilet
} from '@/shared/modules/AssessmentEdit/services/ride-assessment-statics';

import type {
  DevaluationUnit,
  RideAssessment,
  RideAssessmentFormName,
  ScaleTypeName
} from '@/shared/modules/AssessmentEdit/types/ride';
import { getDiff } from 'recursive-diff';

function getNewRideAssessmentId(): string {
  return crypto.randomUUID();
}

function createRideAssessment(
  lineId: string,
  lineName: string,
  assessmentDateTime: string,
  scaleTypeName: ScaleTypeName
): RideAssessment {
  const variableDevaluationType = scaleTypeName === '3er' ? 'range_na_0_75_100' : 'range_na_0_75_90_100';
  return {
    assessmentId: getNewRideAssessmentId(),
    author: undefined,
    editor: undefined,
    assessmentDateTime,
    editDateTime: undefined,
    exportDateTime: undefined,

    isSynchronizing: false,
    assessmentValidationStatus: 'incomplete',

    forms: {
      baseData: { visited: false, deactivated: false, status: 'initial' },
      stationInformation: { visited: false, deactivated: false, status: 'initial' },
      wagonTypes: { visited: false, deactivated: false, status: 'initial' },
      utilization: { visited: false, deactivated: false, status: 'initial' },
      inTrainInformation: { visited: false, deactivated: false, status: 'initial' },
      wagonState: { visited: false, deactivated: false, status: 'initial' },
      conductors: { visited: false, deactivated: false, status: 'initial' },
      securityStaff: { visited: false, deactivated: false, status: 'initial' },
      onboardService: { visited: false, deactivated: false, status: 'initial' },
      disruption: { visited: false, deactivated: false, status: 'initial' },
      assessmentComment: { visited: false, deactivated: false, status: 'initial' }
    },

    lineId,
    lineName,
    scaleType: scaleTypeName,
    trainNumber: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    departureStationId: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    departureStationCode: '',
    departureTimeNominal: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    departureTimeActual: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    arrivalStationId: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    arrivalTimeNominal: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    arrivalTimeActual: { value: '', status: 'initial', errorMessage: undefined, deactivated: false },
    cancelled: { value: false, status: 'initial', errorMessage: undefined },
    cancellationComment: {
      note: '',
      imageIds: []
    },

    stationInformation: [
      {
        name: 'stationTrainAnnouncement',
        type: 'boolean_na_Yes_No',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'stationDisruptionAnnouncement',
        type: 'boolean_na_Yes_No',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'stationDestinationDisplay',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'stationDisruptionDisplay',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'trainLabel',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    utilization: [
      {
        name: 'seats',
        type: 'range_na_50_75_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'standingRoom',
        type: 'range_na_50_75_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    inTrainInformation: [
      {
        name: 'routeNetworkPlan',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'speakerAnnouncement',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'passengerInformationSystem',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    wagonState: [
      {
        name: 'vehicleNumber',
        type: 'string',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'safetyEndangeringDefects',
        type: 'boolean',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionExteriorShell',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessExteriorShell',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'exteriorGraffiti',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionWindows',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessWindows',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionPassageDoors',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessPassageDoors',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionFloor',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessFloor',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionSeats',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessSeats',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionWasteBins',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessWasteBins',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionInteriorScaffold',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessInteriorScaffold',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'interiorGraffiti',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionLights',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionHeating',
        type: 'range_na_0_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionToilets',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessToilets',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'functionExteriorDoors',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'cleanlinessExteriorDoors',
        type: variableDevaluationType,
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    conductors: [
      {
        name: 'conductorCount',
        type: 'number_na_6',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'conductorInactivity',
        type: 'boolean_na_No_Yes',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'conductorAppearance',
        type: 'range_na_0_50_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'conductorBehavior',
        type: 'range_na_0_25_75_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    securityStaff: [
      {
        name: 'securityStaffCount',
        type: 'number_na_6',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'securityStaffInactivity',
        type: 'boolean_na_No_Yes',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'securityStaffAppearance',
        type: 'range_na_0_50_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'securityStaffBehavior',
        type: 'range_na_0_25_75_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    onboardService: [
      {
        name: 'onboardServiceCount',
        type: 'number_na_6',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'onboardServiceBehavior',
        type: 'range_na_0_25_75_100',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    disruption: [
      {
        name: 'disrupted',
        type: 'boolean',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'disruptionAnnouncement',
        type: 'boolean_na_Yes_No',
        value: undefined,
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'disruptionStartStationId',
        type: 'string',
        value: '',
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      },
      {
        name: 'disruptionEndStationId',
        type: 'string',
        value: '',
        predefinedNotes: [],
        note: '',
        imageIds: [],
        deactivated: false,
        status: 'initial',
        errorMessage: undefined
      }
    ],

    assessmentComment: {
      noComment: false,
      note: '',
      imageIds: [],
      deactivated: false,
      status: 'initial',
      errorMessage: undefined
    },

    wagonTypes: {
      values: [],
      deactivated: false,
      status: 'initial',
      errorMessage: undefined
    },
    assessedWagonTypeId: '',
    assessedWagonTypeName: ''
  };
}

function getImageDownloadTitle(lineName: string, assessmentDateTime: string, unitTitle: string): string {
  const date = formatImageDownloadDateTime(assessmentDateTime);
  const line = lineName.replaceAll('/', '_').replaceAll(' ', '_');
  const unit = unitTitle.replaceAll(' /', '').replaceAll(' ', '_');

  return `${date}_${line}_${unit}`;
}

function getRideAssessmentImageIds(rideAssessment: RideAssessment): string[] {
  const imageIds: string[] = [];

  for (const unit of [
    ...rideAssessment.stationInformation,
    ...rideAssessment.wagonState,
    ...rideAssessment.conductors,
    ...rideAssessment.securityStaff,
    ...rideAssessment.onboardService,
    ...rideAssessment.utilization,
    ...rideAssessment.inTrainInformation,
    ...rideAssessment.disruption,
    ...rideAssessment.wagonTypes.values,
    rideAssessment.assessmentComment,
    rideAssessment.cancellationComment
  ]) {
    unit.imageIds.map(imageId => imageIds.push(imageId));
  }

  return imageIds;
}

/**
 * This function calculates the fields that should be deactivated based on the current assessment state.
 * Theoretically, the state of field A could deactivate field B, which in turn deactivates field C, even though the state of field A does not directly affect field C. This function does not take this into account because we currently do not have such a case.
 */
function calculateFieldsToDeactivate(assessment: RideAssessment): string[] {
  const safetyEndangeringDefects = assessment.wagonState[1];
  const conductorCount = assessment.conductors[0];
  const conductorInactivity = assessment.conductors[1];
  const securityStaffCount = assessment.securityStaff[0];
  const securityStaffInactivity = assessment.securityStaff[1];
  const onboardServiceCount = assessment.onboardService[0];
  const disrupted = assessment.disruption[0];
  const assessedWagonTypeName = assessment.assessedWagonTypeName;

  const fieldsToDeactivate = new Set<string>();
  if (assessment.cancelled.value === true) {
    DevaluationUnitDependencies.cancelled.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'yes'].includes(safetyEndangeringDefects.value)) {
    DevaluationUnitDependencies.safetyEndangeringDefects.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'n/a', '0'].includes(conductorCount.value)) {
    DevaluationUnitDependencies.conductorCount.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'n/a', '0'].includes(conductorInactivity.value)) {
    DevaluationUnitDependencies.conductorInactivity.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'n/a', '0'].includes(securityStaffCount.value)) {
    DevaluationUnitDependencies.securityStaffCount.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'n/a', '0'].includes(securityStaffInactivity.value)) {
    DevaluationUnitDependencies.securityStaffInactivity.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'n/a', '0'].includes(onboardServiceCount.value)) {
    DevaluationUnitDependencies.onboardServiceCount.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if ([undefined, 'no'].includes(disrupted.value)) {
    DevaluationUnitDependencies.disrupted.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if (assessment.assessmentComment.noComment === true) {
    DevaluationUnitDependencies.noComment.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  if (assessedWagonTypeName === wagonTypeWithoutToilet) {
    DevaluationUnitDependencies.noToilet.forEach(path => {
      fieldsToDeactivate.add(path);
    });
  }
  return Array.from(fieldsToDeactivate);
}

function calculateFormsToDeactivate(assessment: RideAssessment): RideAssessmentFormName[] {
  const formsToDeactivate: RideAssessmentFormName[] = RideAssessmentDevaluationUnitSections.filter(sectionName => {
    return assessment[sectionName].every(unit => unit.deactivated);
  });
  if (assessment.wagonTypes.deactivated) {
    formsToDeactivate.push('wagonTypes');
  }
  return formsToDeactivate;
}

function resetDeactivatedDevaluationUnit(unit: DevaluationUnit): void {
  if (unit.deactivated === false) {
    return;
  }
  unit.value = undefined;
  unit.note = '';
  unit.predefinedNotes = [];
  unit.imageIds = [];
}

function resetDeactivatedFields(assessment: RideAssessment): void {
  if (assessment.arrivalTimeActual.deactivated) {
    assessment.arrivalTimeActual.value = '';
  }
  assessment.stationInformation.forEach(resetDeactivatedDevaluationUnit);
  if (assessment.wagonTypes.deactivated) {
    assessment.wagonTypes.values = [];
  }
  assessment.wagonState.forEach(resetDeactivatedDevaluationUnit);
  assessment.conductors.forEach(resetDeactivatedDevaluationUnit);
  assessment.securityStaff.forEach(resetDeactivatedDevaluationUnit);
  assessment.onboardService.forEach(resetDeactivatedDevaluationUnit);
  assessment.utilization.forEach(resetDeactivatedDevaluationUnit);
  assessment.inTrainInformation.forEach(resetDeactivatedDevaluationUnit);
  assessment.disruption.forEach(resetDeactivatedDevaluationUnit);
  if (assessment.assessmentComment.deactivated) {
    assessment.assessmentComment.note = '';
    assessment.assessmentComment.imageIds = [];
  }
}

function mapDeactivatedDevaluationUnitToImageIds(devaluationUnit: DevaluationUnit): string[] {
  return devaluationUnit.deactivated ? devaluationUnit.imageIds : [];
}

function getImageKeysOfDeactivatedFields(assessment: RideAssessment): string[] {
  return [
    ...assessment.stationInformation.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...(assessment.wagonTypes.deactivated ? assessment.wagonTypes.values.flatMap(wagonType => wagonType.imageIds) : []),
    ...assessment.wagonState.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.conductors.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.securityStaff.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.onboardService.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.utilization.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.inTrainInformation.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...assessment.disruption.flatMap(mapDeactivatedDevaluationUnitToImageIds),
    ...(assessment.assessmentComment.deactivated ? assessment.assessmentComment.imageIds : [])
  ];
}

function rideAssessmentHasChanged(initial: RideAssessment, updated: RideAssessment): boolean {
  return getDiff(initial, updated).length > 0;
}

export {
  calculateFieldsToDeactivate,
  calculateFormsToDeactivate,
  createRideAssessment,
  getImageDownloadTitle,
  getImageKeysOfDeactivatedFields,
  getRideAssessmentImageIds,
  mapDeactivatedDevaluationUnitToImageIds,
  resetDeactivatedFields,
  resetDeactivatedDevaluationUnit,
  rideAssessmentHasChanged
};
