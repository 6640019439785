<template functional>
  <div class="count">
    <span class="count__number mb-1 mb-sm-2">{{ props.count }}</span>
    <span class="count__label text-button font-weight-bold grey--text text--darken-1">
      <v-icon :color="props.color" class="mr-2" x-small>fas fa-circle</v-icon>
      {{ props.label }}
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    count: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

.count {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include sm-and-up {
    // adjust position of children for visual balance
    padding-bottom: 1.5rem;
  }

  &__number {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
  }

  &__label {
    white-space: nowrap;
    line-height: 1.2857;
  }
}
</style>
