<template>
  <v-main class="main">
    <div class="pt-8 px-5 pa-sm-12">
      <router-view />
    </div>
    <app-global-snackbar />
  </v-main>
</template>

<script lang="ts">
import Vue from 'vue';
import AppGlobalSnackbar from '@/shared/modules/Global/components/AppGlobalSnackbar.vue';

export default Vue.extend({
  components: {
    AppGlobalSnackbar
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';

.main {
  // prevent content width from changing when drawer is opened
  --navigation-drawer-width: 0px;
  padding-left: var(--navigation-drawer-width) !important;

  @include sm-and-up {
    --navigation-drawer-width: 130px;
  }
}
</style>
