export default {
  login: {
    title: 'Hallo.',
    phoneTitle: 'Willkommen beim',
    subtitle: 'Willkommen beim VRR Profitester-Reporting',
    phoneSubtitle: 'Profitester-Reporting',
    submit: 'Login',
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    badCredentials: 'Login fehlgeschlagen'
  }
};
