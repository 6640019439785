<template>
  <v-dialog v-model="timePicker" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-bind="$attrs"
        :label="hideLabel ? undefined : label !== '' ? label : $t('layout.common.time')"
        :value="!value ? '' : $t('layout.time', { time: displayTime })"
        :style="inputStyles"
        readonly
      >
        <template #append>
          <v-icon class="mt-1" small>fal fa-clock</v-icon>
        </template>
      </v-text-field>
    </template>

    <!-- Use "v-if" in order to remove the digital-time-picker component from DOM when closed.
         This is needed to trigger the mounted lifecycle hook every time when opened. -->
    <digital-time-picker
      v-if="timePicker"
      v-model="time"
      :future-disabled="futureDisabled"
      :resettable="resettable"
      @ok="ok"
      @reset="reset"
    />
  </v-dialog>
</template>

<script lang="ts">
import { format, isValid, parseISO } from 'date-fns';
import Vue, { PropType } from 'vue';
import DigitalTimePicker from '@/shared/modules/Global/components/DigitalTimePicker.vue';
import { formatPickerDate, isAfter } from '@/shared/helper/date';

export default Vue.extend({
  inheritAttrs: false,
  components: {
    DigitalTimePicker
  },
  data() {
    return {
      timePicker: false,
      date: '',
      time: ''
    };
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    max: {
      type: Date as PropType<Date | undefined>,
      default: undefined
    },
    resettable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayTime(): string {
      const parsedDate = parseISO(`${this.date} ${this.time}`);
      const date = isValid(parsedDate) ? parsedDate : new Date();
      return this.$d(date, 'time');
    },
    futureDisabled(): boolean {
      return isAfter(parseISO(`${this.date} 23:59`), this.maxDate);
    },
    inputStyles(): Partial<CSSStyleDeclaration> {
      return {
        maxWidth: this.maxWidth
      };
    },
    maxDate(): Date | number {
      return this.max ? new Date(this.max) : Date.now();
    }
  },
  methods: {
    setToMax() {
      this.date = formatPickerDate(this.maxDate);
      this.time = format(this.maxDate, 'HH:mm');
    },
    ok() {
      this.timePicker = false;
    },
    reset() {
      this.date = '';
      this.time = '';
      this.timePicker = false;
    }
  },
  watch: {
    date(date) {
      if (isAfter(parseISO(`${date} ${this.time}:00`), this.maxDate)) {
        this.setToMax();
      }

      this.$emit('input', `${date} ${this.time}:00`);
    },
    time(time) {
      if (isAfter(parseISO(`${this.date} ${time}:00`), this.maxDate)) {
        this.setToMax();
      }

      this.$emit('input', `${this.date} ${time}:00`);
    },
    value: {
      immediate: true,
      handler(value) {
        this.date = '';
        this.time = '';
        if (!value) return;

        try {
          const [date, time] = value.split(' ');

          if (!date || !time) return;

          this.date = date.trim();
          this.time = time.trim().slice(0, 5);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

::v-deep .picker-header {
  background: $gradient-primary;
}
</style>
