<template>
  <v-container class="wrapper pa-6">
    <h1 class="greeting darkgrey--text text--darken-1 text-center">{{ $t('assessment.welcome') }}</h1>
    <div class="d-flex justify-center justify-sm-space-around align-center">
      <animated-icon class="icon subway d-none d-xl-block" icon="subway" @ready="setIconReady($event, 0)" />
      <animated-icon class="icon train" icon="train" @ready="setIconReady($event, 1)" />
      <animated-icon
        class="icon pen-and-paper d-none d-xl-block"
        icon="pen-and-paper"
        @ready="setIconReady($event, 2)"
      />
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import AnimatedIcon from '@/shared/modules/Global/components/AnimatedIcon.vue';
import type { LottiePlayer } from '@lottiefiles/lottie-player';

export default Vue.extend({
  components: { AnimatedIcon },
  data() {
    return {
      icons: [] as LottiePlayer[],
      currentlyAnimatingIcon: 0,
      animationTimeout: -1,
      animationIntermission: 2000,
      animationIconCount: 2
    };
  },
  computed: {
    animationValidViewport(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  methods: {
    setIconReady(lottieElement: LottiePlayer, index: number) {
      this.icons[index] = lottieElement;
      this.animateIcons();
    },
    animateIcons() {
      if (!this.allIconsReady()) {
        return;
      }

      this.icons.forEach(icon => {
        icon.removeEventListener('complete', this.animateNextIcon);
        icon.addEventListener('complete', this.animateNextIcon);
        icon.count = this.animationIconCount;
        icon.loop = true;
        icon.stop();
      });

      this.icons[0].play();
    },
    animateNextIcon() {
      this.icons[this.currentlyAnimatingIcon].stop();

      if (!this.animationValidViewport) {
        return;
      }

      // wait between animations
      if (this.animationTimeout === -1) {
        this.animationTimeout = window.setTimeout(this.animateNextIcon, this.animationIntermission);
        return;
      }
      this.animationTimeout = -1;

      this.currentlyAnimatingIcon =
        this.currentlyAnimatingIcon === this.icons.length - 1 ? 0 : this.currentlyAnimatingIcon + 1;

      this.icons[this.currentlyAnimatingIcon].play();
    },
    allIconsReady() {
      const readyStates = this.icons.filter(icon => {
        return icon !== undefined;
      });
      return readyStates.length === 3;
    }
  },
  watch: {
    animationValidViewport: {
      handler: function () {
        if (this.animationValidViewport) {
          this.animateIcons();
        } else {
          this.icons.forEach(icon => icon.stop());
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.icons.forEach(icon => icon.removeEventListener('completed', this.animateNextIcon));
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';
@import '@/shared/styles/media.scss';

.wrapper {
  background-color: $grey1;
  height: 100%;
}

.greeting {
  font-size: 2rem;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 1.375;
}

.subway {
  max-width: 133px;
}

.train {
  max-width: 128px;

  @include sm-and-up {
    max-width: 160px;
  }
}

.pen-and-paper {
  max-width: 140px;
}
</style>
