import { Profitester, Station } from '@/shared/plugins/database/profitester';
import { numberSensitiveComparator } from '@/shared/helper/i18n';

class DatabaseHelper {
  database!: Profitester;

  constructor() {
    this.database = new Profitester();
  }

  async getSortedStationsOnLine(lineId: string) {
    let stations: Station[] = [];
    try {
      const stationIds = (await this.database.lines.get(lineId))?.trainStations ?? [];
      stations = (await this.database.stations.bulkGet(stationIds)).filter(
        (station): station is Station => station !== undefined
      );
    } catch (error) {
      console.error(error);
    }
    return stations.sort((stationA, stationB) => numberSensitiveComparator.compare(stationA.name, stationB.name));
  }
}

const dbHelper = new DatabaseHelper();

export { dbHelper };
