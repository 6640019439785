import { DevaluationUnitKey } from '@/shared/modules/AssessmentEdit/types/station';

const devaluationUnitInformation: { [K in DevaluationUnitKey]?: string } = {
  pollution:
    '<p>Verschmutzung (Boden / Wände / Scheiben / Ausstattungsgegenstände)</p>\r\n\r\n<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  damages:
    '<p>Schäden an Wänden / Bodenbelägen / Decken bzw. andere bauliche Mängel</p>\r\n\r\n<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  vegetation:
    '<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  graffiti:
    '<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  moisturePuddles:
    '<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  odourNuisance:
    '<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>',
  lyingAroundGarbage:
    '<p>+ &nbsp; &nbsp;keine Verunreinigungen / Mängel<br />\r\n0 &nbsp; &nbsp;leichte Verunreinigungen / Mängel<br />\r\n- &nbsp; &nbsp; mittlere Verunreinigungen / Mängel<br />\r\n- - &nbsp;starke Verunreinigungen / Mängel</p>'
};

export { devaluationUnitInformation };
