import Vue from 'vue';

import type { CurrentUser, Project, UserRole } from '@/shared/types';
import { includes } from '@/shared/helper/typescript';
import { RIDE, STATION } from '@/shared/helper/permissions';

export const authUser = <Key extends keyof CurrentUser>(property: Key): CurrentUser[Key] | null => {
  const currentUser = Vue.prototype.$auth.user() as CurrentUser | null;
  if (null === currentUser) {
    return null;
  }

  return currentUser[property] ?? null;
};

export const authCheck = (role: unknown): boolean => {
  return Vue.prototype.$auth.check(role);
};

function isProjectAssigment(role: UserRole | Project): role is Project {
  const projectRoles: Project[] = [RIDE, STATION];
  return includes(projectRoles, role);
}

function isUserRole(role: UserRole | Project): role is UserRole {
  return !isProjectAssigment(role);
}

export const getUserRoleAndProjects = (
  roles: (UserRole | Project)[] | undefined
): { userRole: UserRole; projects: Project[] } | Error => {
  if (roles === undefined) return new Error('undefinedRoles');

  const userRoles = roles.filter(isUserRole);
  const projects = roles.filter(isProjectAssigment);

  if (userRoles.length !== 1) return new Error('invalidRoleCount');
  if (projects.length === 0) return new Error('missingProjectAssignment');

  return { userRole: userRoles[0], projects };
};
