<template>
  <v-container fluid class="px-0 px-sm-3" :class="{ 'mb-16': isOffline }" data-test="station-assessment-list">
    <v-overlay :value="loading" opacity="0.8" data-test="base-data-overlay">
      <div class="text-center">
        <v-progress-circular color="white" size="60" class="mb-6" indeterminate />
        <div class="text-h6">{{ $t('assessment.loadingBaseData') }}</div>
      </div>
    </v-overlay>
    <v-card width="100%" min-height="70vh" class="desktop-card">
      <v-row
        class="showcase flex-nowrap overflow-x-hidden pos-relative mx-n5 mx-sm-0 mt-n11 mt-sm-0"
        :class="{ 'showcase--hidden': baseDataOverlayVisible }"
        no-gutters
      >
        <v-col cols="12" md="3" lg="6">
          <welcome />
        </v-col>
        <v-col cols="12" md="9" lg="6" class="white" :class="[greetUser ? 'slide-in' : 'slide-in--finished']">
          <station-assessment-statistics />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" lg="6" class="assessment-base-data-container pos-relative">
          <assessment-base-data-card
            v-if="baseDataOverlayVisible"
            label="stationAssessment.stationNameOrCode"
            :items="autocompleteStations"
            @close="hideBaseDataOverlay"
            @baseDataComplete="createAssessment"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!baseDataOverlayVisible || !isPhone">
        <v-col cols="12" class="phone-card-wrapper py-11">
          <v-card class="phone-card px-6 px-sm-0 py-4 py-sm-0">
            <v-btn
              v-if="!baseDataOverlayVisible"
              :title="$t('stationAssessment.actions.createAssessment')"
              color="primary"
              elevation="2"
              class="new-assessment-button"
              data-test="button-new-assessment"
              fab
              @click="showBaseDataOverlay"
            >
              <v-icon>far fa-plus</v-icon>
            </v-btn>
            <app-tabs v-model="activeTab" class="mb-3">
              <v-tab class="mr-4" data-test="tab-drafts">
                {{ $t('stationAssessment.drafts') }}
                <span v-if="!isPhone" class="ml-1 lightgrey--text">({{ draftAssessmentCount }})</span>
              </v-tab>
              <v-tab data-test="tab-transmitted">
                {{ $t('stationAssessment.transmitted') }}
                <span v-if="!isPhone" class="ml-1 lightgrey--text">({{ transmittedAssessmentCount }})</span>
              </v-tab>
            </app-tabs>
            <hr class="divider white darken-1 d-none d-sm-block mb-3" />
            <!-- show table status indicator -->
            <v-tabs-items v-model="activeTab" class="overflow-visible">
              <v-tab-item>
                <station-assessment-list
                  :assessments="draftAssessments"
                  :search="draftAssessmentSearch"
                  :options="draftAssessmentOptions"
                  :columns="draftTableColumns"
                  data-test="draft-assessment-list"
                  @update:options="setDraftAssessmentOptions"
                  @update:search="setDraftAssessmentSearch"
                  @deleteAssessment="deleteStationAssessment"
                  @transmitAssessment="transmitStationAssessment"
                  @assessmentSelected="editAssessment"
                />
              </v-tab-item>
              <v-tab-item>
                <station-assessment-list
                  :assessments="transmittedAssessments"
                  :search="transmittedAssessmentSearch"
                  :options="transmittedAssessmentOptions"
                  :columns="transmittedTableColumns"
                  data-test="transmitted-assessment-list"
                  @update:options="setTransmittedAssessmentOptions"
                  @update:search="setTransmittedAssessmentSearch"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <offline-snackbar />
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import importHelper from '@/pwa/helper/import';
import Welcome from '@/pwa/modules/Assessment/components/Welcome.vue';
import StationAssessmentStatistics from '@/pwa/modules/Assessment/components/StationAssessmentStatistics.vue';
import StationAssessmentList from '@/pwa/modules/Assessment/components/StationAssessmentList.vue';
import AssessmentBaseDataCard from '@/pwa/modules/Assessment/components/AssessmentBaseDataCard.vue';
import AppTabs from '@/shared/modules/Global/components/AppTabs.vue';
import OfflineSnackbar from '@/shared/modules/Global/components/OfflineSnackbar.vue';
import { createEmptyStationAssessment } from '@/shared/modules/AssessmentEdit/services/station-assessment-service';
import { STATION_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/stationAssessment';
import { authUser } from '@/shared/helper/vue-auth';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { VueOfflineMixin } from '@/shared/modules/Global/mixins/VueOfflineMixin';

import type { StationAssessmentShort, StationAssessmentTableHeaderValue } from '@/pwa/modules/Assessment/types/station';
import type { Station } from '@/shared/plugins/database/profitester';
import type { AssessmentBaseData, BaseDataListItem } from '@/pwa/modules/Assessment/types';

function stationAlreadyAssessed(assessments: StationAssessmentShort[], station: Station) {
  return assessments.findIndex(assessment => assessment.stationCode === station.code) !== -1;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof VueOfflineMixin>>).extend({
  components: {
    Welcome,
    StationAssessmentStatistics,
    StationAssessmentList,
    AssessmentBaseDataCard,
    AppTabs,
    OfflineSnackbar
  },
  mixins: [VueOfflineMixin],
  data() {
    return {
      loading: false,
      greetUser: false,
      activeTab: 0,
      baseDataOverlayVisible: false,
      dbStations: [] as Station[],
      draftTableColumns: [
        'statusIndicator',
        'actions',
        'stationName',
        'stationCode',
        'assessmentDateTime',
        'assessmentStatus'
      ] as StationAssessmentTableHeaderValue[],
      transmittedTableColumns: [
        'statusIndicatorPlaceholder',
        'stationName',
        'stationCode',
        'assessmentDateTime',
        'exportDateTime'
      ] as StationAssessmentTableHeaderValue[]
    };
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_NAMESPACE, [
      'globalStationAssessments',
      'draftAssessmentSearch',
      'draftAssessmentOptions',
      'transmittedAssessmentSearch',
      'transmittedAssessmentOptions'
    ]),
    ...mapGetters(STATION_ASSESSMENT_NAMESPACE, [
      'draftAssessments',
      'draftAssessmentCount',
      'transmittedAssessments',
      'transmittedAssessmentCount'
    ]),
    isPhone(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    autocompleteStations(): BaseDataListItem[] {
      return this.dbStations
        .map(station => {
          const disabled = stationAlreadyAssessed(this.globalStationAssessments, station);
          return { ...station, disabled };
        })
        .sort((stationA, stationB) => stationA.name.localeCompare(stationB.name));
    }
  },
  methods: {
    ...mapMutations(STATION_ASSESSMENT_NAMESPACE, [
      'addStationAssessment',
      'setDraftAssessmentSearch',
      'setDraftAssessmentOptions',
      'setTransmittedAssessmentSearch',
      'setTransmittedAssessmentOptions'
    ]),
    ...mapActions(STATION_ASSESSMENT_NAMESPACE, [
      'fetchGlobalStationAssessments',
      'transmitStationAssessment',
      'deleteStationAssessment',
      'deleteOldTransmittedAssessments'
    ]),
    showBaseDataOverlay(): void {
      // create router history entry so that phone users can close the station selection by navigating back
      this.$router.push({ query: { ...this.$route.query, createAssessment: '1' } });
      this.fetchGlobalStationAssessments();
    },
    hideBaseDataOverlay(): void {
      this.$router.back();
    },
    createAssessment({ id, assessmentDateTime }: AssessmentBaseData): void {
      const stationCode = this.dbStations.find(station => station.id === id)?.code ?? '';
      const stationName = this.dbStations.find(station => station.id === id)?.name ?? '';
      const currentUsername = authUser('fullName');
      if (currentUsername !== null) {
        const newStationAssessment = createEmptyStationAssessment(
          stationCode,
          stationName,
          assessmentDateTime,
          currentUsername
        );
        this.addStationAssessment(newStationAssessment);
        this.editAssessment(newStationAssessment.assessmentId);
      }
    },
    editAssessment(id: string) {
      this.$router.push({ name: 'station-assessment', params: { id } });
    }
  },
  async mounted() {
    this.greetUser = this.$route.query.greet === '1';
    const createAssessmentFlag = this.$route.query.createAssessment === '1';
    if (this.greetUser || createAssessmentFlag) {
      this.$router.replace({
        query: { ...this.$route.query, greet: undefined, createAssessment: undefined }
      });
    }

    if (this.greetUser) {
      this.loading = true;
      await importHelper.updateDatabase();
      this.loading = false;
    }

    this.dbStations = await this.$database.stations.where('showInStationReporting').equals(1).toArray();

    this.deleteOldTransmittedAssessments();
  },
  beforeRouteUpdate(to, from, next) {
    this.baseDataOverlayVisible = to.query.createAssessment !== undefined;
    next();
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';
@import '@/shared/styles/colors.scss';

.showcase {
  border-radius: 4px;
  box-shadow: $shadow-lg;

  &--hidden::after {
    @include sm-and-up {
      @include md-and-down {
        content: '';
        background-color: $grey1;
        position: absolute;
        inset: 0;
      }
    }
  }
}

.desktop-card {
  --inline-margin: 88px;

  @include xs-only {
    --inline-margin: 24px;
    background-color: transparent;
    box-shadow: none !important;
  }
}

.assessment-base-data-container {
  margin-inline: 0;

  @include sm-and-up {
    margin-inline: var(--inline-margin);
  }
}

.slide-in {
  @include sm-and-down {
    animation: slide-left 0.6s ease-in-out 5s forwards;

    &--finished {
      transform: translateX(-100%);
    }
  }
}

.phone-card-wrapper {
  padding-inline: 0;

  // ensure that assessment list can be scrolled under new assessment button
  margin-bottom: 90px;

  @include sm-and-up {
    padding-inline: var(--inline-margin) !important;
  }
}

.phone-card {
  @include sm-and-up {
    background-color: transparent;
    box-shadow: none !important;
  }
}

.new-assessment-button {
  position: fixed;
  bottom: 100px;
  right: 35px;
  z-index: 1;

  @include sm-and-up {
    position: absolute;
    top: -72px;
    left: 0;
  }
}

.divider {
  border-top-width: 0;
  margin-inline: calc(-1 * var(--inline-margin));
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
