import type {
  AccessibilityDevaluationUnitKey,
  DevaluationUnitKey,
  PassengerInformationDevaluationUnitKey,
  QualityOfStayDevaluationUnitConditionKey,
  QualityOfStayDevaluationUnitRangeKey
} from '@/shared/modules/AssessmentEdit/types/station';

const QualityOfStayOneDevaluationUnits: QualityOfStayDevaluationUnitRangeKey[] = [
  'lyingAroundGarbage',
  'moisturePuddles',
  'pollution',
  'graffiti',
  'odourNuisance',
  'vegetation',
  'damages'
];

const QualityOfStayTwoDevaluationUnits: QualityOfStayDevaluationUnitConditionKey[] = [
  'elevators',
  'lighting',
  'seats',
  'showcases',
  'ticketMachines',
  'wasteBins',
  'weatherProtection'
];

const StaticPassengerInformationDevaluationUnits: PassengerInformationDevaluationUnitKey[] = [
  'stationNameplates',
  'timetableDisplay'
];

const DynamicPassengerInformationDevaluationUnits: PassengerInformationDevaluationUnitKey[] = [
  'displaysDsa',
  'timeDisplay'
];

const AccessiblityDevaluationUnits: AccessibilityDevaluationUnitKey[] = ['platformAccess', 'tactileGuidanceSystem'];

const DevaluationUnitImages: {
  [K in DevaluationUnitKey]: string;
} = {
  lyingAroundGarbage: require('@/shared/assets/icons/dustpan.svg'),
  damages: require('@/shared/assets/icons/broken-glass.svg'),
  elevators: require('@/shared/assets/icons/arrows-up-down.svg'),
  graffiti: require('@/shared/assets/icons/spray-can.svg'),
  lighting: require('@/shared/assets/icons/lightbulb.svg'),
  moisturePuddles: require('@/shared/assets/icons/waterdrop.svg'),
  odourNuisance: require('@/shared/assets/icons/nose-scent.svg'),
  pollution: require('@/shared/assets/icons/stain.svg'),
  seats: require('@/shared/assets/icons/seats.svg'),
  showcases: require('@/shared/assets/icons/window.svg'),
  ticketMachines: require('@/shared/assets/icons/ticket-machine.svg'),
  vegetation: require('@/shared/assets/icons/leaves.svg'),
  wasteBins: require('@/shared/assets/icons/garbage-can.svg'),
  weatherProtection: require('@/shared/assets/icons/umbrella.svg'),
  displaysDsa: require('@/shared/assets/icons/info-bubble.svg'),
  stationNameplates: require('@/shared/assets/icons/nameplate.svg'),
  timeDisplay: require('@/shared/assets/icons/clock.svg'),
  timetableDisplay: require('@/shared/assets/icons/calendar-clock.svg'),
  platformAccess: require('@/shared/assets/icons/person-wheelchair.svg'),
  tactileGuidanceSystem: require('@/shared/assets/icons/eye-crossed.svg')
};

export {
  QualityOfStayOneDevaluationUnits,
  QualityOfStayTwoDevaluationUnits,
  StaticPassengerInformationDevaluationUnits,
  DynamicPassengerInformationDevaluationUnits,
  AccessiblityDevaluationUnits,
  DevaluationUnitImages
};
