<template>
  <v-navigation-drawer :value="drawerOpen" app @input="emitDrawerChange" data-test="phone-drawer">
    <v-container class="drawer px-0 pb-0">
      <div class="user-info d-flex flex-column align-center">
        <div class="user-info__visual rounded-circle d-flex align-center justify-center">
          <v-icon size="55" color="darkgrey" class="text--darken-1">fal fa-head-side</v-icon>
        </div>
        <span class="user-info__text darkgrey--text text--darken-1 font-weight-bold my-3">{{ fullName }}</span>
      </div>
      <v-list data-test="list">
        <v-list-item v-for="link in navigationLinks" :key="link.text" :to="link.route" class="pl-7">
          <v-list-item-icon class="my-3 mr-4">
            <v-icon color="primary" size="30">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="link-text black--text text--lighten-3">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
    <template #append>
      <v-container class="logout">
        <v-row justify="center" class="external-links mx-0">
          <v-col cols="auto">
            <div class="link-text lightgrey--text text--darken-2">
              <a
                class="text-decoration-none lightgrey--text text--darken-2"
                href="https://www.vrr.de/de/datenschutz/"
                target="_blank"
                rel="noreferrer"
                >{{ $t('layout.externalLinks.dataPrivacy') }}</a
              >
              |
              <a
                class="text-decoration-none lightgrey--text text--darken-2"
                href="https://www.vrr.de/de/impressum/"
                target="_blank"
                rel="noreferrer"
                >{{ $t('layout.externalLinks.imprint') }}</a
              >
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn text class="font-weight-bold" @click="$emit('logout')">{{ $t('layout.logout') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    drawerOpen: {
      type: Boolean,
      required: true
    },
    fullName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      navigationLinks: [
        { text: this.$t('layout.assessment'), icon: 'fal fa-clipboard-list-check', route: '/assessment' },
        { text: this.$t('layout.passwordChange'), icon: 'fal fa-key', route: '/account/password-change' }
      ]
    };
  },
  methods: {
    emitDrawerChange(openState: boolean) {
      this.$emit('update:drawerOpen', openState);
    }
  }
});
</script>

<style scoped lang="scss">
.drawer {
  padding-top: max(80px, 14vh);
}

.user-info {
  margin-bottom: 4.6vh;

  &__visual {
    border: 4px solid var(--v-primary-base);
    height: clamp(80px, 15vh, 100px);
    width: clamp(80px, 15vh, 100px);
  }

  &__text {
    font-size: 1.25rem;
    letter-spacing: 0.0375em;
    line-height: 1.5;
  }
}

.link-text {
  font-size: 0.875rem;
  letter-spacing: 0.07em;
}

.external-links {
  margin-bottom: 6vh;
}

.logout {
  margin-bottom: 9vh;
}
</style>
