<template>
  <v-img :src="imagePath" position="left bottom" contain />
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icons: {
        signal: require(`@/shared/assets/icons/signal.svg`),
        trainStationWhite: require(`@/shared/assets/icons/station-white.svg`),
        trainStationGreen: require(`@/shared/assets/icons/station-green.svg`)
      }
    };
  },
  computed: {
    imagePath(): string | undefined {
      const icon = Object.entries(this.icons).find(([iconName]) => iconName === this.name);
      return icon === undefined ? undefined : icon[1];
    }
  }
});
</script>

<style scoped></style>
