<template>
  <v-btn-toggle
    :value="value"
    class="number-range-option d-flex justify-space-between"
    :class="{ 'number-range-option--selected': value !== undefined }"
    data-test="number-range-option"
    group
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="disabled"
      :ripple="false"
      max-height="34"
      class="number-range-option__button ma-0 font-weight-medium"
      :class="{ 'number-range-option__button--na': option.value === 'n/a' }"
    >
      {{ option.label }}
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

const rangeDevaluationUnitOptions: Record<string, { label: string; value: string }[]> = {
  range_na_0_75_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '0%',
      value: '0'
    },
    {
      label: '75%',
      value: '75'
    },
    {
      label: '100%',
      value: '100'
    }
  ],
  range_na_0_75_90_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '0%',
      value: '0'
    },
    {
      label: '75%',
      value: '75'
    },
    {
      label: '90%',
      value: '90'
    },
    {
      label: '100%',
      value: '100'
    }
  ],
  range_na_0_25_75_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '0%',
      value: '0'
    },
    {
      label: '25%',
      value: '25'
    },
    {
      label: '75%',
      value: '75'
    },
    {
      label: '100%',
      value: '100'
    }
  ],
  range_na_0_50_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '0%',
      value: '0'
    },
    {
      label: '50%',
      value: '50'
    },
    {
      label: '100%',
      value: '100'
    }
  ],
  range_na_50_75_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '<51%',
      value: '50'
    },
    {
      label: '51% - 75%',
      value: '75'
    },
    {
      label: '>75%',
      value: '100'
    }
  ],
  range_na_0_100: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: '0%',
      value: '0'
    },
    {
      label: '100%',
      value: '100'
    }
  ],
  boolean_na_Yes_No: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: 'Ja',
      value: '100'
    },
    {
      label: 'Nein',
      value: '0'
    }
  ],
  boolean_na_No_Yes: [
    {
      label: 'k.A.',
      value: 'n/a'
    },
    {
      label: 'Ja',
      value: '0'
    },
    {
      label: 'Nein',
      value: '100'
    }
  ]
};

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    type: {
      type: String as PropType<string>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options(): { value: string; label: string }[] {
      return rangeDevaluationUnitOptions[this.type] ?? [];
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.number-range-option {
  max-width: 300px;
  gap: 8px;

  &--selected > &__button.v-btn {
    opacity: 0.3;
  }

  &__button {
    --border-color: var(--v-darkgrey-darken1);

    border: 1px solid var(--border-color) !important;
    border-radius: 5px !important;
    flex: 1 1 60px;

    letter-spacing: 0;
    text-transform: none;
    transition-property: box-shadow, transform, opacity, border-color, background-color;

    &.v-btn.v-btn--active {
      --border-color: var(--v-primary-base);
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base);
      opacity: 1;

      &.v-btn--disabled {
        color: var(--v-white-darken2) !important;
      }
    }

    &.v-btn.v-btn--disabled {
      --border-color: transparent;

      cursor: not-allowed;
      pointer-events: auto;
    }

    &--na {
      flex: 0 1 50px;
    }

    &::before {
      // remove white tint
      display: none;
    }

    & ::v-deep .v-ripple__container {
      border-radius: 6px;
    }
  }
}
</style>
