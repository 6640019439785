<template>
  <div class="circle-icon">
    <animated-icon class="circle-icon__icon" v-bind="$attrs" v-on="$listeners" :icon="icon" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AnimatedIcon from '@/shared/modules/Global/components/AnimatedIcon.vue';

export default Vue.extend({
  inheritAttrs: false,
  components: {
    AnimatedIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.circle-icon {
  aspect-ratio: 1 / 1;
  background-color: $grey1;
  display: grid;
  place-items: center;
  border-radius: 50%;

  &__icon {
    max-height: 50%;
  }
}
</style>
