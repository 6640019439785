var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.onVisibilityChange),expression:"onVisibilityChange"}],staticClass:"v-picker v-card v-picker--time theme--light"},[_c('div',{staticClass:"v-picker__title picker-header"},[_c('div',{staticClass:"v-time-picker-title"},[_c('div',{staticClass:"v-time-picker-title__time"},[_c('div',{staticClass:"display-3 font-weight-medium"},[_vm._v(_vm._s(_vm.value))])])])]),_c('div',{staticClass:"v-picker__body theme--light",staticStyle:{"width":"290px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('ul',{staticClass:"picker-hour v-date-picker-years"},_vm._l((24),function(count){return _c('li',{key:count,ref:String(count - 1).padStart(2, '0') === _vm.hour ? 'activeHour' : '',refInFor:true,staticClass:"picker-entry",class:{
                'active primary--text': String(count - 1).padStart(2, '0') === _vm.hour,
                disabled: _vm.isHourDisabled(count - 1)
              },on:{"click":function($event){return _vm.onClickHour(count - 1)}}},[_vm._v(" "+_vm._s(String(count - 1).padStart(2, '0'))+" ")])}),0)])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('ul',{staticClass:"picker-minute v-date-picker-years"},_vm._l((60),function(count){return _c('li',{key:count,ref:String(count - 1).padStart(2, '0') === _vm.minute ? 'activeMinute' : '',refInFor:true,staticClass:"picker-entry",class:{
                'active primary--text': String(count - 1).padStart(2, '0') === _vm.minute,
                disabled: _vm.isMinuteDisabled(count - 1)
              },on:{"click":function($event){return _vm.onClickMinute(count - 1)}}},[_vm._v(" "+_vm._s(String(count - 1).padStart(2, '0'))+" ")])}),0)])]),_c('v-col',{staticClass:"pt-6 pb-4 px-6",class:_vm.resettable ? 'd-flex justify-space-between' : 'text-right',attrs:{"cols":"12"}},[(_vm.resettable)?_c('v-btn',{staticClass:"px-5",attrs:{"data-test":"button-time-picker-reset","depressed":""},on:{"click":function($event){return _vm.$emit('reset')}}},[_vm._v(" "+_vm._s(_vm.$t('layout.common.reset'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"px-9",attrs:{"color":"primary","data-test":"button-time-picker-ok","depressed":""},on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(" "+_vm._s(_vm.$t('layout.common.ok'))+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }