<template>
  <v-btn-toggle
    :value="value"
    class="input-option d-flex"
    :class="{ 'input-option--selected': value !== undefined }"
    color="primary"
    group
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="option in inputOptions"
      :key="option.value"
      :value="option.value"
      :ripple="false"
      max-height="40"
      class="input-option__button ma-0 font-weight-medium"
    >
      {{ option.label }}
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    inputOptions: {
      type: Array as PropType<{ label: string; value: boolean }[]>,
      required: true
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/colors.scss';

.input-option {
  &--selected > &__button.v-btn {
    opacity: 0.3;
  }

  &__button {
    --border-color: var(--v-darkgrey-darken1);
    --border-radius: 5px;

    border: 1px solid var(--border-color) !important;
    border-right-width: 0 !important;
    flex: 1 1 60px;

    letter-spacing: 0;
    text-transform: none;
    transition-property: box-shadow, transform, opacity, border-color, background-color;

    &.v-btn.v-btn--active {
      --border-color: var(--v-primary-base);
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base);
      opacity: 1;

      &.v-btn--disabled {
        color: var(--v-white-darken2) !important;
      }
    }

    &.v-btn.v-btn--disabled {
      --border-color: transparent;

      cursor: not-allowed;
      pointer-events: auto;
    }

    &:last-child {
      border-top-right-radius: var(--border-radius) !important;
      border-bottom-right-radius: var(--border-radius) !important;
      border-right-width: 1px !important;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius) !important;
      border-bottom-left-radius: var(--border-radius) !important;
    }

    &::before {
      // remove white tint
      display: none;
    }
  }
}
</style>
