<template>
  <apexchart
    :series="series"
    :options="options"
    :width="small ? 142 : 210"
    :height="small ? 142 : 210"
    type="radialBar"
  ></apexchart>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import type { ApexChartOptions } from '@/pwa/modules/Assessment/types/station';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    labels: {
      type: Array as PropType<string[]>,
      required: true
    },
    sizeBreakpoint: {
      type: Number,
      default: 960
    }
  },
  computed: {
    small(): boolean {
      return this.$vuetify.breakpoint.width < this.sizeBreakpoint;
    },
    options(): ApexChartOptions {
      return {
        chart: {
          sparkline: {
            enabled: true
          }
        },
        grid: {
          padding: {
            top: -10,
            bottom: -10
          }
        },
        colors: [this.color ? this.color : this.$vuetify.theme.currentTheme.primary],
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 0,
              size: '78%'
            },
            track: {
              background: '#e5e5e5'
            },
            dataLabels: {
              value: {
                fontFamily: 'Lato, sans-serif',
                fontSize: '40px',
                fontWeight: 'bold',
                formatter: () => this.value.toString(),
                offsetY: -18
              },
              name: {
                fontFamily: 'Lato, sans-serif',
                fontSize: '12px',
                fontWeight: 'normal',
                offsetY: 22
              }
            }
          }
        },
        legend: {
          show: true,
          floating: true,
          fontSize: '12px',
          fontFamily: 'Lato, sans-serif',
          position: 'left',
          offsetX: 80,
          offsetY: -27,
          onItemClick: {
            toggleDataSeries: false
          },
          markers: {
            width: 0
          },
          formatter: () => {
            return this.total.toString();
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [this.labels.join(' ')],
        responsive: [
          {
            breakpoint: this.sizeBreakpoint,
            options: {
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '70%'
                  },
                  dataLabels: {
                    value: {
                      fontSize: '32px',
                      offsetY: -22
                    },
                    name: {
                      offsetY: 15
                    }
                  }
                }
              },
              legend: {
                show: false
              },
              labels: [this.labels]
            }
          }
        ]
      };
    },
    series(): number[] {
      return [(this.value / this.total) * 100];
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';

::v-deep .apexcharts-datalabel-label {
  letter-spacing: 0;

  // overwrite inline color
  fill: var(--v-lightgrey-darken2) !important;

  @include sm-and-up {
    letter-spacing: 0.05em;
  }
}

::v-deep .apexcharts-datalabel-value {
  letter-spacing: 0.0125em;
}

::v-deep .apexcharts-legend {
  padding: 0;
  transform: translateX(-100%);
}
</style>
