import Dexie, { Table } from 'dexie';
import type { ScaleTypeName } from '@/shared/modules/AssessmentEdit/types/ride';

export interface Station {
  id: string;
  code: string;
  externalId: number;
  county: string;
  name: string;
  showInStationReporting: 0 | 1;
  latitude: number;
  longitude: number;
}

export interface ApiStation extends Omit<Station, 'showInStationReporting'> {
  showInStationReporting: boolean;
}

export interface Line {
  id: string;
  name: string;
  scaleTypeName: ScaleTypeName;
  trainStations: string[];
  wagonTypes: string[];
}

export interface WagonType {
  id: string;
  externalId: number;
  name: string;
}

export interface Vehicle {
  id: string;
  externalId: number;
  name: string;
  wagonTypeId: string;
  lines: string[];
}

export interface Image {
  id: string;
  data: string;
}

export class Profitester extends Dexie {
  stations!: Table<Station>;
  lines!: Table<Line>;
  wagonTypes!: Table<WagonType>;
  vehicles!: Table<Vehicle>;
  images!: Table<Image>;

  constructor() {
    super('profitester');
    this.version(6).stores({
      stations: 'id, showInStationReporting',
      lines: 'id',
      wagonTypes: 'id',
      vehicles: 'id, wagonTypeId, *lines',
      vehicleNumbers: null,
      questions: null,
      images: 'id'
    });
  }
}
