<template>
  <v-container fluid class="px-0 px-sm-3" :class="{ 'mb-16': isOffline }" data-test="ride-assessment-overview">
    <v-overlay :value="loading" opacity="0.8" data-test="base-data-overlay">
      <div class="text-center">
        <v-progress-circular color="white" size="60" class="mb-6" indeterminate />
        <div class="text-h6">{{ $t('assessment.loadingBaseData') }}</div>
      </div>
    </v-overlay>
    <v-card width="100%" min-height="70vh" class="desktop-card">
      <v-row
        class="showcase flex-nowrap overflow-x-hidden pos-relative mx-n5 mx-sm-0 mt-n11 mt-sm-0"
        :class="{ 'showcase--hidden': baseDataOverlayVisible }"
        no-gutters
      >
        <v-col v-if="$vuetify.breakpoint.mdAndUp" md="3" lg="6">
          <welcome />
        </v-col>
        <v-col cols="12" md="9" lg="6" class="white">
          <ride-assessment-statistics
            :draft-count="draftRideAssessments.length"
            :synchronized-count="synchronizedRideAssessments.length"
            :revision-count="revisionRideAssessments.length"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" lg="6" class="assessment-base-data-container pos-relative">
          <assessment-base-data-card
            v-if="baseDataOverlayVisible"
            label="rideAssessment.lineName"
            :items="autocompleteLines"
            @close="hideBaseDataOverlay"
            @baseDataComplete="createAssessment"
          />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!baseDataOverlayVisible || !isPhone">
        <v-col cols="12" class="phone-card-wrapper py-11">
          <v-card class="phone-card px-6 px-sm-0 py-4 py-sm-0">
            <v-btn
              v-if="!baseDataOverlayVisible && activeTab === 0"
              :title="$t('rideAssessment.actions.createAssessment')"
              color="primary"
              elevation="2"
              class="action-button"
              data-test="button-new-assessment"
              fab
              @click="showBaseDataOverlay"
            >
              <v-icon>far fa-plus</v-icon>
            </v-btn>
            <v-btn
              v-if="activeTab === 1"
              :title="$t('rideAssessment.actions.exportAssessment')"
              :disabled="selectedSynchronizedAssessments.length === 0 || isOffline"
              :loading="exportLoading"
              class="action-button"
              color="primary"
              elevation="2"
              data-test="button-export"
              fab
              @click="exportDialogVisible = true"
            >
              <v-icon>far fa-file-export</v-icon>
            </v-btn>
            <app-tabs v-model="activeTab" class="mb-3">
              <v-tab class="mr-4" data-test="tab-drafts">
                {{ $t('rideAssessment.drafts') }}
                <span v-if="!isPhone" class="ml-1 lightgrey--text">({{ draftRideAssessments.length }})</span>
              </v-tab>
              <v-tab class="mr-4" data-test="tab-synchronized">
                {{ $t('rideAssessment.synchronized') }}
                <span v-if="!isPhone" class="ml-1 lightgrey--text">({{ synchronizedRideAssessments.length }})</span>
              </v-tab>
              <v-tab data-test="tab-revision">
                {{ $t('rideAssessment.revision') }}
                <span v-if="!isPhone" class="ml-1 lightgrey--text">({{ revisionRideAssessments.length }})</span>
              </v-tab>
            </app-tabs>
            <hr class="divider white darken-1 d-none d-sm-block mb-3" />
            <v-tabs-items v-model="activeTab" class="overflow-visible">
              <v-tab-item>
                <ride-assessment-list
                  :assessments="filteredDraftRideAssessments"
                  :search="draftRideAssessmentSearch"
                  :options="draftRideAssessmentOptions"
                  :columns="draftTableColumns"
                  :offline="isOffline"
                  data-test="draft-assessment-list"
                  @update:options="setDraftRideAssessmentOptions"
                  @update:search="setDraftRideAssessmentSearch"
                  @deleteAssessment="deleteDraftRideAssessment"
                  @synchronizeAssessment="synchronizeDraftRideAssessment"
                  @assessmentClicked="editAssessment($event, 'draft')"
                />
              </v-tab-item>
              <v-tab-item>
                <ride-assessment-list
                  v-model="selectedSynchronizedAssessments"
                  :assessments="synchronizedRideAssessments"
                  :search="synchronizedRideAssessmentSearch"
                  :options="synchronizedRideAssessmentOptions"
                  :columns="synchronizedTableColumns"
                  :offline="isOffline"
                  :loading="synchronizedRideAssessmentsLoading"
                  data-test="synchronized-assessment-list"
                  online-delete
                  selectable
                  @update:options="setSynchronizedRideAssessmentOptions"
                  @update:search="setSynchronizedRideAssessmentSearch"
                  @deleteAssessment="deleteOnlineRideAssessment"
                  @assessmentClicked="editAssessment($event, 'edit')"
                />
              </v-tab-item>
              <v-tab-item>
                <ride-assessment-list
                  :assessments="revisionRideAssessments"
                  :search="revisionRideAssessmentSearch"
                  :options="revisionRideAssessmentOptions"
                  :columns="revisionTableColumns"
                  :offline="isOffline"
                  :loading="revisionRideAssessmentsLoading"
                  data-test="revision-assessment-list"
                  online-delete
                  @update:options="setRevisionRideAssessmentOptions"
                  @update:search="setRevisionRideAssessmentSearch"
                  @deleteAssessment="deleteOnlineRideAssessment"
                  @assessmentClicked="editAssessment($event, 'view')"
                  @reviseAssessment="reviseRevisionRideAssessment"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <offline-snackbar />
    <app-confirm-dialog
      :value="exportDialogVisible"
      :confirm-text-key="'rideAssessment.actions.exportAssessmentShort'"
      @cancel="exportDialogVisible = false"
      @confirm="exportAssessments"
    >
      <template #title>{{ $t('rideAssessment.exportDialog.title') }}</template>
      <template #text>{{ $t('rideAssessment.exportDialog.description') }}</template>
    </app-confirm-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import importHelper from '@/pwa/helper/import';
import Welcome from '@/pwa/modules/Assessment/components/Welcome.vue';
import RideAssessmentStatistics from '@/pwa/modules/Assessment/components/RideAssessmentStatistics.vue';
import RideAssessmentList from '@/pwa/modules/Assessment/components/RideAssessmentList.vue';
import AssessmentBaseDataCard from '@/pwa/modules/Assessment/components/AssessmentBaseDataCard.vue';
import AppTabs from '@/shared/modules/Global/components/AppTabs.vue';
import AppConfirmDialog from '@/shared/modules/Global/components/AppConfirmDialog.vue';
import OfflineSnackbar from '@/shared/modules/Global/components/OfflineSnackbar.vue';
import { RIDE_ASSESSMENT_NAMESPACE } from '@/pwa/modules/Assessment/stores/rideAssessment';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { VueOfflineMixin } from '@/shared/modules/Global/mixins/VueOfflineMixin';
import { createRideAssessment } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { numberSensitiveComparator } from '@/shared/helper/i18n';

import type { ListRideAssessment, RideAssessmentTableHeaderValue } from '@/pwa/modules/Assessment/types/ride';
import type { Line } from '@/shared/plugins/database/profitester';
import type { AssessmentBaseData, BaseDataListItem } from '@/pwa/modules/Assessment/types';

export default (Vue as VueConstructor<Vue & InstanceType<typeof VueOfflineMixin>>).extend({
  components: {
    Welcome,
    RideAssessmentStatistics,
    AssessmentBaseDataCard,
    AppTabs,
    OfflineSnackbar,
    RideAssessmentList,
    AppConfirmDialog
  },
  mixins: [VueOfflineMixin],
  data() {
    return {
      loading: false,
      greetUser: false,
      activeTab: 0,
      baseDataOverlayVisible: false,
      exportDialogVisible: false,
      selectedSynchronizedAssessments: [] as ListRideAssessment[],
      exportLoading: false,
      dbLines: [] as Line[],
      draftTableColumns: [
        'statusIndicator',
        'lineName',
        'trainNumber',
        'departureStationCode',
        'assessmentDateTime',
        'assessmentValidationStatus',
        'deleteAction',
        'synchronizeAction'
      ] as RideAssessmentTableHeaderValue[],
      synchronizedTableColumns: [
        'data-table-select',
        'statusIndicatorPlaceholder',
        'lineName',
        'trainNumber',
        'departureStationCode',
        'assessmentDateTime',
        'deleteAction'
      ] as RideAssessmentTableHeaderValue[],
      revisionTableColumns: [
        'statusIndicatorPlaceholder',
        'lineName',
        'trainNumber',
        'departureStationCode',
        'assessmentDateTime',
        'deleteAction',
        'reviseAction'
      ] as RideAssessmentTableHeaderValue[]
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_NAMESPACE, [
      'draftRideAssessments',
      'draftRideAssessmentSearch',
      'draftRideAssessmentOptions',
      'synchronizedRideAssessments',
      'synchronizedRideAssessmentSearch',
      'synchronizedRideAssessmentOptions',
      'synchronizedRideAssessmentsLoading',
      'revisionRideAssessments',
      'revisionRideAssessmentSearch',
      'revisionRideAssessmentOptions',
      'revisionRideAssessmentsLoading'
    ]),
    ...mapGetters(RIDE_ASSESSMENT_NAMESPACE, ['filteredDraftRideAssessments']),
    isPhone(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    autocompleteLines(): BaseDataListItem[] {
      return this.dbLines
        .map(line => ({
          ...line,
          disabled: false,
          code: line.name
        }))
        .sort((lineA, lineB) => numberSensitiveComparator.compare(lineA.name, lineB.name));
    }
  },
  methods: {
    ...mapMutations(RIDE_ASSESSMENT_NAMESPACE, [
      'setDraftRideAssessmentSearch',
      'setDraftRideAssessmentOptions',
      'addDraftRideAssessment'
    ]),
    ...mapActions(RIDE_ASSESSMENT_NAMESPACE, [
      'fetchSynchronizedRideAssessments',
      'fetchRevisionRideAssessments',
      'synchronizeDraftRideAssessment',
      'deleteDraftRideAssessment',
      'deleteOnlineRideAssessment',
      'reviseRevisionRideAssessment',
      'setSynchronizedRideAssessmentSearch',
      'setSynchronizedRideAssessmentOptions',
      'setRevisionRideAssessmentSearch',
      'setRevisionRideAssessmentOptions',
      'exportRideAssessments'
    ]),
    showBaseDataOverlay(): void {
      // create router history entry so that phone users can close the station selection by navigating back
      this.$router.push({ query: { ...this.$route.query, createAssessment: '1' } });
    },
    hideBaseDataOverlay(): void {
      this.$router.back();
    },
    createAssessment({ id, assessmentDateTime }: AssessmentBaseData) {
      const selectedLine = this.dbLines.find(line => line.id === id);
      if (selectedLine === undefined) {
        return;
      }
      const scaleTypeName = selectedLine.scaleTypeName;
      const lineName = selectedLine.name;
      const newRideAssessment = createRideAssessment(id, lineName, assessmentDateTime, scaleTypeName);
      this.addDraftRideAssessment(newRideAssessment);
      this.editAssessment(newRideAssessment.assessmentId, 'draft');
    },
    editAssessment(id: string, config: string) {
      this.$router.push({ name: 'ride-assessment', params: { id, config } });
    },
    async exportAssessments() {
      this.exportLoading = true;
      const csv = await this.exportRideAssessments(
        this.selectedSynchronizedAssessments.map(assessment => assessment.assessmentId)
      );
      this.exportLoading = false;

      if (!csv) {
        return;
      }

      const link = document.createElement('a');
      link.href = URL.createObjectURL(csv);
      link.download = 'quma_fahrt.csv';
      link.click();

      this.selectedSynchronizedAssessments = [];
      this.fetchSynchronizedRideAssessments();
      this.exportDialogVisible = false;
    }
  },
  watch: {
    activeTab() {
      if (this.baseDataOverlayVisible) {
        this.hideBaseDataOverlay();
      }
    }
  },
  async mounted() {
    this.greetUser = this.$route.query.greet === '1';
    const createAssessmentFlag = this.$route.query.createAssessment === '1';
    if (this.greetUser || createAssessmentFlag) {
      this.$router.replace({
        query: { ...this.$route.query, greet: undefined, createAssessment: undefined }
      });
    }

    if (this.greetUser) {
      this.loading = true;
      await importHelper.updateDatabase();
      this.loading = false;
    }

    this.dbLines = await this.$database.lines.toArray();

    this.fetchSynchronizedRideAssessments();
    this.fetchRevisionRideAssessments();
  },
  beforeRouteUpdate(to, from, next) {
    this.baseDataOverlayVisible = to.query.createAssessment !== undefined;
    next();
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';
@import '@/shared/styles/colors.scss';

.showcase {
  border-radius: 4px;
  box-shadow: $shadow-lg;

  &--hidden::after {
    @include sm-and-up {
      @include md-and-down {
        content: '';
        background-color: $grey1;
        position: absolute;
        inset: 0;
      }
    }
  }
}

.desktop-card {
  --inline-margin: 88px;

  @include xs-only {
    --inline-margin: 24px;
    background-color: transparent;
    box-shadow: none !important;
  }
}

.assessment-base-data-container {
  margin-inline: 0;

  @include sm-and-up {
    margin-inline: var(--inline-margin);
  }
}

.phone-card-wrapper {
  padding-inline: 0;

  // ensure that assessment list can be scrolled under new assessment button
  margin-bottom: 90px;

  @include sm-and-up {
    padding-inline: var(--inline-margin) !important;
  }
}

.phone-card {
  @include sm-and-up {
    background-color: transparent;
    box-shadow: none !important;
  }
}

.action-button {
  position: fixed;
  bottom: 100px;
  right: 35px;
  z-index: 1;

  @include sm-and-up {
    position: absolute;
    top: -72px;
    left: 0;
  }
}

.divider {
  border-top-width: 0;
  margin-inline: calc(-1 * var(--inline-margin));
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
