<template>
  <app-snackbar
    :value="snackbar.visible"
    :timeout="snackbarTimeout"
    :top="$vuetify.breakpoint.smAndUp"
    :bottom="$vuetify.breakpoint.xsOnly"
    :color="snackbar.config.color || 'primary'"
    :icon="snackbarIcon"
    :class="{
      mobile: $vuetify.breakpoint.xsOnly
    }"
    data-test="global-snackbar"
    @input="hideSnackbar"
  >
    {{ $t(snackbar.config.title) }}
    <template #description>
      {{ $t(snackbar.config.description) }}
    </template>
  </app-snackbar>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import AppSnackbar from '@/shared/modules/Global/components/AppSnackbar.vue';
import { mapMutations, mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';

import type { LayoutState } from '@/shared/modules/Layout/types';

type VuexBindings = {
  snackbar: LayoutState['snackbar'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: { AppSnackbar },
  data() {
    return {
      snackbarTimeout: 5000
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['snackbar']),
    snackbarIcon(): string {
      switch (this.snackbar.config.icon) {
        case 'error':
          return 'fal fa-circle-xmark';
        case 'success':
          return 'fal fa-circle-check';
        default:
          return 'fal fa-circle-check';
      }
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['hideSnackbar'])
  },
  watch: {
    snackbar: {
      deep: true,
      async handler(newSnackbar) {
        if (newSnackbar.visible === false) {
          return;
        }

        // reset timeout
        this.snackbarTimeout = 0;
        await Vue.nextTick();
        this.snackbarTimeout = this.snackbar.config.timeout || 5000;
      }
    }
  }
});
</script>

<style scoped lang="scss"></style>
