import type { ActionTree, Commit, GetterTree, Module, MutationTree } from 'vuex';
import type {
  StationAssessmentOptions,
  StationAssessmentSearch,
  StationAssessmentShort,
  StationAssessmentState
} from '@/pwa/modules/Assessment/types/station';
import type { StationAssessment } from '@/shared/modules/AssessmentEdit/types/station';
import type { AxiosResponse } from 'axios';
import type { ApiError } from '@/shared/types';
import type { DistributionUnit } from '@/shared/types/reporting';
import type { VueConstructor } from 'vue';

import { getQuarter, getYear, differenceInDays, parseISO } from 'date-fns';
import { isAxiosError } from '@/shared/helper/axios';
import { isSameDay } from '@/shared/helper/date';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';
import { SnackbarConfig } from '@/shared/modules/Layout/types';
import { contains } from '@/pwa/modules/Assessment/helpers/string';
import {
  convertStationAssessmentForExport,
  replaceIdsWithImages
} from '@/shared/modules/AssessmentEdit/services/station-assessment-conversion';
import VueI18n from '@/pwa/plugins/vue-i18n';
import { getStationAssessmentImageIds } from '@/shared/modules/AssessmentEdit/services/station-assessment-service';
import { deleteImages } from '@/shared/modules/AssessmentEdit/services/image-service';

export const STATION_ASSESSMENT_NAMESPACE = 'STATION_ASSESSMENT_NAMESPACE';

function showSnackbar(commit: Commit, snackbar: SnackbarConfig) {
  commit(
    `${LAYOUT_NAMESPACE}/showSnackbar`,
    {
      ...snackbar,
      title: `stationAssessment.snackbar.${snackbar.title}`,
      description: snackbar.description ? `stationAssessment.snackbar.${snackbar.description}` : undefined
    },
    { root: true }
  );
}

function showSuccessSnackbar(commit: Commit, title: string, description?: string) {
  showSnackbar(commit, {
    title,
    description,
    icon: 'success'
  });
}

function showErrorSnackbar(commit: Commit, title: string, description?: string) {
  showSnackbar(commit, {
    title,
    description,
    color: 'error',
    icon: 'error'
  });
}

const state: StationAssessmentState = {
  stationReportsLoading: false,
  stationReportsYear: getYear(new Date()),
  stationReportsQuarter: getQuarter(new Date()),
  stationReportsUpdated: null,
  stationReportsUpdateFailed: false,
  stationReportsCountTotal: 0,
  stationReportsCountUnassessed: 0,
  stationAssessments: [],
  globalStationAssessments: [],
  draftAssessmentSearch: {
    stationCode: undefined,
    stationName: undefined,
    assessmentDateTime: undefined,
    assessmentStatus: undefined
  },
  draftAssessmentOptions: {
    sortBy: ['assessmentDateTime'],
    sortDesc: [true],
    itemsPerPage: -1
  },
  transmittedAssessmentSearch: {
    stationCode: undefined,
    stationName: undefined,
    assessmentDateTime: undefined,
    exportDateTime: undefined
  },
  transmittedAssessmentOptions: {
    sortBy: ['assessmentDateTime'],
    sortDesc: [true],
    itemsPerPage: -1
  }
};

const getters: GetterTree<StationAssessmentState, unknown> = {
  draftAssessments(state) {
    const { stationCode, stationName, assessmentDateTime, assessmentStatus } = state.draftAssessmentSearch;
    return state.stationAssessments.filter(assessment => {
      return (
        (stationCode === undefined ? true : contains(assessment.stationCode, stationCode)) &&
        (stationName === undefined ? true : contains(assessment.stationName, stationName)) &&
        (assessmentDateTime === undefined ? true : isSameDay(assessment.assessmentDateTime, assessmentDateTime)) &&
        (assessmentStatus === undefined ? true : assessment.assessmentStatus === assessmentStatus) &&
        assessment.exportDateTime === null
      );
    });
  },
  draftAssessmentCount(_, getters) {
    return getters.draftAssessments.length;
  },
  transmittedAssessments(state) {
    const { stationCode, stationName, assessmentDateTime, assessmentStatus } = state.transmittedAssessmentSearch;
    return state.stationAssessments.filter(assessment => {
      return (
        (stationCode === undefined ? true : contains(assessment.stationCode, stationCode)) &&
        (stationName === undefined ? true : contains(assessment.stationName, stationName)) &&
        (assessmentDateTime === undefined ? true : isSameDay(assessment.assessmentDateTime, assessmentDateTime)) &&
        (assessmentStatus === undefined ? true : assessment.assessmentStatus === assessmentStatus) &&
        assessment.exportDateTime !== null
      );
    });
  },
  transmittedAssessmentCount(_, getters) {
    return getters.transmittedAssessments.length;
  }
};

const mutations: MutationTree<StationAssessmentState> = {
  setStationReportsUpdated(state, payload: Date) {
    state.stationReportsUpdated = payload;
  },
  setStationReportsUpdateFailed(state, payload: boolean) {
    state.stationReportsUpdateFailed = payload;
  },
  setStationReportsCountTotal(state, payload: number) {
    state.stationReportsCountTotal = payload;
  },
  setStationReportsCountUnassessed(state, payload: number) {
    state.stationReportsCountUnassessed = payload;
  },
  setStationReportsLoading(state, payload: boolean) {
    state.stationReportsLoading = payload;
  },
  setGlobalStationAssessments(state, payload: StationAssessmentShort[]) {
    state.globalStationAssessments = payload;
  },
  addStationAssessment(state, payload: StationAssessment) {
    state.stationAssessments.push(payload);
  },
  deleteStationAssessment(state, payload: { assessmentId: string }) {
    state.stationAssessments.splice(
      state.stationAssessments.findIndex(assessment => assessment.assessmentId === payload.assessmentId),
      1
    );
  },
  setStationAssessmentExportDateTime(state, payload: { assessment: StationAssessment; exportDateTime: string }) {
    const stationAssessment = state.stationAssessments.find(
      assessment => assessment.assessmentId === payload.assessment.assessmentId
    );
    if (stationAssessment !== undefined) {
      stationAssessment.exportDateTime = payload.exportDateTime;
    }
  },
  setStationAssessmentIsTransmitting(state, payload: { assessment: StationAssessment; isTransmitting: boolean }) {
    const stationAssessment = state.stationAssessments.find(
      assessment => assessment.assessmentId === payload.assessment.assessmentId
    );
    if (stationAssessment !== undefined) {
      stationAssessment.isTransmitting = payload.isTransmitting;
    }
  },
  setDraftAssessmentSearch(state, payload: StationAssessmentSearch) {
    state.draftAssessmentSearch = payload;
  },
  setDraftAssessmentOptions(state, payload: StationAssessmentOptions) {
    state.draftAssessmentOptions = payload;
  },
  setTransmittedAssessmentSearch(state, payload: StationAssessmentSearch) {
    state.transmittedAssessmentSearch = payload;
  },
  setTransmittedAssessmentOptions(state, payload: StationAssessmentOptions) {
    state.transmittedAssessmentOptions = payload;
  }
};

const configureActions: (Vue: VueConstructor) => ActionTree<StationAssessmentState, unknown> = Vue => {
  const actions: ActionTree<StationAssessmentState, unknown> = {
    async fetchStationReports({ state, commit, dispatch }): Promise<void> {
      try {
        commit('setStationReportsLoading', true);

        const quarterId = `q${state.stationReportsQuarter}`;

        const response: AxiosResponse = await Vue.prototype.$http.get('station/reporting/report/list', {
          params: {
            year: state.stationReportsYear,
            itemsPerPage: 0
          }
        });

        commit('setStationReportsUpdated', new Date().toISOString());
        commit('setStationReportsCountTotal', response.data.total);
        commit('setStationReportsUpdateFailed', false);

        dispatch('fetchGlobalStationAssessments');
      } catch (error) {
        if (isAxiosError<ApiError>(error)) {
          console.error(error.response?.data?.message);
          showErrorSnackbar(commit, 'networkError', 'stationReportsError');
          commit('setStationReportsUpdateFailed', true);
        }
        console.error(error);
      } finally {
        commit('setStationReportsLoading', false);
      }
    },
    async fetchGlobalStationAssessments({ state, commit }): Promise<void> {
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('station/assessment/list', {
          params: {
            search: {
              year: state.stationReportsYear,
              quarter: `Q${state.stationReportsQuarter}`
            },
            itemsPerPage: -1
          }
        });

        commit('setGlobalStationAssessments', response.data.items);

        if (state.stationReportsCountTotal > 0) {
          const unassessedCount = state.stationReportsCountTotal - response.data.total;
          commit('setStationReportsCountUnassessed', unassessedCount);
        }
      } catch (error) {
        if (isAxiosError<ApiError>(error)) {
          console.error(error.response?.data?.message);
          showErrorSnackbar(commit, 'networkError', 'assessmentsListError');
        }
        console.error(error);
      }
    },
    async transmitStationAssessment({ commit, dispatch }, stationAssessment): Promise<void> {
      try {
        commit('setStationAssessmentIsTransmitting', { assessment: stationAssessment, isTransmitting: true });

        const exportDateTime = new Date().toISOString();
        const exportStationAssessment = convertStationAssessmentForExport(stationAssessment, exportDateTime);
        await replaceIdsWithImages(exportStationAssessment);

        await Vue.prototype.$http.post('commands/station/assessment/add', exportStationAssessment);

        showSuccessSnackbar(commit, 'assessmentTransmissionSuccess');

        dispatch('fetchStationReports');
        dispatch('fetchGlobalStationAssessments');
        commit('setStationAssessmentExportDateTime', { assessment: stationAssessment, exportDateTime });
      } catch (error) {
        if (isAxiosError<ApiError>(error)) {
          console.error(error.response?.data?.message);
          const errorCode = error.response?.data?.code;
          if (errorCode === undefined) {
            showErrorSnackbar(commit, 'assessmentTransmissionError.heading', 'assessmentTransmissionError.default');
          } else {
            showErrorSnackbar(
              commit,
              'assessmentTransmissionError.heading',
              VueI18n.te(`stationAssessment.snackbar.assessmentTransmissionError.${errorCode}`)
                ? `assessmentTransmissionError.${errorCode}`
                : 'assessmentTransmissionError.default'
            );
          }
        }
        console.error(error);
      } finally {
        commit('setStationAssessmentIsTransmitting', { assessment: stationAssessment, isTransmitting: false });
      }
    },
    async deleteStationAssessment({ commit }, stationAssessment): Promise<void> {
      const stationAssessmentImageIds = getStationAssessmentImageIds(stationAssessment);
      commit('deleteStationAssessment', { assessmentId: stationAssessment.assessmentId });
      await deleteImages(stationAssessmentImageIds);
    },
    deleteOldTransmittedAssessments({ dispatch, getters }) {
      const currentDate = new Date();
      const assessmentsToDelete: StationAssessment[] = getters.transmittedAssessments.filter(
        // transmittedAssessments getter guarantees that exportDateTime is not null
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (assessment: StationAssessment) => differenceInDays(currentDate, parseISO(assessment.exportDateTime!)) >= 1
      );
      assessmentsToDelete.forEach(assessment => {
        dispatch('deleteStationAssessment', assessment);
      });
    },
    showStationAssessmentCompleteSnackbar({ commit }) {
      showSuccessSnackbar(commit, 'stationAssessmentComplete');
    }
  };
  return actions;
};

export const configureStationAssessmentStore = (Vue: VueConstructor) => {
  const module: Module<StationAssessmentState, unknown> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: configureActions(Vue)
  };
  return module;
};
