<template>
  <v-container fluid data-test="password-change-form">
    <v-row>
      <v-col class="pa-0 pa-sm-3">
        <v-card class="pa-3 pa-sm-12">
          <v-row no-gutters justify="center">
            <v-col cols="12" lg="11" offset-lg="1" class="text-center text-sm-left">
              <div v-if="forcedChange">
                {{ $t('account.passwordChangeRequired') }}
              </div>
              {{ $t('account.passwordMustSecure') }}
            </v-col>

            <v-col cols="12" md="6" lg="5" offset-lg="1">
              <v-list disabled>
                <v-list-item-group>
                  <v-list-item v-for="criteriaItem in criteria" :key="criteriaItem.index" class="px-0 px-sm-4">
                    <v-list-item-icon class="list-item-icon mr-3 mr-sm-8 my-3 my-sm-4">
                      <v-icon v-if="criteriaItem.status" color="success">fal fa-check</v-icon>
                      <v-icon v-else color="error">fal fa-times</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ criteriaItem.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" lg="5">
              <v-form @submit.prevent="onSubmit" class="text-center text-sm-left">
                <v-text-field
                  v-model="models.password"
                  :error-messages="errorMessages.password"
                  :label="$t('account.currentPassword')"
                  class="mb-3"
                  type="password"
                  autocomplete="current-password"
                  data-test="current-password"
                  @input="resetValidation"
                ></v-text-field>
                <v-text-field
                  v-model="models.newPassword"
                  :error-messages="errorMessages.newPassword"
                  :label="$t('account.newPassword')"
                  class="mb-3"
                  type="password"
                  autocomplete="new-password"
                  data-test="new-password"
                  @input="onInput"
                ></v-text-field>
                <v-text-field
                  v-model="models.newPasswordRepeat"
                  :error-messages="errorMessages.newPasswordRepeat"
                  :label="$t('account.newPasswordRepeat')"
                  class="mb-3"
                  type="password"
                  autocomplete="new-password"
                  data-test="new-password-repeat"
                  @input="resetValidation"
                ></v-text-field>
                <v-btn
                  :loading="loading"
                  type="submit"
                  color="primary"
                  class="mt-4"
                  data-test="button-change-password"
                  outlined
                  rounded
                  >{{ $t('account.changePasswordNow') }}</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { mapActions, mapMutations } from 'vuex';
import { ACCOUNT_NAMESPACE } from '@/pwa/modules/Account/stores/account';
import { authUser } from '@/shared/helper/vue-auth';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';
import Vue from 'vue';

import type { TranslateResult } from 'vue-i18n';
import type { RawLocation } from 'vue-router';

export default Vue.extend({
  data() {
    return {
      errorMessages: {
        password: [] as TranslateResult[],
        newPassword: [] as TranslateResult[],
        newPasswordRepeat: [] as TranslateResult[]
      },
      models: {
        password: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      loading: false,
      redirectRoute: { path: '/' } as RawLocation,
      criteria: [
        {
          text: this.$t('account.criteria.length'),
          status: false,
          check: (value: string) => value.length >= 8
        },
        {
          text: this.$t('account.criteria.number'),
          status: false,
          check: (value: string) => value.search(/\d/) !== -1
        },
        {
          text: this.$t('account.criteria.letter'),
          status: false,
          check: (value: string) => value.search(/[a-zA-Z]/) !== -1
        },
        {
          text: this.$t('account.criteria.specialCharacter'),
          status: false,
          check: (value: string) => value.search(/[@$!%*#?&]/) !== -1
        }
      ]
    };
  },
  computed: {
    forcedChange() {
      return authUser('passwordChangeRequired');
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['lockMenu', 'unlockMenu']),
    ...mapActions(ACCOUNT_NAMESPACE, ['changePassword']),
    resetValidation() {
      this.errorMessages = {
        password: [],
        newPassword: [],
        newPasswordRepeat: []
      };
    },
    validate() {
      if (this.models.password === '') {
        this.errorMessages.password.push(this.$t('account.error.required'));
      }

      if (this.models.newPassword === '') {
        this.errorMessages.newPassword.push(this.$t('account.error.required'));
      }

      if (this.models.newPasswordRepeat === '') {
        this.errorMessages.newPasswordRepeat.push(this.$t('account.error.required'));
      }

      if (this.models.newPassword !== this.models.newPasswordRepeat) {
        this.errorMessages.newPasswordRepeat.push(this.$t('account.error.passwordsNotEqual'));
      }

      if (this.criteria.filter(criteria => !criteria.status).length > 0) {
        this.errorMessages.newPassword.push(this.$t('account.error.passwordDontMatchCriteria'));
      }
    },
    onInput(value: string) {
      this.resetValidation();
      this.criteria.forEach(criterion => {
        criterion.status = criterion.check(value);
      });
    },
    async onSubmit() {
      this.validate();

      if (
        this.errorMessages.password.length +
          this.errorMessages.newPassword.length +
          this.errorMessages.newPasswordRepeat.length >
        0
      ) {
        return;
      }

      this.loading = true;

      const result = await this.changePassword({
        userId: authUser('id'),
        password: this.models.password,
        newPassword: this.models.newPassword
      });

      if (result.success) {
        await this.$auth.fetch({});

        await this.$router.push(this.redirectRoute).catch(e => {
          console.log(e);
        });
      }

      this.loading = false;

      if (!result.success && result.code === 16) {
        this.errorMessages.password.push(this.$t('account.error.passwordWrong'));
        return;
      }

      if (!result.success && result.code === 17) {
        this.errorMessages.newPassword.push(this.$t('account.error.newPasswordEqualsCurrentPassword'));
        return;
      }
    }
  },
  mounted() {
    const redirectPath = this.$route.query.redirect;
    if (typeof redirectPath === 'string') {
      this.redirectRoute = { path: redirectPath };
    }

    if (this.forcedChange) {
      this.lockMenu();
    }
  },
  beforeDestroy() {
    this.unlockMenu();
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';

.list-item-icon {
  @include xs-only {
    min-width: 1.3125rem;
  }
}
</style>
