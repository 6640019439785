<template>
  <div class="overview-button d-flex d-md-none mt-3" :class="{ 'overview-button--complete': complete }">
    <router-link :to="{ name: toRouteName }" class="overview-button__pill d-flex" v-ripple="{ class: 'white--text' }">
      <div class="overview-button__indicator d-flex align-center pl-2 pr-6 primary">
        <v-icon color="white" class="overview-button__indicator-icon">far fa-check</v-icon>
      </div>
    </router-link>
    <v-btn
      :to="{ name: toRouteName }"
      class="overview-button__button"
      data-test="button-overview"
      color="primary"
      elevation="2"
      outlined
      exact
      fab
      small
    >
      <v-icon>far fa-list-ul</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    complete: {
      type: Boolean,
      default: false
    },
    toRouteName: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/media.scss';

.overview-button {
  --button-size: 20px;

  bottom: 100px;
  justify-content: flex-end;
  margin-bottom: 140px;
  pointer-events: none;
  position: sticky;

  &__pill {
    left: var(--button-size);
    overflow: hidden;
    position: relative;
    pointer-events: auto;

    ::v-deep .v-ripple__container {
      border-top-left-radius: var(--button-size);
      border-bottom-left-radius: var(--button-size);
    }
  }

  &__button {
    background-color: white;
    pointer-events: auto;
  }

  &__indicator {
    transform: translateX(100%);
    transition: 0.3s transform ease-in-out;
    border-top-left-radius: var(--button-size);
    border-bottom-left-radius: var(--button-size);
  }

  &__indicator-icon {
    font-size: 1.375rem;
  }

  &--complete &__indicator {
    transform: translateX(0);
  }
}
</style>
